
import markdownit from 'markdown-it';
import * as api from '@/api/AiApi.js';
import { getEchoInstance } from '@/services/echo.js';
import useSnackbar from '@/hooks/snackbars.js';

/**
 * @type {import('markdown-it').default|null}
 */
let mdRenderer = null;
const getRenderMarkdownResult = result => {
    if (!mdRenderer) {
        mdRenderer = markdownit({
            html: true,
            linkify: true,
        });
    }

    return mdRenderer.render(result);
};
const { createSnackbar } = useSnackbar();

export default {
    namespaced: true,
    state: {
        isShowModal: false,
        loading: false,
        searchQuery: '',
        prevSearchQuery: '',
        searchPromptType: null,
        searchResult: '',
        searchResultLinks: [],
        aiSessionId: null,
        currentAiMessageId: null,
        socketInstasnce: null,
        currentActivityId: null,
        editorInstance: null,
    },
    getters: {
        isShowModal: state => state.isShowModal,
        isLoading: state => state.loading,
        searchQuery: state => state.searchQuery,
        prevSearchQuery: state => state.prevSearchQuery,
        searchPromptType: state => state.searchPromptType,
        searchResult: state => state.searchResult,
        searchResultLinks: state => state.searchResultLinks,
        aiSessionId: state => state.aiSessionId,
        currentAiMessageId: state => state.currentAiMessageId,
        socketInstance: state => state.socketInstance,
        currentActivityId: state => state.currentActivityId,
        editorInstance: state => state.editorInstance,
    },
    mutations: {
        setShowModal(state, value) {
            state.isShowModal = value;
        },
        setLoading(state, value) {
            state.loading = value;
        },
        setSearchQuery(state, value) {
            state.searchQuery = value;
        },
        setPrevSearchQuery(state, value) {
            state.prevSearchQuery = value;
        },
        setSearchPromptType(state, value) {
            state.searchPromptType = value;
        },
        setSearchResult(state, value) {
            state.searchResult = value;
        },
        setSearchResultLinks(state, value) {
            state.searchResultLinks = value;
        },
        setAiSessionId(state, value) {
            state.aiSessionId = value;
        },
        setCurrentAiMessageId(state, value) {
            state.currentAiMessageId = value;
        },
        setSocketInstance(state, value) {
            state.socketInstance = value;
        },
        setCurrentActivityId(state, value) {
            state.currentActivityId = value;
        },
        setEditorInstance(state, value) {
            state.editorInstance = value;
        },
    },
    actions: {
        async createAiSession({ commit, getters, dispatch }, activityId) {
            try {
                const { currentActivityId } = getters;

                if (activityId === currentActivityId && getters.socketInstance) return;

                commit('setLoading', true);

                if (getters.socketInstance) dispatch('stopAiSocketConnection');

                const { data } = await api.createAiSession(activityId);
                const sessionId = data.data.id;
                const prevSessionId = getters.aiSessionId;

                if (prevSessionId === sessionId) return;

                dispatch('startAiSocketConnection', sessionId);
                commit('setAiSessionId', sessionId);
                commit('setCurrentActivityId', activityId);
            } catch (err) {
                console.error(err);
            } finally {
                commit('setLoading', false);
            }
        },
        startAiSocketConnection({ commit, dispatch }, sessionId) {
            const socketInstance = getEchoInstance()
                .private(`ai.session.${sessionId}`)
                .stopListening('.ai.session')
                .listen('.ai.session', data => {
                    const result = data.response.text;
                    const messageId = data.message_id;

                    dispatch('updateAiQuerySearchResult', {
                        result,
                        messageId,
                    });
                })
                .stopListening('.ai.session_failed')
                .listen('.ai.session_failed', ({ response }) => {
                    console.error('ошибка отправления собщения в AI', response.error);
                    createSnackbar({
                        type: 'error',
                        message: 'Ошибка. Попробуйте еще раз',
                    });
                    dispatch('toggleShowAiModal', false);
                });

            commit('setSocketInstance', socketInstance);
        },
        stopAiSocketConnection({ commit, getters }) {
            const { socketInstance } = getters;

            if (!socketInstance) return;

            socketInstance.stopListening('.ai.session').stopListening('.ai.session_failed');

            commit('setSocketInstance', null);
            commit('setCurrentActivityId', null);
        },
        async sendAiSearchQuery({ commit, getters }, { query, type }) {
            if (getters.isLoading) return;

            commit('setLoading', true);
            commit('setSearchQuery', query);
            commit('setPrevSearchQuery', query);
            commit('setSearchPromptType', type);

            try {
                const { data } = await api.sendAiSearchQuery(getters.aiSessionId, {
                    text: query,
                    prompt_type: type,
                });

                const messageId = data.data.id;

                commit('setCurrentAiMessageId', messageId);

            } catch (err) {
                console.error(err);
            }
        },
        updateAiQuerySearchResult({ commit }, { result, messageId }) {
            try {
                const searchResult = getRenderMarkdownResult(result);

                commit('setSearchQuery', '');
                commit('setSearchResult', searchResult);
                commit('setCurrentAiMessageId', messageId);
            } catch (err) {
                console.error(err);
            } finally {
                commit('setLoading', false);
            }
        },
        toggleShowAiModal({ commit }, isShowModal) {
            commit('setShowModal', isShowModal);
        },
        setSearchPromptType({ commit }, value) {
            commit('setSearchPromptType', value);
        },
        async canselSearchResult({ commit, dispatch }, data) {
            try {
                await api.canselMeassage(data);
                commit('setLoading', false);
                dispatch('updateAiQuerySearchResult', {
                    result: '',
                    messageId: null,
                });
            } catch (error) {
                console.error(error);
            }
        },
        setEditorInstance({ commit }, editor) {
            commit('setEditorInstance', editor);
        },
        setCurrentActivityId({ commit }, value) {
            commit('setCurrentActivityId', value);
        },
        setSearchQuery({ commit }, value) {
            commit('setSearchQuery', value);
            commit('setPrevSearchQuery', value);
        },
    },
};
