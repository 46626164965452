<template>
    <ConfirmModal
        v-model="isShow"
        :label="options.title"
        :subLabel="options.description"
        :buttonRemoveTitle="options.confirmButtonText"
        :buttonCancelTitle="options.cancelButtonText"
        @confirm="handleConfirm"
        @cancel="handleCancel" />
</template>

<script>
import { useConfirmModal } from '@/hooks/confirmation.js';
import ConfirmModal from '@/components/UI/ConfirmModal.vue';

export default {
    name: 'Confirmation',
    components: { ConfirmModal },
    setup() {
        const { isShow, options, handleConfirm, handleCancel } = useConfirmModal();

        return {
            isShow,
            options,
            handleConfirm,
            handleCancel,
        };
    },
};
</script>
