import { FileTypes } from '@/utils/fileTypes.js';
import documentIcon from '@/assets/svg/icons8-block/icons8-document.svg';
import pictureIcon from '@/assets/svg/icons8-block/icons8-image.svg';
import videoIcon from '@/assets/svg/icons8-block/icons8-video.svg';
import audioIcon from '@/assets/svg/icons8-block/icons8-audio-2.svg';
import archiveIcon from '@/assets/svg/icons8-block/icons8-archive.svg';
import { ACTIVITY_BLOCK_TYPES } from '@/constants/index.js';

export const HOMEWORK_TYPES = {
    ESSAY: ACTIVITY_BLOCK_TYPES.EssayHomework,
    FILES: ACTIVITY_BLOCK_TYPES.HomeworkFiles,
};

export const HOMEWORK_TYPE_TITLE = {
    [HOMEWORK_TYPES.ESSAY]: 'essay',
    [HOMEWORK_TYPES.FILES]: 'files',
};

export const ACCEPTED_FORMATS = [
    ...FileTypes.document.EXTENSIONS,
    ...FileTypes.picture.EXTENSIONS,
    ...FileTypes.video.EXTENSIONS,
    ...FileTypes.audio.EXTENSIONS,
    ...FileTypes.archive.EXTENSIONS,
].map(ext => `.${ext}`).join(', ');

export const ACCEPTED_FILES = {
    document: {
        title: 'Файл',
        titleTheme: 'linear-gradient(135deg, #36CCDC 0%, #5B7EE5 99.29%)',
        icon: documentIcon,
        formats: FileTypes.document.EXTENSIONS.join(', '),
    },
    picture: {
        title: 'Картинка',
        titleTheme: 'linear-gradient(135deg, #23B883, #23B883)',
        icon: pictureIcon,
        formats: FileTypes.picture.EXTENSIONS.join(', '),
    },
    video: {
        title: 'Видео',
        titleTheme: 'linear-gradient(135deg, #FF9426 0%, #F21365 91.96%)',
        icon: videoIcon,
        formats: FileTypes.video.EXTENSIONS.join(', '),
    },
    audio: {
        title: 'Аудио',
        titleTheme: 'linear-gradient(135deg, #FD6F97 12.45%, #ED0082 94.15%)',
        icon: audioIcon,
        formats: FileTypes.audio.EXTENSIONS.join(', '),
    },
    archive: {
        title: 'Архив',
        titleTheme: 'linear-gradient(135deg, #A044FF 0%, #303893 96.36%)',
        icon: archiveIcon,
        formats: FileTypes.archive.EXTENSIONS.join(', '),
    },
};

export const MAX_FILE_SIZE = 70;
export const MAX_FILE_SIZE_IN_BYTES = MAX_FILE_SIZE * 1024 * 1024;
export const MAX_FILES_QUANTITY = 10;
