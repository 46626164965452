export const eventsGetterTypes = Object.freeze({
    GET_EVENTS_DATA: '[events] get events data',
    GET_EVENTS_LOADING: '[events] get events loading',
    GET_CURRENT_PAGE: '[events] get current page',
    GET_IS_LAST_PAGE: '[events] get is last page',
    GET_LAST_PAGE: '[events] get last page',
});

export const eventsGetters = {
    [eventsGetterTypes.GET_EVENTS_DATA]: state => state.events,
    [eventsGetterTypes.GET_EVENTS_LOADING]: state => state.loading,
    [eventsGetterTypes.GET_CURRENT_PAGE]: state => state.currentPage,
    [eventsGetterTypes.GET_IS_LAST_PAGE]: state => state.currentPage >= state.lastPage,
    [eventsGetterTypes.GET_LAST_PAGE]: state => state.lastPage,
};
