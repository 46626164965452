import { getSearchData, getSearchHistory, clearSearchHistory } from '@/api/search.js';
import { searchMutationTypes } from './mutations.js';

const errorHandler = (error, actionName) => {
    const errorText = `error in ${actionName} action`;

    console.error(errorText, error);

    return Promise.reject(error);
};

export const searchActionTypes = Object.freeze({
    FETCH_SEARCH_DATA: '[search] fetch search data',
    RESET_SEARCH_DATA: '[search] reset search data',
    FETCH_SEARCH_HISTORY: '[search] fetch search history',
    CLEAR_SEARCH_HISTORY: '[search] clear search history',
});

const initSearchData = {
    members: [],
    reports: [],
    projects: [],
};

export const searchActions = {
    async [searchActionTypes.FETCH_SEARCH_DATA]({ commit }, { developmentCenterId, params }) {
        commit(searchMutationTypes.SET_LOADING, true);

        try {
            const { data } = await getSearchData(developmentCenterId, params);

            const { projects, members, reports } = data.data;
            const isNotFoundData = !projects?.length && !members?.length && !reports?.length;

            commit(searchMutationTypes.SET_SEARCH_DATA, data.data);
            commit(searchMutationTypes.SET_NOT_FOUND_DATA, isNotFoundData);

            return Promise.resolve(data.data);
        } catch (error) {
            return errorHandler(error, searchActionTypes.FETCH_SEARCH_DATA);
        } finally {
            commit(searchMutationTypes.SET_LOADING, false);
        }
    },
    [searchActionTypes.RESET_SEARCH_DATA]({ commit }) {
        try {
            commit(searchMutationTypes.SET_SEARCH_DATA, initSearchData);

            return Promise.resolve(initSearchData);
        } catch (error) {
            return errorHandler(error, searchActionTypes.RESET_SEARCH_DATA);
        }
    },
    async [searchActionTypes.FETCH_SEARCH_HISTORY]({ commit }) {
        try {
            const { data } = await getSearchHistory();

            commit(searchMutationTypes.SET_SEARCH_HISTORY, data.data || []);

            return Promise.resolve(data.data);
        } catch (error) {
            return errorHandler(error, searchActionTypes.FETCH_SEARCH_HISTORY);
        }
    },
    async [searchActionTypes.CLEAR_SEARCH_HISTORY]({ commit }) {
        try {
            await clearSearchHistory();

            commit(searchMutationTypes.SET_SEARCH_HISTORY, []);

            return Promise.resolve([]);
        } catch (error) {
            return errorHandler(error, searchActionTypes.CLEAR_SEARCH_HISTORY);
        }
    },
};
