import axios from 'axios';
import * as Sentry from '@sentry/vue';
import { captureConsoleIntegration } from '@sentry/integrations';
import { ClientErrorCodes } from '@/utils/httpStatusCodes.js';
import { ERROR_CHUNK_LOADING_MSGS } from '@/services/errors.js';

const IGNORED_ERROR_STATUSES = [
    ClientErrorCodes.UNAUTHORIZED,
    ClientErrorCodes.FORBIDDEN,
    ClientErrorCodes.AUTHENTICATION_TIMEOUT,
];

export const setSentryUser = id => {
    Sentry.setUser({ id });
};

export const initSentry = app => {
    if (process.env.VUE_APP_SENTRY_DSN) {
        const isProduction = ['preproduction', 'production'].includes(process.env.VUE_APP_ENV);

        Sentry.init({
            app,
            dsn: process.env.VUE_APP_SENTRY_DSN,
            environment: process.env.VUE_APP_ENV,
            enabled: process.env.VUE_APP_SENTRY_ENABLED === 'true',
            trackComponents: true,
            integrations: isProduction
                ? [
                    Sentry.replayIntegration({
                        errorSampleRate: 1.0,
                        sessionSampleRate: 0.1,
                    }),
                    captureConsoleIntegration({
                        levels: ['error'],
                    }),
                ]
                : [],
            denyUrls: [
                // Chrome extensions
                /extensions\//i,
                /^chrome:\/\//i,
                // metrics
                /mc\.yandex\.ru\//i,
            ],
            ignoreErrors: [...ERROR_CHUNK_LOADING_MSGS],
            beforeSend: (event, hint) => {
                const error = hint?.originalException;
                const isAxiosError = axios.isAxiosError(error);
                const isIgnoredStatusError = error?.response?.status && IGNORED_ERROR_STATUSES.includes(error?.response.status);
                const isUserAuthError = typeof event?.message === 'string' && event?.message?.includes('[user] error while get data from api'); // error from user store

                if ((isAxiosError && isIgnoredStatusError) || isUserAuthError) return null;

                return event;
            },
        });
    }
};
