import { getHomeworkBlock, getHomeworkBlockList, getVersionHomeworkActivities } from '@/api/homeworkApi.js';
import { getNewBlockInstancePatch } from '@/utils/utils.js';
import { homeworkMutationTypes } from './mutations.js';
import { homeworkGettersTypes } from './getters.js';

export const homeworkActionsTypes = Object.freeze({
    FETCH_HOMEWORK_BLOCK: '[homework] fetch homework block',
    FETCH_HOMEWORK_BLOCK_DATA: '[homework] fetch homework block data',
    UPDATE_HOMEWORK_STATUS: '[homework] update homework status',
    RESET_HOMEWORK_BLOCK_DATA_STATE: '[homework] reset homework block data state',
    FETCH_HOMEWORK_BLOCKS: '[homework] fetch homework blocks',
    RESET_HOMEWORK_BLOCKS_CURRENT_PAGE: '[homework] reset homework blocks current page',
    SET_HOMEWORK_BLOCKS_CURRENT_PAGE: '[homework] set homework blocks current page',
});

const errorHandler = (error, actionName) => {
    const errorText = `error in ${actionName} action`;

    console.error(errorText, error);

    return Promise.reject(error);
};

export const homeworkActions = {
    async [homeworkActionsTypes.FETCH_HOMEWORK_BLOCKS]({ commit, getters }, { isReplaceContent, ...rest }) {
        commit(homeworkMutationTypes.SET_HOMEWORK_BLOCKS_LOADING, true);

        try {
            const { data } = await getVersionHomeworkActivities({ ...rest });

            const blocksData = isReplaceContent ? data.data : [...getters[homeworkGettersTypes.GET_HOMEWORK_BLOCKS], ...data.data];

            commit(homeworkMutationTypes.SET_HOMEWORK_BLOCKS, blocksData);
            commit(homeworkMutationTypes.SET_HOMEWORK_BLOCKS_CURRENT_PAGE, data.meta.current_page);
            commit(homeworkMutationTypes.SET_HOMEWORK_BLOCKS_LAST_PAGE, data.meta.last_page);
        } catch (error) {
            return errorHandler(error, homeworkActionsTypes.FETCH_HOMEWORK_BLOCKS);
        } finally {
            commit(homeworkMutationTypes.SET_HOMEWORK_BLOCKS_LOADING, false);
        }
    },
    async [homeworkActionsTypes.FETCH_HOMEWORK_BLOCK]({ commit, dispatch }, { homeworkId }) {
        commit(homeworkMutationTypes.SET_HOMEWORK_BLOCK_LOADING, true);

        try {
            const { data } = await getHomeworkBlock(homeworkId);

            commit(homeworkMutationTypes.SET_HOMEWORK_BLOCK, data.data);

            return Promise.resolve(data.data);
        } catch (error) {
            dispatch('globals/setGlobalerrorFromResponseData', error, { root: true });

            return errorHandler(error, homeworkActionsTypes.FETCH_HOMEWORK_BLOCK);
        } finally {
            commit(homeworkMutationTypes.SET_HOMEWORK_BLOCK_LOADING, false);
        }
    },
    async [homeworkActionsTypes.FETCH_HOMEWORK_BLOCK_DATA]({ commit, getters }, { isReplaceContent, ...rest }) {
        commit(homeworkMutationTypes.SET_HOMEWORK_BLOCK_DATA_LOADING, true);

        try {
            const { data } = await getHomeworkBlockList({
                ...rest,
            });

            const homeworkBlockData = isReplaceContent ? data.data : [...getters[homeworkGettersTypes.GET_HOMEWORK_BLOCK_DATA], ...data.data];

            commit(homeworkMutationTypes.SET_HOMEWORK_BLOCK_DATA_CURRENT_PAGE, data.meta.current_page);
            commit(homeworkMutationTypes.SET_HOMEWORK_BLOCK_DATA_LAST_PAGE, data.meta.last_page);
            commit(homeworkMutationTypes.SET_HOMEWORK_BLOCK_DATA_TOTAL_COUNT, data.meta.total);
            commit(homeworkMutationTypes.SET_HOMEWORK_BLOCK_DATA, homeworkBlockData);

            return Promise.resolve(data.data);
        } catch (error) {
            return errorHandler(error, homeworkActionsTypes.FETCH_HOMEWORK_BLOCK_DATA);
        } finally {
            commit(homeworkMutationTypes.SET_HOMEWORK_BLOCK_DATA_LOADING, false);
        }
    },
    [homeworkActionsTypes.RESET_HOMEWORK_BLOCK_DATA_STATE]({ commit }) {
        commit(homeworkMutationTypes.RESET_HOMEWORK_BLOCK_DATA_STATE);
    },
    [homeworkActionsTypes.RESET_HOMEWORK_BLOCKS_CURRENT_PAGE]({ commit }) {
        commit(homeworkMutationTypes.SET_HOMEWORK_BLOCKS_CURRENT_PAGE, 1);
    },

    [homeworkActionsTypes.SET_HOMEWORK_BLOCKS_CURRENT_PAGE]({ commit }, value) {
        commit(homeworkMutationTypes.SET_HOMEWORK_BLOCKS_CURRENT_PAGE, value);
    },
    [homeworkActionsTypes.UPDATE_HOMEWORK_STATUS]({ commit, getters }, { homeworkId, status }) {
        const homeworkBlockData = getters[homeworkGettersTypes.GET_HOMEWORK_BLOCK_DATA];
        const updatedHomeworkBlockData = homeworkBlockData.map(homework => (homework.homework?.id === homeworkId
            ? getNewBlockInstancePatch(homework, [['/homework/status', status]])
            : homework));

        commit(homeworkMutationTypes.SET_HOMEWORK_BLOCK_DATA, updatedHomeworkBlockData);
    },
};
