<template>
    <div class="user-item d-flex justify-content-between align-items-center py-2">
        <div class="d-flex align-items-center">
            <div class="mr-2">
                <img
                    class="user-item__avatar"
                    :src="member.avatar">
            </div>
            <div>
                <div class="d-flex align-items-center mb-1">
                    <div class="user-item__name">
                        {{ member.name }}
                    </div>
                    <div
                        v-if="isYou"
                        class="user-item__you ml-1">
                        Вы
                    </div>
                </div>
                <div class="user-item__text d-flex align-items-center">
                    <div>{{ member.email }}</div>
                    <div class="user-item__circle mx-1"></div>
                    <div>{{ member.login }}</div>
                </div>
            </div>
        </div>
        <slot name="action"></slot>
    </div>
</template>

<script>
export default {
    name: 'UserItem',
    props: {
        member: {
            type: Object,
            default: () => ({}),
            required: true,
        },
        isYou: {
            type: Boolean,
            default: false,
        },
    },

};
</script>

<style lang="scss" scoped>
.user-item {
    border-bottom: 1px solid #D6D9DF;

    &:last-child {
        border: none;
    }
}

.user-item__avatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.user-item__name {
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
}

.user-item__you {
    color: $primary;
    background: $lightblue;
    font-size: 12px;
    font-weight: 450;
    line-height: 16px;
    border-radius: 5px;
    padding: 2px 10px;
}

.user-item__circle {
    width: 4px;
    height: 4px;
    background: #74777D;
    border-radius: 50%;
}

.user-item__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #74777D;
}
</style>
