<template>
    <slot></slot>
</template>

<script>
import { hashLinkScroll } from '@/utils/handleHashScroll.js';

export default {
    name: 'HashScrollComponent',
    watch: {
        '$route.hash': {
            handler(hash) {
                if (!hash) return;

                hashLinkScroll(hash);
            },
            immediate: true,
        },
    },

};
</script>
