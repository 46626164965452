import { Utils } from '@sbs/uikit-constructor';

export const SubtitleExtensions = [
    Utils.FileTypes.AllFileExtensions.VTT,
    Utils.FileTypes.AllFileExtensions.SRT,
];

export const FileTypes = {
    document: {
        EXTENSIONS: Utils.FileTypes.DOCUMENT_EXTENSIONS.filter(item => !SubtitleExtensions.includes(item)),
        ICON: 'file-alt',
        ICON_COLOR: 'blue',
        STROKE_COLOR: 'blue',
        BACKGROUND_COLOR: 'gradient-pale-blue',
    },
    subtitles: {
        EXTENSIONS: SubtitleExtensions,
        ICON: 'file',
        ICON_COLOR: 'pink',
        STROKE_COLOR: 'pink',
        BACKGROUND_COLOR: 'gradient-pale-pink',
    },
    picture: {
        EXTENSIONS: Utils.FileTypes.PICTURE_EXTENSIONS,
        ICON: 'image',
        ICON_COLOR: 'green',
        STROKE_COLOR: 'green',
        BACKGROUND_COLOR: 'gradient-pale-green',
    },
    archive: {
        EXTENSIONS: Utils.FileTypes.ARCHIVE_EXTENSIONS,
        ICON: 'file-archive',
        ICON_COLOR: 'purple',
        STROKE_COLOR: 'purple',
        BACKGROUND_COLOR: 'gradient-pale-purple',
    },
    audio: {
        EXTENSIONS: Utils.FileTypes.AUDIO_EXTENSIONS,
        ICON: 'music-alt',
        ICON_COLOR: 'pink',
        STROKE_COLOR: 'pink',
        BACKGROUND_COLOR: 'gradient-pale-pink',
    },
    video: {
        EXTENSIONS: Utils.FileTypes.VIDEO_EXTENSIONS,
        ICON: 'video',
        ICON_COLOR: 'yellow',
        STROKE_COLOR: 'yellow',
        BACKGROUND_COLOR: 'gradient-pale-yellow',
    },
    other: {
        ICON: 'file',
        ICON_COLOR: 'orange',
        STROKE_COLOR: 'orange',
        BACKGROUND_COLOR: 'gradient-pale-orange',
    },
};

export const getFileType = ext => {
    const [fileType] = Object.entries(FileTypes)
        .find(([_, val]) => val.EXTENSIONS?.includes(ext.toLowerCase())) ?? [];

    return fileType || 'other';
};
