<template>
    <SbsPopup
        v-model="isShowPopup"
        :triggers="['click', 'hover']"
        :hideTriggers="[]"
        class="EventDatePopup"
        popupClass="EventDatePopup__Popup"
        :maxWidth="250"
        @show="handleShowPopup">
        <slot></slot>
        <template #content>
            <div class="EventDatePopup__Title">
                Дата проведения
            </div>
            <div class="EventDatePopup__Items">
                <div
                    v-for="date in eventSchedule"
                    :key="date"
                    class="EventDatePopup__Item">
                    <div class="EventDatePopup__ItemDate">
                        {{ formattedDate(date.day) }}:
                    </div>
                    <div class="EventDatePopup__ItemTimes">
                        <div
                            v-for="time in date.times"
                            :key="time"
                            class="EventDatePopup__ItemTime">
                            {{ formattedTime(time) }}
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </SbsPopup>
</template>

<script>
import { hideAllPoppers } from 'floating-vue';

export default {
    name: 'EventDatePopup',
    emits: ['update:modelValue'],
    computed: {
        isShowPopup: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
    },
    methods: {
        formattedTime(time) {
            return time.split('-').join(' — ');
        },
        formattedDate(date) {
            return date.replaceAll('-', '.');
        },
        handleShowPopup() {
            hideAllPoppers();
            this.isShowPopup = true;
        },
    },
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
        eventSchedule: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="scss">
.EventDatePopup__Popup {
  padding: 20px 25px;
}

.EventDatePopup__Title {
    margin-bottom: 15px;
    @include typo-primary-medium;
}

.EventDatePopup__Items {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.EventDatePopup__Item {
    display: flex;
    gap: 10px;
    @include typo-additional-demi;
}

.EventDatePopup__ItemDate {
    width: 85px;
    color: $gray-deep;
    font-weight: 400;
}
</style>
