import axios from 'axios';
import { BASE_URI_DEVELOPMENT_CENTER } from '@/api/CONST.js';

const prefix = `${BASE_URI_DEVELOPMENT_CENTER}/editor/user/colors`;

/**
 * get user favorite colors for wysiwyg redactor
 * @returns Promise
 */
export const getUserEditorColors = () => axios.get(prefix);

/**
 * add user favorite color for wysiwyg redactor
 * @param {number|string} color
 * @returns Promise
 */
export const addUserEditorColor = color => axios.post(prefix, { color });

/**
 * remove user favorite color for wysiwyg redactor by id
 * @param {number|string} id
 * @returns Promise
 */
export const deleteUserEditorColor = id => axios.delete(`${prefix}/${id}`);
