<template>
    <div class="ErrorContent">
        <div class="ErrorContent__Wrapper trs">
            <InlineSvg
                :src="icon"
                class="ErrorContent__Icon" />
            <h3 class="ErrorContent__Title">
                {{ title }}
            </h3>
            <p class="ErrorContent__SubTitle">
                {{ subtitle }}
            </p>
            <SbsButton
                v-if="navBtnLabel && showNavBtn"
                class="ErrorContent__GoToBtn"
                borderTheme="primary"
                colorTheme="black"
                backgroundTheme="transparent"
                :text="navBtnLabel"
                size="large"
                static
                @click="$emit('click:navBtn')" />
        </div>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
    name: 'ErrorContent',
    components: {
        InlineSvg,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        navBtnLabel: {
            type: String,
            default: null,
        },
        showNavBtn: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['click:navBtn'],
};
</script>

<style lang="scss">
.ErrorContent {
    max-width: 495px;
    margin: auto;
}

.ErrorContent__Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
    border-radius: 15px;
}

.ErrorContent__Icon {
    margin-bottom: 25px;
}

.ErrorContent__Title {
    width: 456px;
    margin-bottom: 5px;
    text-align: center;

    @include typo-h3-bold;
}

.ErrorContent__SubTitle {
    width: 380px;
    margin-bottom: 20px;
    text-align: center;

    @include typo-secondary-book;
}

.ErrorContent__GoToBtn {
    box-sizing: border-box;
    padding: 12px 40px;

    @include typo-primary-bold;
}
</style>
