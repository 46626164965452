import { ACTIVITY_BLOCK_TYPES } from '@/constants/index.js';

export const activityTypes = [
    {
        category_name: 'Избранное',
        category_type: 'favorites',
        icon: 'heart',
        children: [],
    },
    {
        category_name: 'Текстовые блоки',
        category_type: 'text_blocks',
        icon: 'file-alt',
        children: [],
    },
    {
        category_name: 'Изображения',
        category_type: 'images',
        icon: 'image',
        children: [],
    },
    {
        category_name: 'Медиа',
        category_type: 'media',
        icon: 'film',
        children: [],
    },
    {
        category_name: 'Тесты и практика',
        category_type: 'tests',
        icon: 'tasks',
        children: [],
    },
    {
        category_name: 'Внешние блоки',
        category_type: 'external_blocks',
        icon: 'external-link-alt',
        children: [],
    },
];

export const textTypes = [
    ACTIVITY_BLOCK_TYPES.Text,
    ACTIVITY_BLOCK_TYPES.Accordion,
    ACTIVITY_BLOCK_TYPES.Tabs1,
    ACTIVITY_BLOCK_TYPES.TextWithIcons,
    ACTIVITY_BLOCK_TYPES.FAQ,
    ACTIVITY_BLOCK_TYPES.People,
    ACTIVITY_BLOCK_TYPES.Quote,
    ACTIVITY_BLOCK_TYPES.FlashCards,
];
export const imageTypes = [ACTIVITY_BLOCK_TYPES.ImageSlider, ACTIVITY_BLOCK_TYPES.TextImage];
export const mediaTypes = [ACTIVITY_BLOCK_TYPES.Video, ACTIVITY_BLOCK_TYPES.File, ACTIVITY_BLOCK_TYPES.Audio];
export const externalBlocksTypes = [ACTIVITY_BLOCK_TYPES.Link, ACTIVITY_BLOCK_TYPES.Event, ACTIVITY_BLOCK_TYPES.PersonalData];
export const testTypes = [
    ACTIVITY_BLOCK_TYPES.TestOrQuiz,
    ACTIVITY_BLOCK_TYPES.HomeworkFiles,
    ACTIVITY_BLOCK_TYPES.EssayHomework,
];
