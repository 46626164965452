import axios from 'axios';
import { BASE_URI_DEVELOPMENT_CENTER } from '@/api/CONST.js';

const prefix = BASE_URI_DEVELOPMENT_CENTER;

/**
 * get activity block links
 *
 * @param   {number | string}  activityId  activity id
 *
 * @returns Promise
 */
export const getActivityLinks = activityId => axios.get(`${prefix}/activities/${activityId}/links`);

/**
 * get activity link data
 *
 * @param   {number | string}  activityId  activity id
 * @param   {number | string}  linkId      link id
 *
 * @returns Promise
 */
export const getActivityLinkData = (activityId, linkId) => axios.get(`${prefix}/activities/${activityId}/links/${linkId}`);

/**
 * add activity link
 *
 * @param   {number | string}  activityId  activity id
 * @param   {object}  linkData  link data
 * @param   {string}  linkData.url  link url
 * @param   {string}  [linkData.title]  link title
 *
 * @returns Promise
 */
export const addActivityLink = (activityId, linkData) => axios.post(`${prefix}/activities/${activityId}/links`, linkData);

/**
 * sort activity links
 *
 * @param   {number | string}  activityId  activity id
 *
 * @returns Promise
 */
export const sortActivityLinks = activityId => axios.post(`${prefix}/activities/${activityId}/links/sort`);

/**
 * delete activity link
 *
 * @param   {number | string}  activityId    activity id
 * @param   {number | string}  linkId        link id
 *
 * @returns Promise
 */
export const removeActivityLink = (activityId, linkId) => axios.delete(`${prefix}/activities/${activityId}/links/${linkId}`);

/**
 * delete activity link
 *
 * @param   {number | string}  activityId    activity id
 * @param   {number | string}  linkId        link id
 *
 * @returns Promise
 */
export const restoreActivityLink = (activityId, linkId) => axios.post(`${prefix}/activities/${activityId}/links/${linkId}/restore`);
