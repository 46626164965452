import { ref } from 'vue';

export const ContainersNames = {
    ADMIN_VIEW_MAIN: 'ADMIN_VIEW_MAIN',
};

const pageContainers = ref({});

export const usePageContainers = () => {
    const setPageContainerWithName = (name, node) => {
        pageContainers.value[name] = node;
    };

    return {
        pageContainers,
        setPageContainerWithName,
    };
};
