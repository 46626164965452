export default {
    namespaced: true,
    state: {
        sourceLinks: [],
    },
    getters: {
        getSourceLink: state => blockId => state.sourceLinks.find(el => el.blockId === blockId)?.value,
    },
    mutations: {
        SET_SOURCE_LINK(state, payload) {
            const { value, blockId } = payload;


            const foundBlock = state.sourceLinks.some(el => {
                if (el.blockId === blockId) {
                    el.value = value;

                    return true;
                }

                return false;
            });

            if (!foundBlock) {
                state.sourceLinks.push({
                    value,
                    blockId,
                });
            }
        },
    },
};
