import axios from 'axios';
import { BASE_URI, BASE_URI_DEVELOPMENT_CENTER } from '@/api/CONST.js';


const PREFIX = String(BASE_URI_DEVELOPMENT_CENTER);

/**
 * get user data
 *
 * @return  {[Promise]}        [return user data]
 */
export const getUser = () => {
    const path = `${BASE_URI}/user`;

    return axios.get(path);
};

/**
 * logout user
 * @param {FormData} data logout formData
 * @returns Promise
 */
export const logout = data => {
    const url = `${BASE_URI}/logout`;

    return axios({
        method: 'post',
        url,
        data,
        headers: { 'Content-Type': 'multipart/form-data' },
    });
};

/**
 * get users list with search
 * @param {string} search string
 * @returns Promise
 */
export const searchUsers = search => axios.get(`${PREFIX}/users`, { params: { search } });
