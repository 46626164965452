<template>
    <SbsTooltip
        content="Действия"
        :disabled="isActive">
        <SbsButtonIcon v-bind="actionButtonData" />
    </SbsTooltip>
</template>

<script>
export default {
    name: 'HomeworkActionButton',
    computed: {
        actionButtonData() {
            return {
                class: 'HomeworkActionButton',
                iconName: 'ellipsis',
                bordered: false,
                colorTheme: this.isActive ? 'black' : 'black',
                backgroundTheme: this.isActive ? 'gray' : 'white',
                hoverBackground: 'gray',
                hoverColor: 'black',
            };
        },
    },
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.HomeworkActionButton {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  color: #000;
}
</style>
