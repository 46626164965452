import { ref } from 'vue';


const isShow = ref(false);
const options = ref({
    title: '',
    confirmButtonText: null,
    cancelButtonText: null,
    description: null,
    accept: null,
    reject: null,
});

const handleConfirm = async () => {
    await options.value.accept?.();
    isShow.value = false;
};

const handleCancel = async () => {
    await options.value.reject?.();
    isShow.value = false;
};

export const useConfirmModal = () => ({
    isShow,
    options,
    handleConfirm,
    handleCancel,
});

/**
 * @typedef {object} ConfirmOptions
 * @property {string} title
 * @property {string} confirmButtonText
 * @property {string} cancelButtonText
 * @property {string} [description]
 * @property {Function} accept
 * @property {Function} reject
 * /

/**
 * @type {function(ConfirmOptions):void}
 */
const confirm = confirmOptions => {
    options.value = confirmOptions;
    isShow.value = true;
};

export const useConfirm = () => confirm;


