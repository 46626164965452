export const searchGetterTypes = Object.freeze({
    GET_SEARCH_DATA: '[search] get search data',
    GET_SEARCH_HISTORY: '[search] get search history',
    GET_SEARCH_LOADING: '[search] get search loading',
    GET_NOT_FOUND_DATA: '[search] get not found data',
});

export const searchGetters = {
    [searchGetterTypes.GET_SEARCH_DATA]: state => state.searchData,
    [searchGetterTypes.GET_SEARCH_HISTORY]: state => state.searchHistory,
    [searchGetterTypes.GET_SEARCH_LOADING]: state => state.loading,
    [searchGetterTypes.GET_NOT_FOUND_DATA]: state => state.isNotFoundData,
};
