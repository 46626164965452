<template>
    <!--
        TODO: компонент требует полного рефактора. Убрать бутстрап классы,
        компоненты меню и сабменю вынести в отдельные компоненты и подключать через динамические компонентыб
        отрефакторить по всему проекту position для сабменю
    -->
    <div class="options-popup">
        <SbsPopup
            v-bind="popupData"
            v-model="isPopupOpen"
            class="options-popup__popper"
            :class="popperClass"
            :popupClass="popupClass">
            <template #content>
                <template v-for="item in popupContent">
                    <template v-if="item.type === 'item' || item.type === 'radio'">
                        <SbsTooltip
                            v-bind="item.disabledTooltipData"
                            :key="item.id"
                            :disabled="!item.disabled"
                            fullWidth>
                            <SbsRadio
                                v-if="item.type === 'radio'"
                                class="options-popup__submenu-radio px-3"
                                hoverBackground="transparent"
                                :class="[item.classes, { 'opacity-40': item.disabled }]"
                                :value="item.eventValue"
                                :modelValue="item.value"
                                :disabled="item.disabled"
                                :label="item.text"
                                @click="handleRadioChange(item, item.eventValue)" />
                            <SbsLink
                                v-else
                                v-bind="linkData"
                                class="py-1 px-5 w-100"
                                :class="[
                                    item.classes, {
                                        'opacity-40': item.disabled,
                                    },
                                ]"
                                :text="item.text"
                                :disabled="item.disabled"
                                @click="closePopupAndEmit(item)">
                                <template #prepend>
                                    <InlineSvg
                                        v-if="item.iconInline"
                                        class="mr-2 flex-shrink-0"
                                        :src="item.iconInline"
                                        v-bind="item.iconSize ?? {}" />
                                    <SbsIcon
                                        v-else-if="item.iconName"
                                        class="mr-2 flex-shrink-0"
                                        :iconName="item.iconName" />
                                </template>
                            </SbsLink>
                        </SbsTooltip>
                    </template>

                    <template v-if="item.type === 'submenu'">
                        <div
                            :key="item.id"
                            class="options-item__have-submenu">
                            <component
                                :is="isPopupOpen ? 'SbsTooltip' : 'div'"
                                :triggers="['click']"
                                :position="submenuTooltipPosition"
                                :offset="0"
                                :skidding="submenuTooltipSkidding"
                                :delay="{
                                    show: 0,
                                    hide: 0,
                                }"
                                tooltipClass="options-popup__submenu bg-white py-3 px-0"
                                fullWidth
                                autoHide
                                @show="handleOpenSubmenu(item)"
                                @hide="handleCloseSubmenu(item)">
                                <SbsTooltip
                                    v-bind="item.disabledTooltipData"
                                    :disabled="!item.disabled"
                                    fullWidth>
                                    <SbsLink
                                        v-bind="linkData"
                                        class="w-100 py-1 px-5"
                                        :class="[
                                            item.classes, {
                                                'bg-lightblue': isSubmenuOpen && openedSubmenuId === item.id,
                                                'opacity-40': item.disabled,
                                            },
                                        ]"
                                        :disabled="item.disabled"
                                        :text="item.text">
                                        <template #prepend>
                                            <InlineSvg
                                                v-if="item.iconInline"
                                                class="mr-2"
                                                :src="item.iconInline" />
                                            <SbsIcon
                                                v-else
                                                class="mr-2"
                                                :iconName="item.iconName" />
                                        </template>
                                        <template #append>
                                            <SbsIcon iconName="chevron-right" />
                                        </template>
                                    </SbsLink>
                                </SbsTooltip>
                                <template #content>
                                    <SbsTooltip
                                        v-for="(submenuItem, submenuIndex) in submenuData.items"
                                        :key="submenuIndex"
                                        class="bg-hover-lightblue"
                                        v-bind="submenuItem.disabledTooltipData"
                                        :disabled="!submenuItem.disabled"
                                        fullWidth>
                                        <SbsRadio
                                            v-if="submenuItem.type === 'radio'"
                                            class="options-popup__submenu-radio px-3"
                                            hoverBackground="transparent"
                                            :class="[submenuItem.classes, { 'opacity-40': submenuItem.disabled }]"
                                            :value="submenuItem.eventValue"
                                            :modelValue="submenuItem.value"
                                            :disabled="submenuItem.disabled"
                                            :label="submenuItem.text"
                                            @click="handleRadioChange(submenuItem, submenuItem.eventValue)" />
                                        <SbsLink
                                            v-else
                                            v-bind="linkData"
                                            :disabled="submenuItem.disabled"
                                            :class="[submenuItem.classes, { 'opacity-40': submenuItem.disabled }]"
                                            class="py-1 px-5 w-100 options-popup__submenu-item"
                                            :text="submenuItem.text"
                                            @click="closePopupAndEmit(submenuItem)">
                                            <template #prepend>
                                                <InlineSvg
                                                    v-if="submenuItem.iconInline"
                                                    class="mr-2"
                                                    :src="submenuItem.iconInline" />
                                                <SbsIcon
                                                    v-else
                                                    class="mr-2"
                                                    :iconName="submenuItem.icon" />
                                            </template>
                                        </SbsLink>
                                    </SbsTooltip>
                                </template>
                            </component>
                        </div>
                    </template>

                    <template v-if="item.type === 'switcher'">
                        <SbsTooltip
                            v-bind="item.disabledTooltipData"
                            :key="item.id"
                            :disabled="!item.disabled"
                            fullWidth>
                            <SbsBaseSwitcher
                                v-model="item.switcherValue"
                                class="py-1 px-5 w-100"
                                :class="[item.classes, { 'opacity-40': item.disabled }]"
                                labelClass="w-100"
                                :disabled="item.disabled"
                                backgroundTheme="primary"
                                @change="forwardEvent(item.handler, modelValue)">
                                <template #default>
                                    <InlineSvg
                                        v-if="item.iconInline"
                                        class="mr-2 flex-shrink-0"
                                        :src="item.iconInline" />
                                    <SbsIcon
                                        v-else
                                        class="mr-2 flex-shrink-0"
                                        :iconName="item.iconName" />
                                    <div class="w-100">
                                        {{ item.text }}
                                    </div>
                                </template>
                            </SbsBaseSwitcher>
                        </SbsTooltip>
                    </template>

                    <template v-if="item.type === 'upload'">
                        <SbsTooltip
                            v-bind="item.disabledTooltipData"
                            :key="item.id"
                            :disabled="!item.disabled"
                            fullWidth>
                            <SbsFileUploader
                                class="options-item__upload"
                                v-bind="linkData"
                                :acceptFileExtensions="item.fileExtensions"
                                @change="(file) => handleUpload(item, file)">
                                <SbsLink
                                    v-bind="linkData"
                                    class="py-1 px-5 w-100"
                                    :class="[
                                        item.classes, {
                                            'opacity-40': item.disabled,
                                        },
                                    ]"
                                    :text="item.text"
                                    :disabled="item.disabled">
                                    <template #prepend>
                                        <InlineSvg
                                            v-if="item.iconInline"
                                            class="mr-2 flex-shrink-0"
                                            :src="item.iconInline" />
                                        <SbsIcon
                                            v-else
                                            class="mr-2 flex-shrink-0"
                                            :iconName="item.iconName" />
                                    </template>
                                </SbsLink>
                            </SbsFileUploader>
                        </SbsTooltip>
                    </template>
                    <div
                        v-if="item.type === 'divider'"
                        :key="item.id"
                        class="options-popup__divider bg-gray-light my-2 mx-4"></div>
                </template>
            </template>
            <slot
                v-bind="{
                    isOpen: isPopupOpen,
                    toggle: togglePopup,
                }">
                <SbsButtonIcon
                    class="options-popup__open-button rounded-circle"
                    v-bind="openButtonData"
                    iconName="ellipsis"
                    @click.stop="togglePopup" />
            </slot>
        </SbsPopup>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import { usePageContainers } from '@/hooks/pageContainers.js';

const TOP_SKIDDING = -20;
const BOTTOM_SKIDING = 20;

export default {
    name: 'OptionsPopup',
    components: {
        InlineSvg,
    },
    emits: ['event:forward', 'update:modelValue'],
    data() {
        return {
            isPopupOpen: this.modelValue ?? false,
            isSubmenuOpen: false,
            submenuData: {},
        };
    },
    computed: {
        openButtonData() {
            return {
                iconName: 'ellipses',
                colorTheme: this.isPopupOpen ? 'primary' : 'black',
                bordered: !this.isPopupOpen,
                borderTheme: this.isPopupOpen ? 'lightblue' : 'gray-light',
                backgroundTheme: this.isPopupOpen ? 'lightblue' : 'white',
                hoverBackground: 'lightblue',
                hoverColor: 'primary',
                hoverBorder: 'lightblue',
            };
        },
        popupData() {
            return {
                popupContentClass: 'options-popup__content',
                showCloseButton: false,
                position: this.position,
                width: this.width,
                offset: this.offset,
            };
        },
        popupClass() {
            return this.isSubmenuOpen ? `options-popup__popup options-popup__popup--open ${this.submenuData.position} py-4 px-0` : `options-popup__popup py-4 px-0 ${this.poppupClass}`;
        },
        linkData() {
            return {
                colorTheme: 'black',
                hoverBackground: 'lightblue',
                underlined: false,
            };
        },
        submenuTooltipSkidding() {
            if (this.submenuData.position?.includes('top')) {
                return TOP_SKIDDING;
            }

            if (this.submenuData.position?.includes('bottom')) {
                return BOTTOM_SKIDING;
            }

            return 0;
        },
        submenuTooltipPosition() {
            if (this.submenuData.position === 'right') return this.submenuData.position;

            switch (this.submenuData.position) {
                case 'top-left':
                    return 'left-start';

                case 'bottom-left':
                    return 'left-end';

                case 'top-right':
                    return 'right-start';

                case 'bottom-right':
                    return 'right-end';

                default:
                    return 'left';
            }
        },
    },
    methods: {
        forwardEvent(name, value) {
            this.$emit('event:forward', name, value);
        },
        togglePopup() {
            this.isPopupOpen = !this.isPopupOpen;
        },
        setSubmenuData(data) {
            this.submenuData = data.submenu ?? {};
        },
        setOpenSubmenu(id) {
            this.isSubmenuOpen = true;
            this.openedSubmenuId = id;
        },
        setCloseSubmenu() {
            this.isSubmenuOpen = false;
            this.openedSubmenuId = null;
        },
        handleOpenSubmenu(item) {
            if (item?.disabled) return;

            setTimeout(() => {
                this.setSubmenuData(item);
                this.setOpenSubmenu(item.id);
            });
        },
        handleCloseSubmenu(item) {
            if (item?.disabled) return;

            this.setCloseSubmenu();
        },
        closePopupAndEmit(item) {
            if (item.disabled) return;

            this.togglePopup();
            this.setCloseSubmenu();
            this.forwardEvent(item.handler);
        },
        handleRadioChange(item, value) {
            if (item.disabled) return;

            this.forwardEvent(item.handler, value);
            setTimeout(() => {
                this.togglePopup();
                this.setCloseSubmenu();
            });
        },
        handleUpload(item, file) {
            this.forwardEvent(item.handler, file);
            this.togglePopup();
        },
    },
    props: {
        popupContent: {
            type: Array,
            default: () => [],
        },
        position: {
            type: String,
            default: 'bottom',
        },
        width: {
            type: [String, Number],
            default: null,
        },
        modelValue: {
            type: Boolean,
            default: false,
        },
        offset: {
            type: Number,
            default: 0,
        },
        popperClass: {
            type: String,
            default: '',
        },
        poppupClass: {
            type: String,
            default: '',
        },
    },
    watch: {
        isPopupOpen(value) {
            this.$emit('update:modelValue', value);

            if (value) return;

            this.isSubmenuOpen = value;
            this.setCloseSubmenu();
        },
        modelValue(value) {
            this.isPopupOpen = value;
        },
    },
    setup() {
        const { pageContainers } = usePageContainers();

        return { pageContainers };
    },
};
</script>

<style lang="scss">
.options-popup__content {
    overflow: visible;
}

.options-popup__open-button {
    width: 40px;
    height: 40px;
}

.options-popup__divider {
    height: 1px;
}

.options-item__have-submenu {
    .sbs-link__text-wrapper {
        width: 100%;
    }
}

.options-popup__submenu {
    width: max-content;
    border-radius: 10px 0 0 10px;
    border: 1px solid $gray-light;
    color: $black;
    text-align: left;
    opacity: 1;
    &-item {
        white-space: nowrap;
    }
}

.options-popup__submenu-radio {
    .sbs-radio__label {
        word-break: normal;
    }
}

.options-popup__popup {
    border-radius: 10px;
    border: 1px solid $gray-light;

    & .sbs-file-uploader {
        width: 100%;
    }
}

.options-popup__popup--open {
    &.top-left {
        border-radius: 0 10px 10px 10px;
    }
    &.top-right {
        border-radius: 10px 0 10px 10px;
    }
    &.bottom-left {
        border-radius: 10px 10px 10px 0;
    }
    &.bottom-right {
        border-radius: 10px 10px 0 10px;
    }
}
</style>
