<template>
    <div class="StructureFiltersMembersSearch">
        <div class="StructureFiltersMembersSearch__Header">
            <h4 class="StructureFiltersMembersSearch__Title">
                {{ title || 'Команда' }}
            </h4>
            <div
                v-if="activeMembers.length"
                class="StructureFiltersMembersSearch__Chip">
                <div class="StructureFiltersMembersSearch__ChipText">
                    {{ membersLabel }}
                </div>
                <SbsIcon
                    class="StructureFiltersMembersSearch__ChipIcon"
                    п
                    width="8"
                    height="8"
                    iconName="xmark"
                    colorTheme="primary"
                    @click="clearMembersList" />
            </div>
        </div>
        <div class="StructureFiltersMembersSearch__Input">
            <SbsInput
                v-model="query"
                backgroundTheme="transparent"
                borderTheme="gray-light"
                colorTheme="primary"
                inputFaderClass="bg-gradient-gray-ultra-light-left"
                placeholder="Поиск по участникам центра разработки"
                faderVisible
                fullWidth>
                <template #prepend>
                    <SbsIcon
                        class="StructureFiltersMembersSearch__InputIconSearch"
                        iconName="search"
                        colorTheme="black" />
                </template>
                <template
                    v-if="!!query"
                    #append>
                    <SbsIcon
                        :width="20"
                        :height="20"
                        class="StructureFiltersMembersSearch__InputIconXmark"
                        iconName="xmark"
                        colorTheme="gray-deep"
                        iconSize="large"
                        @click="clearMemberTerm" />
                </template>
            </SbsInput>
        </div>
        <div class="StructureFiltersMembersSearch__List">
            <SbsCheckboxGroup
                v-if="list.length && !loadings.query"
                :modelValue="formattedMembersValue"
                @update:modelValue="handleMembersGroupChange">
                <SbsCheckbox
                    v-for="member in list"
                    :key="member.id"
                    labelClass="StructureFiltersMembersSearch__CheckboxLabel"
                    hoverBackground="transparent"
                    class="StructureFiltersMembersSearch__Checkbox"
                    :value="member.id">
                    <UserItem
                        :member="member.user"
                        class="StructureFiltersMembersSearch__User" />
                </SbsCheckbox>
                <ScrollPreloader
                    v-if="!isLastMembersPage"
                    class="StructureFiltersMembersSearch__Spinner"
                    :loading="loadings.page"
                    @load="handleScrollLoader" />
            </SbsCheckboxGroup>
            <div
                v-if="loadings.query"
                class="StructureFiltersMembersSearch__Spinner">
                <SbsSpinner
                    size="large"
                    colorTheme="primary" />
            </div>
            <EmptySearchStub
                v-else-if="isSearchListEmpty && query"
                :icon="notFoundImage"
                label="Ничего не найдено"
                subLabel="Попробуйте изменить поисковый запрос"
                class="StructureFiltersMembersSearch__Empty" />
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import _debounce from 'lodash/debounce.js';
import notFoundImage from '@/assets/svg/not-found-solutions-icon.svg';
import { getDevelopmentCenterMembers } from '@/api/developmentCentersApi.js';
import { createCancelHttp } from '@/utils/http.js';
import { getHomeworkBlockMentors } from '@/api/homeworkApi.js';
import ScrollPreloader from '@/components/UI/ScrollPreloader.vue';
import UserItem from '@/components/UI/UserItem.vue';
import EmptySearchStub from '@/components/UI/EmptySearchStub.vue';

const DEFAULT_PAGE = 1;

export default {
    name: 'StructureFiltersMembersSearch',
    components: {
        ScrollPreloader,
        UserItem,
        EmptySearchStub,
    },
    data: () => ({
        loadings: {
            query: false,
            page: false,
        },
        query: '',
        page: DEFAULT_PAGE,
        maxPage: DEFAULT_PAGE,
        list: [],
        notFoundImage,
    }),
    computed: {
        isSearchListEmpty() {
            return !this.list.length;
        },
        isLastMembersPage() {
            return this.page === this.maxPage;
        },
        membersLabel() {
            return `${this.activeMembers.length} ${this.$utils.pluralize(this.activeMembers.length, ['пользователь', 'пользователя', 'пользователей'])}`;
        },
        formattedMembersValue() {
            return this.activeMembers.map(id => Number(id));
        },
    },
    methods: {
        clearMembersList() {
            this.updateMembersFilter([]);
        },
        fetchMembers(cancelToken) {
            const { developmentCenterId } = this.$route.params;

            const options = {
                search: this.query,
                page: this.page,
            };

            const config = {
                cancelToken,
            };

            if (this.type === 'mentors') {
                if (!this.homeworkId) throw new Error('Homework required');

                return getHomeworkBlockMentors(this.homeworkId, options, config)
                    .then(resp => ({
                        data: {
                            ...resp.data,
                            data: resp.data.data.map(item => ({
                                id: item.id,
                                user: item,
                            })),
                        },
                    }));
            }

            return getDevelopmentCenterMembers(developmentCenterId, options, config);
        },
        async searchMembers(cancelToken) {
            this.loadings.query = true;
            this.list = [];
            this.page = DEFAULT_PAGE;

            try {
                const { data } = await this.fetchMembers(cancelToken);

                this.list = data.data;
                this.maxPage = data.meta.last_page;
                this.loadings.query = false;
            } catch (error) {
                if (axios.isCancel(error)) return;

                console.error(error);
                this.loadings.query = false;
            }
        },
        async handleScrollLoader() {
            if (this.loadings.page) return;

            this.loadings.page = true;
            this.page++;

            try {
                const { data } = await this.fetchMembers();

                this.list = [
                    ...this.list,
                    ...data.data,
                ];
            } catch (error) {
                console.error(error);
            } finally {
                this.loadings.page = false;
            }
        },
        searchUserDebounce: _debounce(/* eslint-disable */ function(cancelToken) {
            this.searchMembers(cancelToken);
        }, 500),
        clearMemberTerm() {
            this.query = '';
        },
        handleMembersGroupChange(value) {
            this.updateMembersFilter(value)
        },
        updateMembersFilter(value) {
            this.$emit('update:activeMembers', value);
        },
    },
    props: {
        activeMembers: {
            type: Array,
            default: () => ([])
        },
        title: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: 'members',
        },
        homeworkId: {
            type: [String, Number],
            default: null,
        },
    },
    watch: {
        query: {
            handler() {
                this.loadings.query = true;
                const cancelToken = createCancelHttp('StructureFiltersMembers').token;
                this.searchUserDebounce(cancelToken);
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss">
.StructureFiltersMembersSearch {
    &__Spinner {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
    }
    &__Header {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }
    &__Title {
        @include typo-h4-demi();
    }
    &__Subtitle {
        margin-right: 10px;
    }
    &__Chip {
        display: flex;
        align-items: center;
        font-weight: 450;
        font-size: 12px;
        line-height: 16px;
        margin-left: 10px;
        color: $primary;
        border-radius: 10px;
        padding: 6.5px 10px 5.5px 10px;
        border-bottom: 1px solid #D6D9DF;
        background: var(--lightblue);
        &:last-child {
            border: none;
        }
    }
    &__List {
        min-height: 295px;
    }
    &__ChipText {
        margin-right: 5px;
    }
    &__ChipIcon {
        cursor: pointer;
    }
    &__Input {
        margin-bottom: 10px;
    }
    &__InputIconSearch {
        margin-right: 10px;
    }
    &__InputIconXmark {
        margin-left: 10px;
        cursor: pointer;
    }
    &__User {
        padding: 0;
    }
    &__Empty  {
        margin: 15px 0 25px;
    }
}
</style>
