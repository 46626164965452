import axios from 'axios';
import { BASE_URI_DEVELOPMENT_CENTER } from '@/api/CONST.js';

/**
 * fetch tags
 * @param {object} params
 * @param {number} params.page
 * @param {'solution_member'|'version_member'} params.type
 * @returns Promise
 */
export const getTags = params => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/tags`, { params });
