import { getAllEvents } from '@/api/eventsApi.js';
import { eventsMutationTypes } from './mutations.js';

const errorHandler = (error, actionName) => {
    const errorText = `error in ${actionName} action`;

    console.error(errorText, error);

    return Promise.reject(error);
};

export const eventsActionTypes = Object.freeze({
    FETCH_EVENTS_DATA: '[events] fetch events data',
    RESET_CURRENT_PAGE: '[events] reset current page',
    SET_CURRENT_PAGE: '[events] set current page',
});

export const eventsActions = {
    async [eventsActionTypes.FETCH_EVENTS_DATA]({ commit }, { versionId }) {
        commit(eventsMutationTypes.SET_LOADING, true);

        try {
            const res = await getAllEvents(versionId);

            const eventsList = res.data.data.map(event => {
                const { data, ...rest } = event;

                return {
                    ...rest,
                    ...JSON.parse(data),
                };
            });

            commit(eventsMutationTypes.SET_EVENTS_DATA, eventsList);
            commit(eventsMutationTypes.SET_LAST_PAGE, res.data.meta.last_page);

            return Promise.resolve(res.data);
        } catch (error) {
            return errorHandler(error, eventsActionTypes.FETCH_SEARCH_DATA);
        } finally {
            commit(eventsMutationTypes.SET_LOADING, false);
        }
    },
    [eventsActionTypes.RESET_CURRENT_PAGE]({ commit }) {
        commit(eventsMutationTypes.SET_CURRENT_PAGE, 1);
    },

    [eventsActionTypes.SET_CURRENT_PAGE]({ commit }, value) {
        commit(eventsMutationTypes.SET_CURRENT_PAGE, value);
    },
};
