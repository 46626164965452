import { createStore } from 'vuex';
import globals from '@/store/modules/globals.js';
import admin from '@/admin/store/index.js';
import user from '@/store/modules/user.js';
import wysiwyg from '@/components/WysiwygRedactor/store/index.js';

const store = createStore({
    modules: {
        wysiwyg,
        globals,
        ...admin,
        user,
    },
});

export default store;
