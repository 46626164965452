import { developmentCentersGetters as getters } from '@/admin/store/modules/developmentCenters/getters.js';
import { developmentCenterMutations as mutations } from '@/admin/store/modules/developmentCenters/mutations.js';
import { developmentCentersActions as actions } from '@/admin/store/modules/developmentCenters/actions.js';
import { developmentCentersState as state } from '@/admin/store/modules/developmentCenters/state.js';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
