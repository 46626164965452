/* eslint-disable */
// https://github.com/rafgraph/react-router-hash-link/blob/main/src/index.js

import debounce from "lodash/debounce.js";
import { nextTick } from 'vue';

let hashFragment = '';
let observer = null;
let asyncTimerId = null;

const scrollFunction = async (el) => {
  await nextTick();

  el?.scrollIntoView({
    behavior: 'smooth',
    block: el.dataset.block || 'start',
  });
};

function reset() {
  hashFragment = '';
  if (observer !== null) observer.disconnect();
  if (asyncTimerId !== null) {
    window.clearTimeout(asyncTimerId);
    asyncTimerId = null;
  }
}

export function isInteractiveElement(element) {
  const formTags = ['BUTTON', 'INPUT', 'SELECT', 'TEXTAREA', 'LABEL'];
  const linkTags = ['A', 'AREA'];
  return (
    (formTags.includes(element.tagName) && !element.hasAttribute('disabled')) ||
    (linkTags.includes(element.tagName) && element.hasAttribute('href'))
  );
}

function getElAndScroll() {
  let element = null;
  if (hashFragment === '#') {
    // use document.body instead of document.documentElement because of a bug in smoothscroll-polyfill in safari
    // see https://github.com/iamdustan/smoothscroll/issues/138
    // while smoothscroll-polyfill is not included, it is the recommended way to implement smoothscroll
    // in browsers that don't natively support el.scrollIntoView({ behavior: 'smooth' })
    element = document.body;
  } else {
    // check for element with matching id before assume '#top' is the top of the document
    // see https://html.spec.whatwg.org/multipage/browsing-the-web.html#target-element
    const id = hashFragment.replace('#', '');
    element = document.getElementById(id);
    if (element === null && hashFragment === '#top') {
      // see above comment for why document.body instead of document.documentElement
      element = document.body;
    }
  }

  if (element !== null) {
    setTimeout(() => {
      scrollFunction(element);
    });

    // update focus to where the page is scrolled to
    // unfortunately this doesn't work in safari (desktop and iOS) when blur() is called
    let originalTabIndex = element.getAttribute('tabindex');
    if (originalTabIndex === null && !isInteractiveElement(element)) {
      element.setAttribute('tabindex', '-1');
    }
    element.focus({ preventScroll: true });
    if (originalTabIndex === null && !isInteractiveElement(element)) {
      // for some reason calling blur() in safari resets the focus region to where it was previously,
      // if blur() is not called it works in safari, but then are stuck with default focus styles
      // on an element that otherwise might never had focus styles applied, so not an option
      element.blur();
      element.removeAttribute('tabindex');
    }

    reset();
    return true;
  }
  return false;
}

export function hashLinkScroll(hash, timeout) {
  hashFragment = hash;
  // Push onto callback queue so it runs after the DOM is updated
  window.setTimeout(() => {
    if (getElAndScroll() === false) {
      if (observer === null) {
        observer = new MutationObserver(debounce(getElAndScroll, 300));
      }
      observer.observe(document.body, {
        attributes: true,
        childList: true,
        subtree: true,
      });
      // if the element doesn't show up in specified timeout or 10 seconds, stop checking
      asyncTimerId = window.setTimeout(() => {
        reset();
      }, timeout || 10000);
    }
  }, 0);
}
