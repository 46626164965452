export const EVENT_TYPES = {
    ONLINE: 'online',
    OFFLINE: 'offline',
};

export const LINK_TYPES = {
    OVERALL: 'overall',
    PER_EVENT: 'per_event',
};

export const PERIOD_TYPES = {
    SINGLE: 'single',
    MULTIPLE: 'multiple',
};

export const EVENT_COVER_UPLOAD_KEY = 'cover';
export const MAX_EVENT_COVER_FILE_SIZE = 10;
export const MAX_EVENT_COVER_FILE_BYTES = MAX_EVENT_COVER_FILE_SIZE * 1024 * 1024; // 10 MB
export const ACCEPT_EVENT_COVER_TYPES = ['image/jpg', 'image/jpeg', 'image/png'];
