import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

/**
 * @typedef {object} BaseEchoOptions
 * @property {string} key
 * @property {'pusher'|'socket.io'} broadcaster
 */

const pusherKey = process.env.VUE_APP_PUSHER_KEY ?? '';
const pusherCluster = process.env.VUE_APP_PUSHER_CLUSTER ?? '';

/**
 * @type Echo
 */
let echo = null;

export const getEchoInstance = () => {
    if (!echo) {
        if (!pusherKey && !pusherCluster) {
            throw new Error('Echo options not defined');
        }

        const url = new URL(process.env.VUE_APP_BACKEND_API_URL);

        /**
         * @type {BaseEchoOptions & import('pusher-js').Options}
         */
        const pusherOptions = {
            key: pusherKey,
            cluster: pusherCluster,
            broadcaster: 'pusher',
            wsHost: url.hostname,
            wsPath: '/content/ws',
            wsPort: url.port ? url.port : null,
            enabledTransports: ['ws', 'wss', 'xhr_streaming'],
            disabledTransports: ['xhr_streaming'],
            channelAuthorization: {
                transport: 'ajax',
                endpoint: '/content/broadcasting/auth',
            },
            Pusher,
        };

        echo = new Echo(pusherOptions);
    }

    return echo;
};
