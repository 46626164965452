import _chunk from 'lodash/chunk.js';
import _cloneDeep from 'lodash/cloneDeep.js';
import * as iconsApi from '@/api/iconsApi.js';

const ITEMS_PER_PAGE = 90;

const importIconsMeta = () => import(/* webpackChunkName: "icons-list" */ '@sbs/fa-icons/src/assets/meta.json');

export default {
    namespaced: true,
    state: {
        icons: [],
        usedIcons: [],
        categories: [],
        meta: {
            current_page: 1,
        },
        categoriesMeta: {},
    },
    getters: {
        icons: state => state.icons,
        isIconsEmpty: state => !state.icons.length,
        meta: state => state.meta,
        currentPage: state => Number(state.meta.current_page),
        lastPage: state => Number(state.meta.last_page),
        perPage: state => Number(state.meta.per_page),
        totalIcons: state => Number(state.meta.total),
        isLastPage: (_state, getters) => getters.currentPage === getters.lastPage,
        getCategoryLength: state => category => state.categoriesMeta[category] ?? 0,
        getUsedIcons: state => state.usedIcons,
        getCategories: state => state.categories,
    },
    mutations: {
        SET_ICONS(state, data) {
            state.icons = data;
        },
        SET_ICONS_META(state, data) {
            state.meta = data;
        },
        SET_USED_ICONS(state, data) {
            state.usedIcons = data;
        },
        SET_CATEGORIES(state, data) {
            state.categories = data;
        },
        SET_CATEGORIES_META(state, meta) {
            state.categoriesMeta = meta;
        },
    },
    actions: {
        // eslint-disable-next-line max-statements
        async fetchIcons({ commit, dispatch }, params = {}) {
            const { default: faIcons } = await importIconsMeta();

            commit('SET_CATEGORIES', _cloneDeep(faIcons));
            dispatch('setCategoriesMeta', params);

            const page = params.page ?? 1;
            const { query, categories, styles } = params;
            let iconsArray = [];

            if (categories?.length) {
                categories.forEach(item => {
                    iconsArray = [
                        ...iconsArray,
                        ...faIcons[item].icons,
                    ];
                });
            } else {
                iconsArray = Object.values(faIcons)
                    .map(item => item.icons)
                    .flat();
            }

            if (styles) {
                iconsArray = iconsArray.filter(icon => icon.styles.includes(styles));
            }

            const resultArray = query?.trim()
                ? iconsArray.filter(icon => icon.label.toLowerCase().includes(query.trim().toLowerCase()))
                : iconsArray;
            const chunks = _chunk(resultArray, ITEMS_PER_PAGE);
            // eslint-disable-next-line camelcase
            const last_page = chunks.length;
            // eslint-disable-next-line camelcase
            const current_page = page;

            return Promise.resolve({
                data: {
                    icons: chunks[page - 1],
                },
                meta: {
                    // eslint-disable-next-line camelcase
                    last_page,
                    // eslint-disable-next-line camelcase
                    current_page,
                },
            });
        },
        setIcons({ commit }, data) {
            commit('SET_ICONS', data);
        },
        setIconsMeta({ commit }, data) {
            commit('SET_ICONS_META', data);
        },
        setCategoriesMeta({ commit, getters }, params = {}) {
            const { styles, query } = params;
            const categoriesMap = getters.getCategories;
            const categoriesArray = Object.keys(categoriesMap);
            const meta = categoriesArray.reduce((prevValue, value) => {
                let icons = [...categoriesMap[value]?.icons ?? []];

                if (styles) {
                    icons = icons.filter(icon => icon.styles.includes(styles));
                }

                if (query?.trim()) {
                    icons = icons.filter(icon => icon.label.toLowerCase().includes(query.trim().toLowerCase()));
                }

                return {
                    ...prevValue,
                    [value]: icons.length,
                };
            }, {});

            commit('SET_CATEGORIES_META', meta);
        },
        async getUsedIcons({ commit }) {
            try {
                const { data } = await iconsApi.getUsedIcons();
                const icons = data.data.map(item => JSON.parse(item.icon));

                commit('SET_USED_ICONS', icons);

                return Promise.resolve(icons);
            } catch (error) {
                console.error('error in ACTION [icons] getUsedIcons', error);

                return Promise.reject(error);
            }
        },
        async addUsedIcon({ commit, getters }, addedIcon) {
            const icon = JSON.stringify(addedIcon);

            try {
                await iconsApi.postUsedIcon(icon);

                commit('SET_USED_ICONS', [...getters.getUsedIcons, addedIcon]);
            } catch (error) {
                console.error('error in ACTION [icons] addUsedIcon', error);

                return Promise.reject(error);
            }
        },
    },
};
