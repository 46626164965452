import axios from 'axios';
import { BASE_URI_DEVELOPMENT_CENTER } from '@/api/CONST.js';

const PREFIX = `${BASE_URI_DEVELOPMENT_CENTER}/search`;

/**
 * get search list
 * @param {string} developmentCenterId development center id
 * @param {object} params
 * @param {number} params.query search term
 * @param {string} params.type search type
 * @returns Promise
 */
export const getSearchData = (developmentCenterId, params) => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/${developmentCenterId}/search`, { params });

/**
 * get search history
 * @returns Promise
 */
export const getSearchHistory = () => axios.get(`${PREFIX}/history`);

/**
 * clear search history
 * @returns Promise
 */
export const clearSearchHistory = () => axios.delete(`${PREFIX}/history`);

