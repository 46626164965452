import { createApp } from 'vue';
import FloatingVue from 'floating-vue';
import SbsUiKitPlugin from '@sbs/uikit-constructor';
import '@sbs/fa-icons/dist/index.css';
import '@sbs/wysiwyg/dist/style.css';
import '@/styles/index.scss';
import 'promise-polyfill/src/polyfill.js';
import '@/global/axios.js';
import { ToastDefaultOptions, Toast } from '@/global/toasts.js';
import router from '@/router/index.js';
import store from '@/store/index.js';
import { initSentry } from '@/services/sentry.js';
import { addHandlingGlobalError } from '@/services/errors.js';
import App from '@/App.vue';

const app = createApp(App);

initSentry(app);
addHandlingGlobalError({ router });

app.use(store);
app.use(router);
app.use(SbsUiKitPlugin);
app.use(FloatingVue, {
    themes: {
        sbs: {
            $extend: 'dropdown',
            $resetCss: true,
        },
    },
});
app.use(Toast, ToastDefaultOptions);

app.config.errorHandler = (err, vm, info) => {
    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line
        console.log(err);
    }

    console.error('ERROR', {
        err,
        vm,
        info,
    });
};

app.mount('#app');
