import * as Sentry from '@sentry/vue';
import { debounce } from 'lodash';
import useSnackbar from '@/hooks/snackbars.js';

export const ERROR_CHUNK_LOADING_MSGS = [/ChunkLoadError:.*failed./i, /Loading.*chunk.*failed./i];

const showSnackbarErrorChunkLoading = err => {
    const { createSnackbar } = useSnackbar();

    Sentry.captureMessage('ERROR LOAD CHUNK', {
        level: 'error',
        extra: err,
    });

    return createSnackbar({
        type: 'error',
        size: 'large',
        title: 'Ошибка загрузки ресурсов',
        message: 'Попробуйте перезагрузить страницу',
        snackbarOptions: {
            id: 'error-loading-chunk',
        },
        actionButton: {
            text: 'Перезагрузить',
            side: 'before',
            iconName: 'rotate',
            iconGroup: 'solid',
            on: {
                click: () => {
                    Sentry.captureMessage('RELOAD ERROR CHUNK LOAD', 'error');
                    window.location.reload(true);
                },
            },
        },
    });
};

export const isErrorChunkLoading = error => ERROR_CHUNK_LOADING_MSGS.some(msg => msg.test(error?.message || ''));

/**
 * @param options
 * @param {import('vue-router').Router} options.router
 */
export const addHandlingGlobalError = options => {
    const { router } = options;

    router.onError(error => {
        if (isErrorChunkLoading(error)) {
            showSnackbarErrorChunkLoading(error);
        }
    });

    window.addEventListener(
        'error',
        debounce(error => {
            showSnackbarErrorChunkLoading(error);
        }, 300),
    );
};
