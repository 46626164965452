import axios from 'axios';
import { BASE_URI_DEVELOPMENT_CENTER } from '@/api/CONST.js';
import { objectToFormData } from '@/utils/objectToFormData.js';

const PREFIX = BASE_URI_DEVELOPMENT_CENTER;

/**
 * @param {object} params
 * @param {string|number} params.courseId
 * @param {import('axios').AxiosRequestConfig} [config]
 * @returns {Promise<import('axios').AxiosResponse<any>>}
 */
export const getCoursesStudents = ({ courseId }, config) => axios.get(`${PREFIX}/courses/${courseId}/students`, config);

/**
 * @param {object} params
 * @param {string|number} params.courseId
 * @param {string|number} params.studentId
 * @param {import('axios').AxiosRequestConfig} [config]
 * @returns {Promise<import('axios').AxiosResponse<any>>}
 */
export const getCoursesStudent = ({ courseId, studentId }, config) => axios.get(`${PREFIX}/courses/${courseId}/students/${studentId}`, {
    ...config,
});

/**
 * @param courseId
 * @param studentId
 * @param params
 * @param {import('axios').AxiosRequestConfig} [config]
 * @returns {Promise<import('axios').AxiosResponse<any>>}
 */
export const getCoursesStudentProgress = ({ courseId, studentId }, config) => axios.get(`${PREFIX}/courses/${courseId}/students/${studentId}/progress`, {
    ...config,
});

/**
 * @param {object} params
 * @param {string|number} params.courseId
 * @param {string|number} params.studentId
 * @param {number} params.entity_id
 * @param {string} params.entity_type
 * @param {string} params.status
 * @returns {Promise<import('axios').AxiosResponse<any>>}
 */
export const changeStudentMaterialProgress = ({ courseId, studentId, ...data }) => axios.put(`${PREFIX}/courses/${courseId}/students/${studentId}/progress`, data);

/**
 * @param courseId
 * @param {import('axios').AxiosRequestConfig} [config]
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getCoursesStructure = ({ courseId }, config) => axios.get(`${PREFIX}/courses/${courseId}/structure`, config);

/**
 * @param courseId
 * @param {File} file
 * @return {Promise<AxiosResponse<any>>}
 */
export const importCourseStudents = ({ courseId, file }) => {
    const formData = objectToFormData({
        courseId,
        file,
    });

    return axios.post(`${PREFIX}/courses/${courseId}/students/import`, formData);
};

export const getStudentImportTemplateUrl = () => '/content/api/development-center/students/import/template';

export const createCourseStudentsReport = ({ courseId }) => axios.get(`${PREFIX}/courses/${courseId}/students/report`);
