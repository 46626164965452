export const developmentCentersGetterTypes = Object.freeze({
    GET_DEVELOPMENT_CENTERS: '[developmentCenters] get development centers list',
    GET_SELECTED_DEVELOPMENT_CENTER: '[developmentCenters] get selected development center',
    GET_DEVELOPMENT_CENTER_BY_ID: '[developmentCenters] get development center by id',
    GET_CURRENT_DEVELOPMENT_CENTER: '[developmentCenters] get current development center',
    GET_LIST_LOADING: '[developmentCenters] get development centers list loading',
    GET_LIST_ERROR: '[developmentCenters] get development centers list error',
    GET_CURRENT_PAGE: '[developmentCenters] get current page',
    GET_IS_LAST_PAGE: '[developmentCenters] get is last page',
    GET_COUNTS: '[developmentCenters] get counts',
    GET_DEVELOPMENT_CENTER_MEMBERS: '[developmentCenters] get development center member list',
    GET_DEVELOPMENT_CENTER_MEMBER_BY_ID: '[developmentCenters] get development center member by id',
    GET_DEVELOPMENT_CENTER_MEMBERS_ERROR: '[developmentCenters] get development center member list error',
    GET_DEVELOPMENT_CENTER_MEMBERS_LOADING: '[developmentCenters] get development center member list loading',
    GET_CURRENT_MEMBERS_PAGE: '[developmentCenters] get current members page',
    GET_IS_LAST_MEMBERS_PAGE: '[developmentCenters] get is last members page',
    GET_MEMBERS_COUNTS: '[developmentCenters] get members counts',
    GET_DEVELOPMENT_CENTER_DICTIONARIES_ACCESS: '[developmentCenters] get development center dictionaries access list',
    GET_DEVELOPMENT_CENTER_DICTIONARIES_PERMISSIONS: '[developmentCenters] get development center dictionaries permissions list',
    GET_DEVELOPMENT_CENTER_DICTIONARIES_ROLES: '[developmentCenters] get development center dictionaries roles list',
    GET_DEVELOPMENT_CENTER_ACCESS_DENIED: '[developmentCenters] get development center access denied',
    // GET_CURRENT_DEVELOPMENT_CENTER_ID: '[developmentCenters] get current development center id',
});

export const developmentCentersGetters = {
    [developmentCentersGetterTypes.GET_DEVELOPMENT_CENTERS]: state => state.developmentCentersList,
    [developmentCentersGetterTypes.GET_SELECTED_DEVELOPMENT_CENTER]: state => state.selectedDevelopmentCenter,
    [developmentCentersGetterTypes.GET_DEVELOPMENT_CENTER_BY_ID]: state => id => state.developmentCentersList?.find(center => Number(center.id) === Number(id)),
    [developmentCentersGetterTypes.GET_CURRENT_DEVELOPMENT_CENTER]: state => state.currentDevelopmentCenter,
    [developmentCentersGetterTypes.GET_LIST_LOADING]: state => state.listLoading,
    [developmentCentersGetterTypes.GET_LIST_ERROR]: state => state.listError,
    [developmentCentersGetterTypes.GET_CURRENT_PAGE]: state => state.currentPage,
    [developmentCentersGetterTypes.GET_IS_LAST_PAGE]: state => state.currentPage >= state.lastPage,
    [developmentCentersGetterTypes.GET_COUNTS]: state => state.counts,
    [developmentCentersGetterTypes.GET_DEVELOPMENT_CENTER_MEMBERS]: state => state.developmentCenterMembers,
    [developmentCentersGetterTypes.GET_DEVELOPMENT_CENTER_MEMBER_BY_ID]: state => id => state.developmentCenterMembers?.find(({ user }) => Number(user.id) === Number(id)),
    [developmentCentersGetterTypes.GET_DEVELOPMENT_CENTER_MEMBERS_ERROR]: state => state.developmentCenterMembersError,
    [developmentCentersGetterTypes.GET_DEVELOPMENT_CENTER_MEMBERS_LOADING]: state => state.developmentCenterMembersLoading,
    [developmentCentersGetterTypes.GET_CURRENT_MEMBERS_PAGE]: state => state.currentMembersPage,
    [developmentCentersGetterTypes.GET_IS_LAST_MEMBERS_PAGE]: state => state.currentMembersPage >= state.lastMembersPage,
    [developmentCentersGetterTypes.GET_MEMBERS_COUNTS]: state => state.membersCounts,
    [developmentCentersGetterTypes.GET_DEVELOPMENT_CENTER_DICTIONARIES_ACCESS]: state => state.developmentCenterAccessList,
    [developmentCentersGetterTypes.GET_DEVELOPMENT_CENTER_DICTIONARIES_PERMISSIONS]: state => state.developmentCenterPeremissionsList,
    [developmentCentersGetterTypes.GET_DEVELOPMENT_CENTER_DICTIONARIES_ROLES]: state => state.developmentCenterRolesList,
    [developmentCentersGetterTypes.GET_DEVELOPMENT_CENTER_ACCESS_DENIED]: state => state.accessDenied,
    // [developmentCentersGetterTypes.GET_CURRENT_DEVELOPMENT_CENTER_ID]: state => state.developmentCentersList.find(center => center.is_selected)?.id,
    getDefaultDevelopmentCenterId: (state, getters) => getters[developmentCentersGetterTypes.GET_SELECTED_DEVELOPMENT_CENTER]?.id ??
            getters[developmentCentersGetterTypes.GET_DEVELOPMENT_CENTERS][0]?.id ??
            '0',
};
