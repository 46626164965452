import { searchGetters as getters } from '@/admin/store/modules/search/getters.js';
import { searchMutations as mutations } from '@/admin/store/modules/search/mutations.js';
import { searchActions as actions } from '@/admin/store/modules/search/actions.js';
import { searchState as state } from '@/admin/store/modules/search/state.js';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
