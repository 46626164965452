import axios from 'axios';
import { BASE_URI_DEVELOPMENT_CENTER } from '@/api/CONST.js';

const PREFIX = `${BASE_URI_DEVELOPMENT_CENTER}/pages`;

/**
 * get all pages with pagination
 * @param {object} params
 * @param {number} params.page current pagination page
 * @param {string} params.search search string
 * @returns Promise
 */
export const getAllPages = params => {
    if (!params.search) delete params.search;

    return axios.get(PREFIX, { params });
};

/**
 * get page item by id
 * @param {number|string} id page id
 * @param {import('axios').AxiosRequestConfig} [config]
 * @returns Promise
 */
export const getPageById = (id, config) => axios.get(`${PREFIX}/${id}`, config);

/**
 * create page
 * @param {object} data page data
 * @param {string} data.name page name
 * @returns Promise
 */
export const createPage = data => axios.post(PREFIX, data);

/**
 * update page
 * @param {number|string} id
 * @param {Object} data
 * @param {string} data.name page name
 * @param {import('axios').AxiosRequestConfig} [config]
 * @returns Promise
 */
export const updatePage = (id, data, config) => axios.put(`${PREFIX}/${id}`, { ...data }, config);

/**
 * delete page
 * @param {number|string} id page id
 * @returns Promise
 */
export const deletePage = id => axios.delete(`${PREFIX}/${id}`);

/**
 * restore page
 * @param {number|string} id page id
 * @returns Promise
 */
export const restorePage = id => axios.post(`${PREFIX}/${id}/restore`);

/**
 * check page name
 * @param {string} name page name
 * @returns Promise
 */
export const checkExists = name => axios.post(`${PREFIX}/check-name`, { name });

/**
 * set page cover
 * @param {object} coverData cover data
 * @param {string} coverData.name cover name
 * @param {File} coverData.file cover file
 * @param {string|number} coverData.pageId cover page id
 * @returns Promise
 */
export const setPageCover = coverData => {
    const { name, file, pageId } = coverData;
    const url = `${PREFIX}/${pageId}/page-covers`;
    const formData = new FormData();

    formData.append('file', file);

    if (name) formData.append('name', name);

    return axios.post(url, formData);
};

/**
 * remove page cover
 * @param {number|string} pageId page id
 * @param {number|string} coverId cover id
 * @returns Promise
 */
export const removePageCover = (pageId, coverId) => axios.delete(`${PREFIX}/${pageId}/page-covers/${coverId}`);

/**
 * restore page cover
 * @param {number|string} pageId page id
 * @param {number|string} coverId cover id
 * @returns Promise
 */
export const restorePageCover = (pageId, coverId) => axios.post(`${PREFIX}/${pageId}/page-covers/${coverId}/restore`);

/**
 * copy page with new name
 * @param {string|number} targetPageId id of target page
 * @param {string} name name of new page
 * @returns Promise
 */
export const copyPage = (targetPageId, name) => axios.post(`${PREFIX}/${targetPageId}/copy`, { name });
