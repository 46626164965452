<template>
    <div class="HomeworkFilterButtons">
        <SbsButton
            class="HomeworkFilterButtons__Btn"
            v-bind="searchButtonData"
            @click="$emit('toggleSearchInput')">
            <template #prepend>
                <InlineSvg
                    class="HomeworkFilterButtons__SearchButtonIcon"
                    :src="require('@/assets/svg/icons8-search-alt.svg')" />
            </template>
        </SbsButton>
        <SbsButton
            class="HomeworkFilterButtons__Btn"
            :withNotify="isHaveFilter"
            v-bind="filterButtonData"
            @click="$emit('toggleShowFilters')">
            <template #prepend>
                <InlineSvg
                    class="HomeworkFilterButtons__ConstructorBtnIcon"
                    :src="require('@/assets/svg/icons8-slider 5.svg')" />
            </template>
        </SbsButton>
        <HomeworkActionPopup
            v-model="isShowActionPopup"
            @goToBlock="$emit('goToBlock')">
            <HomeworkActionButton
                class="HomeworkFilterButtons__ActionButton"
                :isActive="isShowActionPopup"
                @click="toggleShowActionPopup" />
        </HomeworkActionPopup>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import HomeworkActionPopup from '@/admin/components/Homework/HomeworkActionPopup.vue';
import HomeworkActionButton from '@/admin/components/Homework/HomeworkActionButton.vue';

export default {
    name: 'HomeworkFilterButtons',
    components: {
        HomeworkActionButton,
        HomeworkActionPopup,
        InlineSvg,
    },
    emits: ['toggleSearchInput', 'goToBlock', 'toggleShowFilters'],
    data: () => ({
        searchButtonData: {
            class: 'HomeworkInfoPanel__Item HomeworkFilterButtons__Buttons',
            text: 'Поиск',
            backgroundTheme: 'white',
            static: true,
            bordered: false,
        },
        filterButtonData: {
            class: 'HomeworkInfoPanel__Item HomeworkFilterButtons__Buttons',
            text: 'Фильтры',
            backgroundTheme: 'white',
            static: true,
            bordered: false,
        },
        isShowActionPopup: false,
    }),
    methods: {
        toggleShowActionPopup() {
            this.isShowActionPopup = !this.isShowActionPopup;
        },
    },
    props: {
        isShowFilters: {
            type: Boolean,
            default: false,
        },
        isHaveFilter: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.HomeworkFilterButtons {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: auto;
}

.HomeworkFilterButtons__Buttons {
    border-radius: 10px;
    padding: 8px 15px;

    &:hover {
        background-color: #C4C6CD !important;
    }
}

.HomeworkFilterButtons__ConstructorBtnIcon,
.HomeworkFilterButtons__SearchButtonIcon {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    font-size: 20px;
}
.HomeworkFilterButtons__Btn {
    font-weight: 450;
}
</style>
