import axios from 'axios';
import { BASE_URI_DEVELOPMENT_CENTER } from '@/api/CONST.js';

const prefix = `${BASE_URI_DEVELOPMENT_CENTER}/activity_types`;

/**
 * get all activity types
 * @param {string|number} pageId id of current page
 * @returns Promise
 */
export const getActivityTypesList = pageId => axios.get(`${prefix}?pageId=${pageId ?? ''}`);

/**
 * add activity type to bookmarks
 * @param {string|number} typeId id of activity type
 * @returns Promise
 */
export const addActivitiTypeToBookmarks = typeId => axios.post(`${prefix}/${typeId}/bookmark`);

/**
 * remove activity type from bookmarks
 * @param {string|number} typeId id of activity type
 * @returns Promise
 */
export const removeActivityTypeFromBookmarks = typeId => axios.post(`${prefix}/${typeId}/unbookmark`);
