export const eventsMutationTypes = Object.freeze({
    SET_EVENTS_DATA: '[events] set events data',
    SET_LOADING: '[events] set loading',
    SET_CURRENT_PAGE: '[events] set current page',
    SET_LAST_PAGE: '[events] set last page',
});

export const eventsMutations = {
    [eventsMutationTypes.SET_EVENTS_DATA](state, payload) {
        state.events = payload;
    },
    [eventsMutationTypes.SET_LOADING](state, payload) {
        state.loading = payload;
    },
    [eventsMutationTypes.SET_CURRENT_PAGE](state, page) {
        state.currentPage = page;
    },
    [eventsMutationTypes.SET_LAST_PAGE](state, lastPage) {
        state.lastPage = lastPage;
    },
};


