export const AiMessagesPromptTypes = {
    IMPROVE_WRITING: 'improve_writing',
    CORRECT_WRITING: 'correct_writing',
    MAKE_SHORTER: 'make_shorter',
    MAKE_LONGER: 'make_longer',
    SUMMARIZE: 'summarize',
};

export const AiSessionTypes = {
    TEXT_BLOCK: 'text_block',
};
