<template>
    <div class="ErrorWrapper">
        <img
            class="ErrorWrapper__Logo"
            src="@/assets/svg/sber-logo2.svg"
            alt="error icon">
        <div class="ErrorWrapper__Content">
            <ErrorContent
                v-bind="errorContent"
                :showNavBtn="isShowNavBtn"
                @click:navBtn="$emit('click:navBtn')" />
        </div>
    </div>
</template>

<script>
import pageNotFoundStub from '@/assets/svg/page-not-found-stub.svg';
import serverErrorStub from '@/assets/svg/server-error-stub.svg';
import HttpStatusCodes from '@/utils/httpStatusCodes.js';
import ErrorContent from '@/components/UI/errors/ErrorContent.vue';


const ErrorContentByCode = {
    [HttpStatusCodes.NOT_FOUND]: {
        title: 'Данной страницы не существует или у вас нет доступа к ней',
        subtitle: 'Попробуйте изменить адрес страницы или перейдите на посадочную страницу со списком проектов',
        icon: pageNotFoundStub,
        navBtnLabel: 'Перейти к проектам',
    },
    [HttpStatusCodes.SERVICE_UNAVAILABLE]: {
        title: 'Сервис временно недоступен',
        subtitle: 'Возможно мы релизим новый функционал, или мы уже знаем о проблеме и занимаемся ей',
        icon: serverErrorStub,
    },
    [HttpStatusCodes.INTERNAL_SERVER_ERROR]: {
        title: 'Ошибка сервера',
        subtitle: 'Страница временно недоступна. Попробуйте повторить попытку или зайди попозже',
        icon: serverErrorStub,
        navBtnLabel: 'Перейти к проектам',
    },
};

const ErrorContentDefault = {
    title: 'Ошибка!',
    subtitle: 'Произошла непредвиденная ошибка. Обратитесь к администратору сайта.',
    icon: pageNotFoundStub,
};

export default {
    name: 'ErrorWrapper',
    components: {
        ErrorContent,
    },
    emits: ['click:navBtn'],
    computed: {
        errorContent() {
            return ErrorContentByCode[this.code] ?? ErrorContentDefault;
        },
        isShowNavBtn() {
            return this.$route.name !== 'AdminProjectsList';
        },
    },
    props: {
        code: {
            type: Number,
            default: null,
        },
    },
};
</script>

<style lang="scss">
.ErrorWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 33px 50px;
    background-color: $gray-ultra-light;
}

.ErrorWrapper__Logo {
    width: 160px;
    height: 32px;
}

.ErrorWrapper__Content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: center;
}
</style>
