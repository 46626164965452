import { useToast } from 'vue-toastification';
import MainSnackbar from '@/components/UI/MainSnackbar.vue';

const useSnackbar = () => {
    const snackbar = useToast();

    /**
     * @param {object} params
     * @param {('success'|'error'|'info'|'timer')} params.type
     * @param {string} [params.title]
     * @param {string} params.message
     * @param {object} [params.on]
     * @param {number} [params.timeout]
     *
     * @param {object} [params.snackbarOptions]
     * @param {object} [params.actionButton]
     * @param {boolean} [params.force]
     * @param {boolean} [params.fullWidth]
     * @param {string|number} [params.maxWidth]
     * @param {boolean} [params.showCloseButton]
     * @param {'medium'|'large'} [params.size]
     * @param {object} [params.spinnerData]
     * @param {boolean} [params.useProgramCall]
     * @param {string|number} [params.width]
     * @param {string} [params.snackbarClass]
     * @param {string} [params.contentClass]
     * @param {object} [params.link]
     * @param {string} [params.link.text]
     * @param {object|Route} [params.link.route]
     */
    const createSnackbar = params => {
        const { on, snackbarOptions, ...props } = params;

        snackbar({
            component: MainSnackbar,
            props,
            listeners: on,
        }, {
            ...snackbarOptions,
            timeout: props.timeout,
        });
    };

    return {
        snackbar,
        createSnackbar,
    };
};

export default useSnackbar;
