import { Utils } from '@sbs/uikit-constructor';

const { MP3, WAV, OGG, JPEG, JPG, PNG } = Utils.FileTypes.AllFileExtensions;

export const ACCEPT_AUDIO_TYPES = [
    'audio/mp3',
    'audio/mpeg',
    'audio/wav',
    'audio/ogg',
    'video/ogg', // for FireFox https://bugzilla.mozilla.org/show_bug.cgi?id=1240259
];
export const ACCEPT_AUDIO_EXT = `.${MP3},.${WAV},.${OGG}`;
export const ACCEPT_AUDIO_EXT2 = `${MP3}, ${WAV}, ${OGG}`;
export const ACCEPT_COVER_TYPES = ['image/jpg', 'image/jpeg', 'image/png'];
export const ACCEPT_COVER_EXT = `.${JPG},.${JPEG},.${PNG}`;
export const ACCEPT_COVER_EXT2 = `${JPG}, ${JPEG}, ${PNG}`;
export const MAX_AUDIO_FILE_BYTES = 1024 * 1024 * 1024; // 1 GB
export const MAX_COVER_FILE_BYTES = 10 * 1024 * 1024; // 10 MB
export const AUDIO_FILE_UPLOAD_KEY = 'file';
export const AUDIO_COVER_UPLOAD_KEY = 'cover';
export const MAX_LENGTH_AUDIO_TITLE = 180;
