import StatusCodes from '@/utils/httpStatusCodes.js';

export default {
    namespaced: true,
    state: {
        globalError: {
            active: false,
            type: '',
            types: [
                StatusCodes.NOT_FOUND,
                StatusCodes.INTERNAL_SERVER_ERROR,
            ],
            default: StatusCodes.INTERNAL_SERVER_ERROR,
        },
        winWidth: null,
        breakpoints: {
            MOBILE: 320,
            TABLET: 768,
            DESKTOP: 1280,
        },
        scrollbarWidth: null,
        scrollDirection: 0,
        scrollDirections: {
            UP: -1,
            DOWN: 1,
        },
        scrollPosition: '',
        scrollPositions: {
            TOP: 'top',
            MIDDLE: 'middle',
            BOTTOM: 'bottom',
        },
        isDragged: false,
        isGlobalLoading: false,
        previousPageRoute: null,
        temporaryRoute: null,
    },
    getters: {
        isGlobalError: state => state.globalError.active,
        globalErrorType: state => state.globalError.type,
        globalErrorTypes: state => state.globalError.types,
        globalErrorDefault: state => state.globalError.default,
        scrollDirection: state => state.scrollDirection,
        winWidth: state => state.winWidth,
        breakpoints: state => state.breakpoints,
        isMobile: state => state.winWidth < state.breakpoints.TABLET,
        isTablet: state => state.winWidth >= state.breakpoints.TABLET && state.winWidth < state.breakpoints.DESKTOP,
        isDesktop: state => state.winWidth >= state.breakpoints.DESKTOP,
        scrollbarWidth: state => state.scrollbarWidth,
        scrollDirections: state => state.scrollDirections,
        isScrollingUp: state => state.scrollDirection === state.scrollDirections.UP,
        isScrollingDown: state => state.scrollDirection === state.scrollDirections.DOWN,
        scrollPosition: state => state.scrollPosition,
        scrollPositions: state => state.scrollPositions,
        isScrollPositionTop: state => state.scrollPosition === state.scrollPositions.TOP,
        isScrollPositionMiddle: state => state.scrollPosition === state.scrollPositions.MIDDLE,
        isScrollPositionBottom: state => state.scrollPosition === state.scrollPositions.BOTTOM,
        isDragged: state => state.isDragged,
        isGlobalLoading: state => state.isGlobalLoading,
        previousPageRoute: state => state.previousPageRoute,
        getTemporaryRoute: state => state.temporaryRoute,
    },
    mutations: {
        SET_GLOBAL_ERROR(state, errorType) {
            state.globalError.active = true;

            if (state.globalError.types.includes(errorType)) {
                state.globalError.type = errorType;
            } else {
                state.globalError.type = state.globalError.default;
            }
        },
        REMOVE_GLOBAL_ERROR(state) {
            state.globalError.active = false;
            state.globalError.type = '';
        },
        SET_WIN_WIDTH(state, width) {
            state.winWidth = width;
        },
        SET_SCROLLBAR_WIDTH(state, width) {
            state.scrollbarWidth = width;
        },
        SET_SCROLL_DIRECTION(state, direction) {
            state.scrollDirection = direction;
        },
        SET_SCROLL_POSITION(state, position) {
            state.scrollPosition = position;
        },
        SET_DRAG_STATE(state, isDragged) {
            state.isDragged = isDragged;
        },
        SET_GLOBAL_LOADING_STATE(state, isGlobalLoading) {
            state.isGlobalLoading = isGlobalLoading;
        },
        SET_PREVIOUS_PAGE_ROUTE(state, previousPageRoute) {
            state.previousPageRoute = previousPageRoute;
        },
        SET_TEMPORARY_ROUTE(state, data) {
            state.temporaryRoute = data;
        },
    },
    actions: {
        setGlobalError({ commit }, errorType = StatusCodes.INTERNAL_SERVER_ERROR) {
            commit('SET_GLOBAL_ERROR', errorType);
        },
        setGlobalerrorFromResponseData({ commit }, error) {
            const { status } = error?.response ?? {};
            const shouldShowError = [StatusCodes.INTERNAL_SERVER_ERROR, StatusCodes.NOT_FOUND].includes(status);

            if (shouldShowError) commit('SET_GLOBAL_ERROR', status);
        },
        removeGlobalError({ commit }) {
            commit('REMOVE_GLOBAL_ERROR');
        },
        setWinWidth({ commit }, width) {
            commit('SET_WIN_WIDTH', width);
        },
        setScrollbarWidth({ commit }, width) {
            commit('SET_SCROLLBAR_WIDTH', width);
        },
        setScrollDirection({ commit }, direction) {
            commit('SET_SCROLL_DIRECTION', direction);
        },
        setScrollPosition({ commit }, position) {
            commit('SET_SCROLL_POSITION', position);
        },
        setDragState({ commit }, isDragged) {
            commit('SET_DRAG_STATE', isDragged);

            if (isDragged) {
                document.body.classList.add('is-dragged');
            } else {
                document.body.classList.remove('is-dragged');
            }
        },
        dragStart({ dispatch }) {
            dispatch('setDragState', true);
        },
        dragEnd({ dispatch }) {
            dispatch('setDragState', false);
        },
        setGlobalLoadingState({ commit, getters }, isGlobalLoading) {
            if (getters.isGlobalLoading === isGlobalLoading) return;

            commit('SET_GLOBAL_LOADING_STATE', isGlobalLoading);
        },
        setPreviousPageRoute({ commit }, previousPageRoute) {
            commit('SET_PREVIOUS_PAGE_ROUTE', previousPageRoute);
        },
        setTemporaryRoute({ commit }, payload) {
            commit('SET_TEMPORARY_ROUTE', payload);
        },
    },
};
