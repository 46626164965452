export const StatusesMap = {
    ACCEPTED: 'accepted',
    PASSED: 'passed',
    NEED_CHECKING: 'need_checking',
    REJECTED: 'rejected',
    FAILED: 'failure',
    RESET: 'reset',
};

export const TaskTypes = {
    ESSAY: 'essay_version',
    FILES: 'files_version',
    RESPONSE: 'response',
};
