import { Utils } from '@sbs/uikit-constructor';
import { bytes, bytesToMegabytes } from '@/utils/helpers.js';

export const VIDEO_FILE_UPLOAD_KEY = 'video';
export const VIDEO_COVER_FILE_UPLOAD_KEY = 'cover';
export const VIDEO_SUBTITLES_FILE_UPLOAD_KEY = 'subtitles';
export const MAX_VIDEO_FILE_BYTES = bytes('2GB');
export const MAX_VIDEO_FILE_MEGABYTES = bytesToMegabytes(MAX_VIDEO_FILE_BYTES);
// export const ACCEPT_VIDEO_TYPES = [
//     'video/mp4',
//     'video/mpeg',
//     'video/x-msvideo',
//     'video/quicktime',
//     'video/webm',
//
//     // не работает в MaxOS
//     // https://stackoverflow.com/questions/56454681/why-is-no-file-type-returned-when-adding-an-mkv-file-to-a-file-input
//     // 'video/x-matroska',
//     // 'video/x-ms-wmx',
//     // 'video/x-ms-asf',
// ];
export const ACCEPT_VIDEO_EXTENSION = [
    `.${Utils.FileTypes.AllFileExtensions.MP4}`,
    `.${Utils.FileTypes.AllFileExtensions.MPEG}`,
    `.${Utils.FileTypes.AllFileExtensions.AVI}`,
    `.${Utils.FileTypes.AllFileExtensions.MOV}`,
    `.${Utils.FileTypes.AllFileExtensions.WEBM}`,
    `.${Utils.FileTypes.AllFileExtensions.MKV}`,
    `.${Utils.FileTypes.AllFileExtensions.WMV}`,
];

export const MAX_VIDEO_COVER_SIZE_BYTES = bytes('10MB');
export const MAX_VIDEO_COVER_SIZE_MEGABYTES = bytesToMegabytes(MAX_VIDEO_COVER_SIZE_BYTES);
export const ACCEPT_VIDEO_COVER_EXTENSION = [
    `.${Utils.FileTypes.AllFileExtensions.JPG}`,
    `.${Utils.FileTypes.AllFileExtensions.JPEG}`,
    `.${Utils.FileTypes.AllFileExtensions.PNG}`,
    `.${Utils.FileTypes.AllFileExtensions.GIF}`,
];
export const ACCEPT_VIDEO_COVER_TYPES = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/gif',
];

export const MAX_VIDEO_SUBTITLES_SIZE_BYTES = bytes('10MB');
export const MAX_VIDEO_SUBTITLES_SIZE_MEGABYTES = bytesToMegabytes(MAX_VIDEO_SUBTITLES_SIZE_BYTES);
export const ACCEPT_VIDEO_SUBTITLES_EXTENSION = [
    `.${Utils.FileTypes.AllFileExtensions.SRT}`,
    `.${Utils.FileTypes.AllFileExtensions.VTT}`,
];
