import { solutionsStructureGetters as getters } from '@/admin/store/modules/solutionsStructure/getters.js';
import { solutionsStructureMutations as mutations } from '@/admin/store/modules/solutionsStructure/mutations.js';
import { solutionsStructureActions as actions } from '@/admin/store/modules/solutionsStructure/actions.js';
import { solutionsStructureState as state } from '@/admin/store/modules/solutionsStructure/state.js';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
