
import { addActivityLink } from '@/api/activityLinkApi.js';
import { getFileEnvUrl } from '@/utils/utils.js';

export default {
    namespaced: true,
    state: {
        linkInfo: null,
    },
    getters: {
        linkInfo: state => state.linkInfo,
    },
    mutations: {
        SET_LINK_INFO(state, data) {
            state.linkInfo = data;
        },
    },
    actions: {
        async fetchLink({ dispatch }, { activityId, url }) {
            const { data } = await addActivityLink(activityId, { url });

            if (!data.data) {
                const err = '[fetch link] this url dose not exists';

                console.error(err);
                throw new Error(err);
            }

            const result = {
                url: data.data.url,
                faviconUrl: getFileEnvUrl(data.data.file),
                title: data.data.title,
                originalId: data.data.id,
            };

            dispatch('setLinkInfo', result);
        },
        setLinkInfo({ commit }, data) {
            commit('SET_LINK_INFO', data);
        },
    },
};
