export const developmentCenterMutationTypes = Object.freeze({
    SET_DEVELOPMENT_CENTERS: '[developmentCenters] set development centers list',
    SET_SELECTED_DEVELOPMENT_CENTER: '[developmentCenters] set selected development center',
    SET_CHANGE_SELECTED_DEVELOPMENT_CENTER: '[developmentCenters] set change selected development center',
    SET_LIST_LOADING: '[developmentCenters] set development centers list loading',
    SET_LIST_ERROR: '[developmentCenters] set development centers list error',
    SET_CURRENT_PAGE: '[developmentCenters] increment current page',
    SET_LAST_PAGE: '[developmentCenters] set last page',
    SET_COUNTS: '[developmentCenters] set counts',
    SET_DEVELOPMENT_CENTER_MEMBERS: '[developmentCenters] set development center member list',
    SET_DEVELOPMENT_CENTER_MEMBERS_ERROR: '[developmentCenters] set development center member list error',
    SET_DEVELOPMENT_CENTER_MEMBERS_LOADING: '[developmentCenters] set development center member list loading',
    SET_CURRENT_MEMBERS_PAGE: '[developmentCenters] increment current members page',
    SET_LAST_MEMBERS_PAGE: '[developmentCenters] set last members page',
    SET_MEMBERS_COUNTS: '[developmentCenters] set members counts',
    SET_DEVELOPMENT_CENTER_DICTIONARIES: '[developmentCenters] set development center dictionaries',
    RESET_MEMBERS_STATE: '[developmentCentersMember] reset development center members state',
    SET_CURRENT_DEVELOPMENT_CENTER: '[developmentCentersMember] set current development center',
    SET_ACCESS_DENIED: '[developmentCentersMember] set access denied',
});

export const developmentCenterMutations = {
    [developmentCenterMutationTypes.SET_DEVELOPMENT_CENTERS](state, developmentCentersList) {
        state.developmentCentersList = developmentCentersList;
    },
    [developmentCenterMutationTypes.SET_SELECTED_DEVELOPMENT_CENTER](state, selectedDevelopmentCenter) {
        state.selectedDevelopmentCenter = selectedDevelopmentCenter;
    },
    [developmentCenterMutationTypes.SET_CHANGE_SELECTED_DEVELOPMENT_CENTER](state, developmentCenter) {
        state.selectedDevelopmentCenter = developmentCenter;
    },
    [developmentCenterMutationTypes.SET_LIST_ERROR](state, listError) {
        state.listError = listError;
    },
    [developmentCenterMutationTypes.SET_LIST_LOADING](state, listLoading) {
        state.listLoading = listLoading;
    },
    [developmentCenterMutationTypes.SET_CURRENT_PAGE](state, page) {
        state.currentPage = page;
    },
    [developmentCenterMutationTypes.SET_LAST_PAGE](state, lastPage) {
        state.lastPage = lastPage;
    },
    [developmentCenterMutationTypes.SET_COUNTS](state, counts) {
        state.counts = counts;
    },
    [developmentCenterMutationTypes.SET_DEVELOPMENT_CENTER_MEMBERS](state, developmentCenterMembers) {
        state.developmentCenterMembers = developmentCenterMembers;
    },
    [developmentCenterMutationTypes.SET_DEVELOPMENT_CENTER_MEMBERS_ERROR](state, developmentCenterMembersError) {
        state.developmentCenterMembersError = developmentCenterMembersError;
    },
    [developmentCenterMutationTypes.SET_DEVELOPMENT_CENTER_MEMBERS_LOADING](state, developmentCenterMembersLoading) {
        state.developmentCenterMembersLoading = developmentCenterMembersLoading;
    },
    [developmentCenterMutationTypes.SET_CURRENT_MEMBERS_PAGE](state, page) {
        state.currentMembersPage = page;
    },
    [developmentCenterMutationTypes.SET_LAST_MEMBERS_PAGE](state, lastPage) {
        state.lastMembersPage = lastPage;
    },
    [developmentCenterMutationTypes.SET_MEMBERS_COUNTS](state, counts) {
        state.membersCounts = counts;
    },
    [developmentCenterMutationTypes.SET_DEVELOPMENT_CENTER_DICTIONARIES](state, dictionaries) {
        state.developmentCenterAccessList = dictionaries.developmentCenterAccessList;
        state.developmentCenterPeremissionsList = dictionaries.developmentCenterPeremissionsList;
        state.developmentCenterRolesList = dictionaries.developmentCenterRolesList;
    },
    [developmentCenterMutationTypes.RESET_MEMBERS_STATE](state) {
        const defaultState = {
            developmentCenterMembers: [],
            developmentCenterMembersError: '',
            developmentCenterMembersLoading: true,
            currentMembersPage: 0,
            lastMembersPage: 1,
            membersCounts: null,
        };

        for (const key in defaultState) {
            if (Object.prototype.hasOwnProperty.call(defaultState, key)) {
                state[key] = defaultState[key];
            }
        }
    },
    [developmentCenterMutationTypes.SET_CURRENT_DEVELOPMENT_CENTER](state, payload) {
        state.currentDevelopmentCenter = payload;
    },
    [developmentCenterMutationTypes.SET_ACCESS_DENIED](state, payload) {
        state.accessDenied = payload;
    },
};
