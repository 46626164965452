/* eslint-disable no-bitwise */

import { EVENTS_PERIOD } from '@/constants/events.js';

const emptyActivityHandlers = {
    ContentText(activity) {
        return !activity.data.content.text.length;
    },
    ContentAccordion(activity) {
        return activity.data.items
            .filter(item => !item.attributes?.deleted)
            .some(({ content }) => !content.title.length || !content.text.length);
    },
    ContentColumnsInFrames(activity) {
        const displayedColumnItems = activity.data.items?.filter(item => !item.attributes.deleted) ?? [];
        const titleVisibilityModel = activity.data.content;

        return (
            !displayedColumnItems.length ||
            displayedColumnItems.some(item => (
                !item.content.iconName ||
                !item.content.description ||
                (titleVisibilityModel && !item.content.title)
            ))
        );
    },
    ContentTabs1(activity) {
        const displayedTabItems = activity.data.items.filter(item => !item.attributes.deleted);

        return (
            !displayedTabItems.length ||
            displayedTabItems.some(tab => !tab.content.tabTitle || !tab.content.text)
        );
    },
    ContentImageSlider(activity) {
        return !activity.data.items?.filter(item => !item.attributes?.deleted).length;
    },
    ContentTextImage(activity) {
        return activity.data.items
            .filter(item => !item.attributes.deleted)
            .some(item => (item.content.text.length === 0 || !item.content.image));
    },
    ActivityVideo(activity) {
        return !(
            activity.data.content.mediaLibraryLink ||
            activity.data.content.videoFile?.file
        );
    },
    ActivityFile(activity) {
        return !activity.data.items.length ||
            !activity.data.items.filter(item => !item.attributes.deleted).length;
    },
    ActivityLink(activity) {
        return activity.data.items
            .filter(item => !item.attributes.deleted)
            .every(link => !link.content.validate);
    },
};

export const isActivityEmpty = activity => emptyActivityHandlers[activity.type]?.(activity);

export const solutionResponseStructureHelper = modules => ({
    modules: modules.map(module => ({
        id: module.id,
        sections: module.sections.filter(item => !item.tempId).map(section => ({
            id: section.id,
            pages: section.pages.map(page => ({ id: page.id })),
        })),
        pages: module.pages.map(page => ({ id: page.id })),
    })),
});

export const checkSolutionStructureOrderChanged = (oldOrder, newOrder) => {
    if (newOrder.some(module => module.tempId) || !newOrder.length) return false;

    let isChanged = false;

    oldOrder.forEach((item, index) => {
        if (isChanged) return;

        isChanged = item.id !== newOrder[index]?.id;
        item.sections.forEach((section, sectionIndex) => {
            if (isChanged) return;

            isChanged = section.id !== newOrder[index].sections[sectionIndex]?.id;

            section.pages.forEach((page, pageIndex) => {
                if (isChanged) return;

                isChanged = page.id !== newOrder[index].sections[sectionIndex]?.pages[pageIndex]?.id;
            });
        });
        item.pages.forEach((page, pageIndex) => {
            if (isChanged) return;

            isChanged = page.id !== newOrder[index].pages[pageIndex]?.id;
        });
    });

    return isChanged;
};

export const isNeedUpdateVersionCompletionType = structure => {
    const isViewedVersionType = structure.version.completion_type === 'viewed';
    const isNotSolutionPage = structure.modules?.length > 1 ||
        structure.modules[0]?.pages?.length > 1;

    return isViewedVersionType && isNotSolutionPage;
};

export const getUpdateAction = actions => (actions ? actions.update : true);

/**
 * @param {HTMLElement} [element]
 * @returns {boolean}
 */
export const getIsTruncatedText = element => {
    if (!element) return false;

    const {
        offsetHeight,
        offsetWidth,
        scrollHeight,
        scrollWidth,
    } = element;

    const offsetHeightFixed = offsetHeight + 1; // на windows неправильно высчитывает offsetHeight

    return scrollHeight > offsetHeightFixed || scrollWidth > offsetWidth;
};

export const goToItemInStructure = (id, type) => {
    const itemEl = document.getElementById(`${type}-${id}`);

    setTimeout(() => itemEl?.scrollIntoView({ behavior: 'smooth' }), 0);
};

export const getDateListFromEvent = event => {
    const { items } = event.content.schedule;

    if (event.settings.period === EVENTS_PERIOD.SINGLE) {
        return [
            {
                day: event.content.date,
                times: [event.content.time],
            },
        ];
    }

    return items.map(day => ({
        day: day.content.date,
        times: day.content.schedule_items.items.map(el => el.content.time),
    }));
};


export const bytes = (() => {
    const parseRegExp = /^((-|\+)?(\d+(?:\.\d+)?)) *(kb|mb|gb|tb|pb)$/i;

    const sizeMap = {
        b: 1,
        kb: 1 << 10,
        mb: 1 << 20,
        gb: 1 << 30,
        tb: 1024 ** 4,
        pb: 1024 ** 5,
    };

    /**
     * https://github.com/visionmedia/bytes.js/blob/master/index.js#L141
     *
     * @param {string|number} val
     * @returns {number|null}
     */
    return val => {
        if (typeof val === 'number' && !isNaN(val)) {
            return val;
        }

        if (typeof val !== 'string') {
            return null;
        }

        // Test if the string passed is valid
        const results = parseRegExp.exec(val);
        let floatValue = 0;
        let unit = 'b';

        if (results) {
            // Retrieve the value and the unit
            floatValue = parseFloat(results[1]);
            unit = results[4].toLowerCase();
        } else {
            // Nothing could be extracted from the given string
            floatValue = parseInt(val, 10);
            unit = 'b';
        }

        if (isNaN(floatValue)) {
            return null;
        }

        return Math.floor(sizeMap[unit] * floatValue);
    };
})();

const ONE_MEGABYTES = 1024 * 1024;

/**
 * @param {number} val
 * @returns {number}
 */
export const bytesToMegabytes = val => val / ONE_MEGABYTES;

export const getErrorMsg = (err, defaultMsg = 'Ошибка. Попробуйте еще раз') => {
    if (typeof err === 'string') return err;

    return err?.message || err?.toString() || defaultMsg;
};
