import axios from 'axios';
import { BASE_URI_DEVELOPMENT_CENTER } from './CONST.js';

const url = `${BASE_URI_DEVELOPMENT_CENTER}/editor/user/icons`;

export const getUsedIcons = () => axios.get(url);

/**
 * add icon to used
 * @param {string} icon
 * @returns Promise
 */
export const postUsedIcon = icon => axios.post(url, { icon });
