export const solutionsStructureMutationTypes = Object.freeze({
    SET_STRUCTURE: '[structure] set structure',
    SET_LOADING: '[structure] set loading',
    SET_ERROR: '[structure] set error',
    SET_REMOVED_MODULE_INDEX: '[structure] set removed module index',
    SET_REMOVED_SECTION_INDEX: '[structure] set removed section index',
    SET_REMOVED_PAGE_INDEX: '[structure] set removed page index',
    RESET_STATE: '[structure] reset state',
    SET_ACCESS_DATA_FOR_RESTORE: '[structure] set access data for restore',
    SET_LAST_MODULE_CREATED_ID: '[structure] set last module created id',
    REMOVE_LAST_MODULE_CREATED_ID: '[structure] remove last module created id',
});

export const solutionsStructureMutations = {
    [solutionsStructureMutationTypes.SET_STRUCTURE](state, structure) {
        state.structure = structure;
    },
    [solutionsStructureMutationTypes.SET_LOADING](state, loading) {
        state.loading = loading;
    },
    [solutionsStructureMutationTypes.SET_ERROR](state, error) {
        state.error = error;
    },
    [solutionsStructureMutationTypes.SET_REMOVED_MODULE_INDEX](state, index) {
        state.removedModuleIndex = index;
    },
    [solutionsStructureMutationTypes.SET_REMOVED_SECTION_INDEX](state, index) {
        state.removedSectionIndex = index;
    },
    [solutionsStructureMutationTypes.SET_REMOVED_PAGE_INDEX](state, index) {
        state.removedPageIndex = index;
    },
    [solutionsStructureMutationTypes.RESET_STATE](state) {
        const defaultState = {
            structure: {},
            error: '',
            loading: false,
            removedModuleIndex: 0,
            removedSectionIndex: 0,
            removedPageIndex: 0,
        };

        for (const key in defaultState) {
            if (Object.hasOwnProperty.call(defaultState, key)) {
                state[key] = defaultState[key];
            }
        }
    },
    [solutionsStructureMutationTypes.SET_ACCESS_DATA_FOR_RESTORE](state, data) {
        state.accessDataForRestore = data;
    },
    [solutionsStructureMutationTypes.SET_LAST_MODULE_CREATED_ID](state, moduleId) {
        state.lastModuleCreatedId = moduleId;
    },
    [solutionsStructureMutationTypes.REMOVE_LAST_MODULE_CREATED_ID](state) {
        state.lastModuleCreatedId = null;
    },
};

