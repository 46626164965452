import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import '@/styles/global/_toastification.scss';

const ToastDefaultOptions = {
    shareAppContext: true,
    timeout: 5000,
    position: 'bottom-center',
    closeButton: false,
    icon: false,
    hideProgressBar: true,
    pauseOnHover: false,
    toastClassName: 'ToastSnackbar-toast',
    containerClassName: 'ToastSnackbar-container',
    transition: 'Vue-Toastification__fade',
};

export {
    Toast,
    ToastDefaultOptions,
};
