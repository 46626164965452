export const homeworkGettersTypes = Object.freeze({
    GET_HOMEWORK_BLOCKS: '[homework] get homework blocks',
    GET_HOMEWORK_BLOCKS_LOADING: '[homework] get homework blocks loading',
    GET_HOMEWORK_BLOCKS_ERROR: '[homework] get homework blocks error',
    GET_HOMEWORK_BLOCKS_CURRENT_PAGE: '[homework] get homework blocks current page',
    GET_HOMEWORK_BLOCKS_IS_LAST_PAGE: '[homework] get homework blocks is last page',
    GET_HOMEWORK_BLOCKS_LAST_PAGE: '[homework] get homework blocks last page',

    GET_HOMEWORK_BLOCK: '[homework] get homework block',
    GET_HOMEWORK_BLOCK_LOADING: '[homework] get homework block loading',
    GET_HOMEWORK_BLOCK_ERROR: '[homework] get homework block error',
    GET_HOMEWORK_BLOCK_DATA: '[homework] get homework block data',
    GET_HOMEWORK_BLOCK_DATA_LOADING: '[homework] get homework block data loading',
    GET_HOMEWORK_BLOCK_DATA_ERROR: '[homework] get homework block data error',
    GET_HOMEWORK_BLOCK_DATA_CURRENT_PAGE: '[homework] get homework block data current page',
    GET_HOMEWORK_BLOCK_DATA_LAST_PAGE: '[homework] get homework block data last page',
    GET_HOMEWORK_BLOCK_DATA_TOTAL_COUNT: '[homework] get homework block data total count',
});

export const homeworkGetters = {
    [homeworkGettersTypes.GET_HOMEWORK_BLOCKS]: state => state.homeworkBlocks,
    [homeworkGettersTypes.GET_HOMEWORK_BLOCKS_LOADING]: state => state.homeworkBlocksLoading,
    [homeworkGettersTypes.GET_HOMEWORK_BLOCKS_ERROR]: state => state.homeworkBlocksError,
    [homeworkGettersTypes.GET_HOMEWORK_BLOCKS_CURRENT_PAGE]: state => state.homeworkBlocksCurrentPage,
    [homeworkGettersTypes.GET_HOMEWORK_BLOCKS_IS_LAST_PAGE]: state => state.homeworkBlocksCurrentPage >= state.homeworkBlocksLastPage,
    [homeworkGettersTypes.GET_HOMEWORK_BLOCKS_LAST_PAGE]: state => state.homeworkBlocksLastPage,

    [homeworkGettersTypes.GET_HOMEWORK_BLOCK]: state => state.homeworkBlock,
    [homeworkGettersTypes.GET_HOMEWORK_BLOCK_LOADING]: state => state.homeworkBlockLoading,
    [homeworkGettersTypes.GET_HOMEWORK_BLOCK_ERROR]: state => state.homeworkBlockError,
    [homeworkGettersTypes.GET_HOMEWORK_BLOCK_DATA]: state => state.homeworkBlockData,
    [homeworkGettersTypes.GET_HOMEWORK_BLOCK_DATA_LOADING]: state => state.homeworkBlockDataLoading,
    [homeworkGettersTypes.GET_HOMEWORK_BLOCK_DATA_ERROR]: state => state.homeworkBlockDataError,
    [homeworkGettersTypes.GET_HOMEWORK_BLOCK_DATA_CURRENT_PAGE]: state => state.homeworkBlockDataCurrentPage,
    [homeworkGettersTypes.GET_HOMEWORK_BLOCK_DATA_LAST_PAGE]: state => state.homeworkBlockDataLastPage,
    [homeworkGettersTypes.GET_HOMEWORK_BLOCK_DATA_TOTAL_COUNT]: state => state.homeworkBlockDataTotalCount,
};
