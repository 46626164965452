export const searchState = {
    searchData: {
        members: [],
        reports: [],
        projects: [],
    },
    isNotFoundData: false,
    loading: false,
    searchHistory: [],
    error: '',
};
