import axios from 'axios';
import { BASE_URI_DEVELOPMENT_CENTER } from '@/api/CONST.js';

const DICT_PREFIX = `${BASE_URI_DEVELOPMENT_CENTER}/dictionaries`;
const CENTERS_PREFIX = `${BASE_URI_DEVELOPMENT_CENTER}/development-centers`;

/**
 * get development centers list
 * @param {Object} params page
 * @returns Promise
 */
export const getDevelopmentCentersList = params => axios.get(CENTERS_PREFIX, { params });

/**
 * get selected development center
 * @returns Promise
 */
export const getSelectedDevelopmentCenter = () => axios.get(`${CENTERS_PREFIX}/selected`);

/**
 * get development centers list users available to add
 * @param {number|string} developmentCenterId development center id
 * @returns Promise
 */

export const getDevelopmentCentersAvailableUsers = search => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/users`, { params: { search } });

/**
 * get development center members list
* @param {number|string} developmentCenterId development center id
 * @returns Promise
 */
export const getDevelopmentCenterMembers = (developmentCenterId, params, config = null) => axios.get(`${CENTERS_PREFIX}/${developmentCenterId}/members`, {
    params,
    ...config,
});

/**
 * get development center member
 * @param {number|string} developmentCenterId development center id
 * @param {number|string} memberId member id
 * @returns Promise
 */
export const getDevelopmentCenterMember = (developmentCenterId, memberId, params = {}) => axios.get(`${CENTERS_PREFIX}/${developmentCenterId}/members/${memberId}`, { params });

/**
 * add development center member
 * @param {number|string} developmentCenterId development center id
 * @param {number|string} memberId development center id
 * @returns Promise
 */
export const addDevelopmentCenterMember = (developmentCenterId, data) => axios.post(`${CENTERS_PREFIX}/${developmentCenterId}/members`, data);

/**
 * update development center member
 * @param {number|string} developmentCenterId development center id
 * @param {number|string} memberId development center id
 * @returns Promise
 */
export const updateDevelopmentCenterMember = (developmentCenterId, memberId, data) => axios.patch(`${CENTERS_PREFIX}/${developmentCenterId}/members/${memberId}`, data);

/**
 * update development center member
 * @param {number|string} developmentCenterId development center id
 * @param {number|string} memberId development center id
 * @returns Promise
 */
export const removeDevelopmentCenterMember = (developmentCenterId, memberId) => axios.delete(`${CENTERS_PREFIX}/${developmentCenterId}/members/${memberId}`);

/**
 * get development center dictionaries access
 * @returns Promise
 */
export const getDevelopmentCenterDictionariesAccess = () => axios.get(`${DICT_PREFIX}/access`);

/**
 * get development center dictionaries permission
 * @param {number|string} roleId user role id
 * @returns Promise
 */
export const getDevelopmentCenterDictionariesPermissions = roleId => axios.get(`${DICT_PREFIX}/permission`, { params: { role_id: roleId } });

/**
 * get development center dictionaries role
 * @returns Promise
 */
export const getDevelopmentCenterDictionariesRoles = () => axios.get(`${DICT_PREFIX}/role`);

/**
 * set current development center id for user
 * @param {string|number} id current development center id
 * @returns Promise
 */
export const setCurrentDevelopmentCenterId = id => axios.post(`${CENTERS_PREFIX}/${id}/select`);

/**
 * Получить ЦР по id.
 * @param {string|number} id development center id
 * @returns Promise
 */
export const getDevelopmentCenterById = id => axios.get(`${CENTERS_PREFIX}/${id}`);

