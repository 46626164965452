/* eslint-disable no-template-curly-in-string, camelcase */

import * as yup from 'yup';
import { convertTimeToMinutesAndSeconds, getHtmlTextLength, getSecondsFromFormattedTimeString } from '@/utils/utils.js';

yup.addMethod(yup.string, 'maxHtml', function maxHtml(max, message = 'Поле не должно превышать ${max} символов', withImage = false) {
    // eslint-disable-next-line no-invalid-this
    return this.test({
        name: 'maxHtml',
        message,
        params: {
            max,
            withImage,
        },
        test: val => getHtmlTextLength(val, { withImage }) <= max,
    });
});

yup.addMethod(yup.string, 'minHtml', function maxHtml(min, message = 'Поле должно быть более ${min} символов', withImage = false) {
    // eslint-disable-next-line no-invalid-this
    return this.test({
        name: 'minHtml',
        message,
        params: {
            min,
            withImage,
        },
        test: val => getHtmlTextLength(val, { withImage }) >= min,
    });
});

yup.addMethod(yup.string, 'maxDuration', function maxDuration(duration, message = 'Время не должно превышать ${duration}') {
    // eslint-disable-next-line no-invalid-this
    return this.test({
        name: 'maxDuration',
        message,
        params: {
            duration: convertTimeToMinutesAndSeconds(duration),
        },
        test: val => getSecondsFromFormattedTimeString(val) <= duration,
    });
});
