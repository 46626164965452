export const homeworkMutationTypes = {
    SET_HOMEWORK_BLOCKS: '[homework] set homework blocks',
    SET_HOMEWORK_BLOCKS_LOADING: '[homework] set homework blocks loading',
    SET_HOMEWORK_BLOCKS_ERROR: '[homework] set homework blocks error',
    SET_HOMEWORK_BLOCKS_CURRENT_PAGE: '[homework] increment homework blocks current page',
    SET_HOMEWORK_BLOCKS_LAST_PAGE: '[homework] set homework blocks last page',

    SET_HOMEWORK_BLOCK: '[homework] set homework block',
    SET_HOMEWORK_BLOCK_LOADING: '[homework] set homework block loading',
    SET_HOMEWORK_BLOCK_ERROR: '[homework] set homework block error',
    SET_HOMEWORK_BLOCK_DATA: '[homework] set homework block data',
    SET_HOMEWORK_BLOCK_DATA_LOADING: '[homework] set homework block data loadings',
    SET_HOMEWORK_BLOCK_DATA_ERROR: '[homework] set homework block data error',
    SET_HOMEWORK_BLOCK_DATA_CURRENT_PAGE: '[homework] set homework block data current page',
    SET_HOMEWORK_BLOCK_DATA_LAST_PAGE: '[homework] set homework block data last page',
    SET_HOMEWORK_BLOCK_DATA_TOTAL_COUNT: '[homework] set homework block data total count',
    RESET_HOMEWORK_BLOCK_DATA_STATE: '[homework] reset homework block data state',
};

export const homeworkMutations = {
    [homeworkMutationTypes.SET_HOMEWORK_BLOCKS](state, blocks) {
        state.homeworkBlocks = blocks;
    },
    [homeworkMutationTypes.SET_HOMEWORK_BLOCKS_LOADING](state, loading) {
        state.homeworkBlocksLoading = loading;
    },
    [homeworkMutationTypes.SET_HOMEWORK_BLOCKS_ERROR](state, error) {
        state.homeworkBlocksError = error;
    },
    [homeworkMutationTypes.SET_HOMEWORK_BLOCKS_CURRENT_PAGE](state, page) {
        state.homeworkBlocksCurrentPage = page;
    },
    [homeworkMutationTypes.SET_HOMEWORK_BLOCKS_LAST_PAGE](state, lastPage) {
        state.homeworkBlocksLastPage = lastPage;
    },

    [homeworkMutationTypes.SET_HOMEWORK_BLOCK](state, homeworkBlock) {
        state.homeworkBlock = homeworkBlock;
    },
    [homeworkMutationTypes.SET_HOMEWORK_BLOCK_LOADING](state, loading) {
        state.homeworkBlockLoading = loading;
    },
    [homeworkMutationTypes.SET_HOMEWORK_BLOCK_ERROR](state, error) {
        state.homeworkBlockError = error;
    },
    [homeworkMutationTypes.SET_HOMEWORK_BLOCK_DATA](state, students) {
        state.homeworkBlockData = students;
    },
    [homeworkMutationTypes.SET_HOMEWORK_BLOCK_DATA_LOADING](state, loading) {
        state.homeworkBlockDataLoading = loading;
    },
    [homeworkMutationTypes.SET_HOMEWORK_BLOCK_DATA_ERROR](state, error) {
        state.homeworkBlockDataError = error;
    },
    [homeworkMutationTypes.SET_HOMEWORK_BLOCK_DATA_CURRENT_PAGE](state, data) {
        state.homeworkBlockDataCurrentPage = data;
    },
    [homeworkMutationTypes.SET_HOMEWORK_BLOCK_DATA_LAST_PAGE](state, data) {
        state.homeworkBlockDataLastPage = data;
    },
    [homeworkMutationTypes.SET_HOMEWORK_BLOCK_DATA_TOTAL_COUNT](state, data) {
        state.homeworkBlockDataTotalCount = data;
    },
    [homeworkMutationTypes.RESET_HOMEWORK_BLOCK_DATA_STATE](state) {
        const defaultState = {
            homeworkBlockData: [],
            homeworkBlockDataCurrentPage: 0,
            homeworkBlockDataLastPage: 1,
            homeworkBlockDataTotalCount: 0,
            homeworkBlockDataLoading: false,
            homeworkBlockDataError: '',
        };

        for (const key in defaultState) {
            if (Object.prototype.hasOwnProperty.call(defaultState, key)) {
                state[key] = defaultState[key];
            }
        }
    },
    updateHomeworkListItem(state, homeworkListItem) {
        state.homeworkBlockData = state.homeworkBlockData.map(item => (item.id === homeworkListItem.id ? homeworkListItem : item));
    },
};
