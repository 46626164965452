import axios from 'axios';
import { BASE_URI_DEVELOPMENT_CENTER } from '@/api/CONST.js';

// const PREFIX = `${BASE_URI}/modules`;

const PREFIX = `${BASE_URI_DEVELOPMENT_CENTER}/versions/`;

/**
 * add solutins module
 * @param {Object} moduleData data for added module
 * @param {string} moduleData.title module title
 * @param {string} moduleData.status module status
 * @param {number} moduleData.version_id module version id
 * @param {number} moduleData.order module order
 * @returns Promise
 */
export const addSolutionsModule = moduleData => axios.post(`${PREFIX}${moduleData.version_id}/modules`, { ...moduleData });


/**
 * update solutins module
 * @param {number|string} moduleId module id
 * @param {Object} moduleData data for added solution
 * @param {string} moduleData.title module title
 * @param {string} moduleData.status module status
 * @param {number} moduleData.version_id module version id
 * @param {number} moduleData.order module order
 * @param {string} moduleData.access_type module access condition type 'always' | 'after'
 * @param {Object} moduleData.access_data module access data
 * @param {boolean} moduleData.with_sections module consists of sections: true - from sections | false - from pages
 * @returns Promise
 */
export const updateSolutionsModule = (moduleId, moduleData) => {
    const url = `${PREFIX}${moduleData.version_id}/modules/${moduleId}`;

    return axios.put(url, { ...moduleData });
};


/**
 * remove module by id
 * @param {number|string} versionId
 * @param {number|string} moduleId module id
 * @returns Promise
 */
export const removeSolutionsModule = (versionId, moduleId) => {
    const url = `${PREFIX}${versionId}/modules/${moduleId}`;

    return axios.delete(url);
};


/**
 * restore module by module id
 * @param {number|string} versionId
 * @param {number|string} moduleId module id
 * @returns Promise
 */
export const restoreSolutionsModule = (versionId, moduleId) => {
    const url = `${PREFIX}${versionId}/modules/${moduleId}/restore`;

    return axios.post(url);
};


/**
 * copy module by id
 * @param {number|string} versionId
 * @param {number|string} moduleId module id
 * @param {string} title module title
 * @returns Promise
 */
export const copySolutionsModule = (versionId, moduleId, title) => {
    const url = `${PREFIX}${versionId}/modules/${moduleId}/copy`;

    return axios.post(url, { title });
};
