<template>
    <SbsModal
        v-model="isShowModal"
        class="HomeworkFiltersModal"
        modalBodyClass="HomeworkFiltersModal__Body"
        :width="550"
        type="sidebar"
        position="right"
        :showCloseButton="true">
        <ClimbUpButton
            v-if="isShowClimbUpBtn"
            class="HomeworkFiltersModal__ClimbUp"
            @click="scrollTop" />
        <SbsScrollWrapper
            ref="filterScrollRef"
            class="HomeworkFiltersModal__ScrollContainer"
            hideTopCurtain
            @scroll="checkShowClimbUpBtn">
            <div class="HomeworkFiltersModal__ModalWrapper">
                <div class="HomeworkFiltersModal__Header">
                    Фильтры
                </div>
                <div
                    v-for="(section, index) in schema"
                    :key="`section-${index}`"
                    class="HomeworkFiltersModal__Section"
                    :class="{ 'HomeworkFiltersModal__Section--Single': !section.title && section.items.length === 1 }">
                    <h4
                        v-if="section.title"
                        class="HomeworkFiltersModal__SectionTitle">
                        {{ section.title }}
                    </h4>
                    <div class="HomeworkFiltersModal__Group">
                        <SbsCheckbox
                            v-if="section.items.length === 1"
                            :key="section.key"
                            :modelValue="filters[section.key]"
                            :value="section.items[0].value"
                            class="HomeworkFiltersModal__Checkbox"
                            labelClass="HomeworkFiltersModal__Label"
                            :label="section.items[0].label"
                            @update:modelValue="handleFilter(section.key, $event)" />
                        <SbsCheckboxGroup
                            v-else
                            :modelValue="filters[section.key]"
                            @update:modelValue="handleFilter(section.key, $event)">
                            <SbsCheckbox
                                v-for="item in section.items"
                                :key="item.value"
                                class="HomeworkFiltersModal__Checkbox"
                                labelClass="HomeworkFiltersModal__Label"
                                :label="item.label"
                                :value="item.value" />
                        </SbsCheckboxGroup>
                    </div>
                </div>
                <StructureFiltersMembersSearch
                    title="Проверяющие"
                    type="mentors"
                    :homeworkId="homeworkId"
                    :activeMembers="filters.mentors"
                    @update:activeMembers="handleFilter('mentors', $event)" />
            </div>
        </SbsScrollWrapper>
        <div class="HomeworkFiltersModal__Buttons">
            <SbsButton
                class="HomeworkFiltersModal__Button"
                text="Показать"
                colorTheme="white"
                backgroundTheme="primary"
                :bordered="false"
                size="large"
                v-bind="submitButtonProps"
                static
                @click="$emit('filters:submit')" />
            <SbsButton
                class="HomeworkFiltersModal__Button"
                text="Сбросить"
                backgroundTheme="gray-ultra-light"
                borderTheme="gray-light"
                size="large"
                static
                @click="$emit('filters:reset')" />
        </div>
    </SbsModal>
</template>

<script>
import StructureFiltersMembersSearch
    from '@/admin/components/LongreadSolutionStructure/StructureFiltersMembersSearch.vue';
import ClimbUpButton from '@/components/UI/ClimbUpButton.vue';

const VISIBLE_SCROLL_OFFSET = 60;

export default {
    name: 'HomeworkFiltersModal',
    components: {
        ClimbUpButton,
        StructureFiltersMembersSearch,
    },
    emits: ['filter:change', 'filters:reset', 'filters:submit', 'update:isShow'],
    data: () => ({
        isShowClimbUpBtn: false,
    }),
    computed: {
        isShowModal: {
            get() {
                return this.isShow;
            },
            set(value) {
                this.$emit('update:isShow', value);
            },
        },
        submitButtonProps() {
            if (this.isAnyFilterEnabled) {
                const shouldShow = this.homeworksLength;
                const text = shouldShow ? `Показать (${this.homeworksLength})` : 'Нет результатов';

                return {
                    text,
                    disabled: !shouldShow,
                    loading: this.isDataLoading,
                };
            }

            return {
                text: 'Выберите фильтр',
                disabled: true,
                loading: this.isDataLoading,
            };
        },
    },
    methods: {
        handleFilter(key, value) {
            this.$emit('filter:change', {
                key,
                value,
            });
        },
        checkShowClimbUpBtn(event) {
            if (!event) return;

            this.isShowClimbUpBtn = event.target?.scrollTop > VISIBLE_SCROLL_OFFSET;
        },
        scrollTop() {
            this.getModalElement()?.scroll({
                top: 0,
                behavior: 'smooth',
            });
        },
        getModalElement() {
            const { filterScrollRef } = this.$refs;

            if (!filterScrollRef) return null;

            return filterScrollRef?.$el.querySelector('[data-overlayscrollbars-contents]') || null;
        },
    },
    props: {
        homeworkId: {
            type: [String, Number],
            required: true,
        },
        isShow: {
            type: Boolean,
            default: false,
        },
        isDataLoading: {
            type: Boolean,
            default: false,
        },
        schema: {
            type: Object,
            required: true,
        },
        filters: {
            type: Object,
            required: true,
        },
        isAnyFilterEnabled: {
            type: Boolean,
            default: false,
        },
        homeworksLength: {
            type: Number,
            default: 0,
        },
    },
};
</script>

<style lang="scss" scoped>
.HomeworkFiltersModal__Body {
    display: flex;
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 0;
}

.HomeworkFiltersModal__ClimbUp {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
}

.HomeworkFiltersModal__ScrollContainer {
    flex-grow: 1;
    flex-basis: 0;
}

.HomeworkFiltersModal__Wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    max-height: 100%;
}

.HomeworkFiltersModal__Header {
    margin-bottom: 25px;

    @include typo-h2-bold;
}

.HomeworkFiltersModal__Section {
  margin-bottom: 20px;

  &--Single {
      margin-bottom: 0;

      + .HomeworkFiltersModal__Section:not(.HomeworkFiltersModal__Section--Single) {
          margin-top: 20px;
      }
  }
}

.HomeworkFiltersModal__SectionTitle {
  @include typo-h4-demi();
  margin-bottom: 10px;
}

.HomeworkFiltersModal__Checkbox {
  &:not(:first-child) {
    margin-top: 5px;
  }
}

.HomeworkFiltersModal__Buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
}

.HomeworkFiltersModal__Button {
  width: 220px;
}
</style>
