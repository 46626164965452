import { Utils } from '@sbs/uikit-constructor';
import { FileTypes, getFileType } from '@/utils/fileTypes.js';

/**
 * @param {File} file
 * @param {Object} [options]
 * @param {boolean} options.downloadable
 * @returns {any}
 */
// eslint-disable-next-line complexity
export const getFileData = (file, options = {}) => {
    if (!file) return {};

    const { downloadable } = options;

    const ext = Utils.FileTypes.getFileExtension(file.name || file.file_name);
    const type = getFileType(ext);
    const icon = FileTypes[type].ICON;
    const iconColor = FileTypes[type].ICON_COLOR;
    const strokeColor = FileTypes[type].STROKE_COLOR;
    const backgroundColor = FileTypes[type].BACKGROUND_COLOR;
    const hoverBackground = downloadable
        ? 'white'
        : 'gray-ultra-light';

    return {
        ...file,
        id: file.id || Utils.Helpers.getRandomId(),
        fileIcon: icon,
        fileIconTheme: iconColor,
        fileBackgroundTheme: backgroundColor,
        fileHoverBackground: hoverBackground,
        loaderStrokeTheme: strokeColor,
        name: file.name || file.file_name,
        progress: file.progress ?? 100,
        size: file.size || file.file_size,
        type: file.type || file.mime || file.file_mime,
        originalFile: file instanceof File
            ? file
            : file.originalFile,
        title: file instanceof File
            ? Utils.FileTypes.getFileName(file.name || file.file_name)
            : file.title,
    };
};
