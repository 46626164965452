/* eslint-disable no-template-curly-in-string, camelcase */
import * as yup from 'yup';
import '@/utils/validatorsYup.js';
import {
    LIKERT_SCALE_TYPES,
    MAX_ANSWERS,
    MIN_ANSWERS,
    MAX_QUESTION_TITLE,
    MAX_QUESTION_DESCRIPTION,
    MIN_ANSWERS_GAP_IN_TEXT,
    MAX_LENGTH_ANSWER,
    MAX_LENGTH_ANSWER_GAPSTEXT,
    MAX_LENGTH_ANSWER_LIKERT,
    MAX_LENGTH_ANSWER_MATCHTEXT,
    MAX_LENGTH_COMMENT,
    QUESTION_TYPES,
    REACTION_TYPES,
    TestQuizTypes,
    MAX_INFORMATION_DESCRIPTION,
    MIN_MATCH_ITEMS_NUMBER,
    MAX_MATCH_ITEMS_NUMBER,
} from './constants.js';

const maxContentMsg = 'Превышено допустимое количество символов';
const answerTitleMsgMax = 'Ответ не должен превышать ${max} символов';
const answerCommentMsgMax = 'Комментарий не должен превышать ${max} символов';
const answerTitleSchema = yup.string().required('Укажите вариант ответа');
const simpleAnswerSchema = yup.object({
    title: yup.string().nullable(),
    reactions_data: yup.string().nullable(),
    is_correct: yup.boolean().nullable(),
});

export const checkGapAnswerInText = (content, answer) => {
    const exist = content?.includes(`data-answer-id="${answer.id}"`);

    return Boolean(exist);
};

const createReactionDataSchema = questionData => {
    if (questionData?.reactionType === REACTION_TYPES.EVERY) {
        return yup
            .string()
            .required('Необходимо указать комментарий')
            .maxHtml(MAX_LENGTH_COMMENT, answerCommentMsgMax);
    }

    return yup.string().nullable();
};

const createIsCorrectSchema = (blockType, questionType, answers) => {
    let isCorrectSchema = yup.boolean();

    if (blockType === TestQuizTypes.TEST && [QUESTION_TYPES.MULTIPLE_ANSWERS, QUESTION_TYPES.SINGLE_CHOICE].includes(questionType)) {
        isCorrectSchema = isCorrectSchema
            .required('Необходимо выбрать вариант ответа');

        if (questionType === QUESTION_TYPES.MULTIPLE_ANSWERS) {
            isCorrectSchema = isCorrectSchema
                .test('requiredOne', 'Необходимо выбрать хотя бы один вариант ответа', () => {
                    const isHaveSelected = answers?.some(item => item.is_correct);

                    return isHaveSelected;
                });
        }

        if (questionType === QUESTION_TYPES.SINGLE_CHOICE) {
            return isCorrectSchema
                .test('requiredOne', 'Необходимо выбрать один вариант ответа', () => {
                    const countSelected = answers?.reduce((acc, item) => acc + Number(Boolean(item.is_correct)), 0);

                    return countSelected === 1;
                });
        }
    } else {
        isCorrectSchema = isCorrectSchema.nullable();
    }

    return isCorrectSchema;
};

const questionsSchema = yup.array().of(yup.object({
    title: yup
        .string()
        .required('Заполните заголовок')
        .maxHtml(MAX_QUESTION_TITLE, maxContentMsg),
    description: yup
        .string()
        .when(['type'], ([type], schema) => {
            if (type === QUESTION_TYPES.INFORMATION) {
                return schema
                    .required('Заполните описание')
                    .maxHtml(MAX_INFORMATION_DESCRIPTION, maxContentMsg);
            }

            return schema
                .nullable()
                .maxHtml(MAX_QUESTION_DESCRIPTION, maxContentMsg);
        }),
    question_data: yup
        .object()
        .json()
        .when(['type'], ([type], schema, ctx) => {
            if (type === QUESTION_TYPES.GAPS_IN_TEXT) {
                return schema.shape({
                    questionContent: yup
                        .string()
                        .required('Необходимо заполнить текст')
                        .test({
                            name: 'exist_answers',
                            message: 'Необходимо расставить все пропуски в тексте',
                            test: content => {
                                const isAnswerExist = answer => checkGapAnswerInText(content, answer);
                                const eachAnswerExist = ctx.parent?.answers?.every(isAnswerExist);

                                return Boolean(eachAnswerExist);
                            },
                        }),
                });
            }

            return schema;
        }),
    answers: yup.array()
        .when(['type'], ([type], schema) => {
            if (type === QUESTION_TYPES.INFORMATION) {
                return schema;
            }

            if (type === QUESTION_TYPES.GAPS_IN_TEXT) {
                return schema.min(MIN_ANSWERS_GAP_IN_TEXT);
            }

            if (type === QUESTION_TYPES.MATCHING) {
                return schema
                    .min(MIN_MATCH_ITEMS_NUMBER)
                    .max(MAX_MATCH_ITEMS_NUMBER);
            }

            return schema
                .min(MIN_ANSWERS)
                .max(MAX_ANSWERS);
        })
        // eslint-disable-next-line camelcase, max-lines-per-function
        .when(['type', 'question_data', '$blockType'], ([type, question_data, blockType], schemaWhen, ctx) => {
            const questionData = question_data;

            if ([QUESTION_TYPES.OPEN_QUESTION, QUESTION_TYPES.INFORMATION].includes(type)) {
                return schemaWhen.of(simpleAnswerSchema);
            }

            const reactionsDataSchema = createReactionDataSchema(question_data);

            const isCorrectSchema = createIsCorrectSchema(blockType, type, ctx.value);

            if (type === QUESTION_TYPES.LIKERT_SCALE) {
                if (!questionData?.likertType || questionData?.likertType === LIKERT_SCALE_TYPES.NUMBER) {
                    return schemaWhen.of(simpleAnswerSchema);
                }

                const answerEachSchema = ctx.value.map((answer, index) => {
                    const isLastOrFirst = (index === 0) || (index === ctx.value.length - 1);

                    return yup.object({
                        title: isLastOrFirst
                            ? answerTitleSchema.maxHtml(MAX_LENGTH_ANSWER_LIKERT, answerTitleMsgMax)
                            : yup.string().nullable(),
                        reactions_data: reactionsDataSchema,
                        is_correct: isCorrectSchema,
                    });
                });

                return yup.tuple(answerEachSchema);
            }

            if (type === QUESTION_TYPES.MATCHING) {
                return schemaWhen.of(yup.object({
                    title: answerTitleSchema.maxHtml(MAX_LENGTH_ANSWER_MATCHTEXT, answerTitleMsgMax),
                    reactions_data: reactionsDataSchema,
                    data: yup
                        .object({
                            side: yup.string(),
                            correctAnswerId: yup
                                .array()
                                .min(1, 'Необходимо сопоставить элемент')
                                .required('Необходимо сопоставить элемент'),
                        })
                        .json(),
                    is_correct: isCorrectSchema,
                }));
            }

            if (type === QUESTION_TYPES.GAPS_IN_TEXT) {
                return schemaWhen.of(yup.object({
                    title: yup
                        .string()
                        .when(['data'], ([data], schema) => {
                            if (data?.withChildren) {
                                return schema.nullable();
                            }

                            return schema
                                .required('Необходимо заполнить текст ответа')
                                .maxHtml(MAX_LENGTH_ANSWER_GAPSTEXT, answerTitleMsgMax);
                        })
                        .when(['id'], ([id], schema) => schema.test({
                            name: 'answer_exist',
                            message: 'Необходимо перетащить пропуст в текст',
                            test: () => checkGapAnswerInText(questionData?.questionContent, { id }),
                        })),
                    data: yup
                        .object({
                            withChildren: yup.boolean().nullable(),
                            sortData: yup.array().nullable(),
                        })
                        .json()
                        .nullable(),
                    reactions_data: reactionsDataSchema,
                    is_correct: isCorrectSchema,
                    children: yup.array().when(['data'], ([data], schema, ctxChildren) => {
                        if (data?.withChildren) {
                            return schema
                                .required('Необходимо добавить варианты ответа')
                                .min(MIN_ANSWERS, 'Вариантов ответов должно быть не менее ${min}')
                                .max(MAX_ANSWERS, 'Вариантов ответов должно быть не больше $max}')
                                .of(yup.object({
                                    title: answerTitleSchema.maxHtml(MAX_LENGTH_ANSWER_GAPSTEXT, answerTitleMsgMax),
                                    reactions_data: reactionsDataSchema,
                                    is_correct: createIsCorrectSchema(
                                        TestQuizTypes.TEST,
                                        QUESTION_TYPES.SINGLE_CHOICE,
                                        ctxChildren.value,
                                    ),
                                }));
                        }

                        return schema;
                    }),
                }));
            }

            return schemaWhen.of(yup.object({
                title: answerTitleSchema.maxHtml(MAX_LENGTH_ANSWER, answerTitleMsgMax, true),
                reactions_data: reactionsDataSchema,
                is_correct: isCorrectSchema,
            }));
        }),
}));

export const questionWrapSchema = yup.object({
    questions: questionsSchema,
});
