export const solutionsStructureState = {
    structure: {},
    error: '',
    loading: false,
    removedModuleIndex: 0,
    removedSectionIndex: 0,
    removedPageIndex: 0,
    accessDataForRestore: null,
    lastModuleCreatedId: null,
};
