import store from '@/store/index.js';
import { userActionTypes, userGetterTypes } from '@/store/modules/user.js';
import { developmentCentersGetterTypes } from '@/admin/store/modules/developmentCenters/getters.js';
import { developmentCentersActionTypes } from '@/admin/store/modules/developmentCenters/actions.js';
import HttpStatusCodes from '@/utils/httpStatusCodes.js';

export const authAndCenterIdGuard = async (to, _from, next) => {
    try {
        if (!store.getters[`user/${userGetterTypes.GET_USER}`].name) await store.dispatch(`user/${userActionTypes.GET_USER}`);

        if (!store.getters[`developmentCenters/${developmentCentersGetterTypes.GET_DEVELOPMENT_CENTERS}`]?.length) {
            await store.dispatch(`developmentCenters/${developmentCentersActionTypes.FETCH_DEVELOPMENT_CENTERS}`);
        }

        if (!store.getters[`developmentCenters/${developmentCentersGetterTypes.GET_SELECTED_DEVELOPMENT_CENTER}`]) {
            await store.dispatch(`developmentCenters/${developmentCentersActionTypes.FETCH_SELECTED_DEVELOPMENT_CENTER}`);
        }

        const toDevelopmentCenterId = String(to.params.developmentCenterId).trim();
        const defaultDevelopmentCenterId = store.getters['developmentCenters/getDefaultDevelopmentCenterId'];

        const user = store.getters[`user/${userGetterTypes.GET_USER}`];
        const isDefaultUser = !user.has_dc_access;

        if (isDefaultUser || Number(toDevelopmentCenterId) === 0) {
            store.dispatch('globals/setGlobalError', HttpStatusCodes.NOT_FOUND);
            next();
        } else if (toDevelopmentCenterId && !isNaN(toDevelopmentCenterId)) {
            next();
        } else {
            next({
                name: 'AdminProjectsList',
                params: {
                    developmentCenterId: defaultDevelopmentCenterId,
                },
            });
        }
    } catch (e) {
        console.error(e);
        store.dispatch('globals/setGlobalError');
        next();
    }
};
