import * as solutionApi from '@/api/solutionsListApi.js';
import * as homeworkApi from '@/api/homeworkApi.js';
import { createCancelHttp } from '@/utils/http.js';
import httpStatusCodes from '@/utils/httpStatusCodes.js';
import { solutionsGetterTypes } from './getters.js';
import { solutionsMutationTypes } from './mutations.js';

const errorHandler = (error, actionName) => {
    const errorText = `error in ${actionName} action`;

    console.error(errorText, error);

    return Promise.reject(error);
};

export const solutionsActionTypes = Object.freeze({
    FETCH_SOLUTIONS: '[solutions] fetch solutions',
    GET_SOLUTIONS_STATUS_LIST: '[solutions] get solutions status list',
    GET_COUNTS: '[solution] get counters',
    ADD_SOLUTION: '[solutions] add solution',
    RESET_CURRENT_PAGE: '[solutions] reset current page',
    SET_CURRENT_PAGE: '[solutions] set current page',
    UPDATE_SOLUTION: '[solutions] update solution',
    UPDATE_SOLUTION_ACCESS: '[solutions] update solution access',
    REMOVE_SOLUTION: '[solutions] remove solution',
    RESET_STATE: '[solutions] reset solutions state',
    RESTORE_SOLUTION: '[solution] restore solution',
    COPY_SOLUTION: '[solution] copy solution',
    CREATE_VERSION: '[solution] create version',
    UPDATE_VERSION: '[solution] update version',
    REMOVE_VERSION: '[solution] remove version',
    RESTORE_VERSION: '[solution] restore version',
    COPY_VERSION: '[solution] copy version',
    UPDATE_VERSION_ACCESS: '[solution] update version access',
    RESTORE_VERSION_ACCESS: '[solution] restore version access',
    FETCH_CURRENT_PROJECT: '[solution] fetch current project',
    UPDATE_PROJECT_ARCHIVAL_STATUS: '[solution] update project archival status',
    FETCH_VERSIONS: '[solution] fetch versions',
    UPDATE_VERSION_STATUS_COUNTS: '[solution] update versions status counts',
    RESET_VERSIONS_STATE: '[solution] reset versions state',
    FETCH_VERSION: '[solution] fetch version',
    FETCH_HOMEWORK_ACTIVITIES: '[solution] fetch homework activities',
});

export const solutionsActions = {
    async [solutionsActionTypes.FETCH_SOLUTIONS]({ commit, getters }, { centerId: id, isReplaceContent = true, ...params }) {
        commit(solutionsMutationTypes.SET_LOADING, true);

        try {
            const { data } = await solutionApi.getSolutions(id, params, {
                cancelToken: createCancelHttp('getSolutions').token,
            });
            const solutions = isReplaceContent ? data.data : [...getters[solutionsGetterTypes.GET_SOLUTIONS], ...data.data];

            commit(solutionsMutationTypes.SET_SOLUTIONS_ACTIONS, data.actions);
            commit(solutionsMutationTypes.SET_LAST_PAGE, data.meta.last_page);
            commit(solutionsMutationTypes.SET_SOLUTIONS_LIST, solutions);
            commit(solutionsMutationTypes.SET_COUNTS, data.counts);
            commit(solutionsMutationTypes.SET_ERROR, false);
            commit(solutionsMutationTypes.SET_ACCESS_DENIED, false);

            return Promise.resolve(solutions);
        } catch (error) {
            if (error.response?.status === httpStatusCodes.FORBIDDEN) commit(solutionsMutationTypes.SET_ACCESS_DENIED, true);

            return errorHandler(error, solutionsActionTypes.FETCH_SOLUTIONS);
        } finally {
            commit(solutionsMutationTypes.SET_LOADING, false);
        }
    },

    async [solutionsActionTypes.GET_COUNTS]({ commit }, params) {
        const { centerId: id } = params;

        try {
            const { data } = await solutionApi.getSolutions(id, {
                ...params,
                page: 1,
            });

            commit(solutionsMutationTypes.SET_COUNTS, data.counts);
        } catch (error) {
            return errorHandler(error, solutionsActionTypes.GET_COUNTS);
        }
    },

    async [solutionsActionTypes.GET_SOLUTIONS_STATUS_LIST]({ commit }) {
        try {
            const { data } = await solutionApi.getSolutionsStatusList();

            commit(solutionsMutationTypes.SET_SOLUTIONS_STATUS_LIST, data.data);
        } catch (error) {
            return errorHandler(error, solutionsActionTypes.GET_COUNTS);
        }
    },

    async [solutionsActionTypes.ADD_SOLUTION]({ commit, getters }, solutionData) {
        const { projectData, developmentCenterId } = solutionData;

        try {
            const { data } = await solutionApi.addSolution(developmentCenterId, projectData);

            commit(solutionsMutationTypes.SET_SOLUTIONS_LIST, [data.data, ...getters[solutionsGetterTypes.GET_SOLUTIONS]]);

            return Promise.resolve(data.data);
        } catch (error) {
            return errorHandler(error, solutionsActionTypes.ADD_SOLUTION);
        }
    },

    [solutionsActionTypes.RESET_CURRENT_PAGE]({ commit }) {
        commit(solutionsMutationTypes.SET_CURRENT_PAGE, 1);
    },

    [solutionsActionTypes.SET_CURRENT_PAGE]({ commit }, value) {
        commit(solutionsMutationTypes.SET_CURRENT_PAGE, value);
    },

    async [solutionsActionTypes.UPDATE_SOLUTION]({ commit, getters }, solutionData) {
        try {
            const {
                centerId,
                id,
                ...rest
            } = solutionData;
            const { data } = await solutionApi.updateSolution(centerId, id, rest);
            const currentSolutionsList = getters[solutionsGetterTypes.GET_SOLUTIONS];
            const newSolutions = currentSolutionsList.map(item => (item.id === id ? data.data : item));

            commit(solutionsMutationTypes.SET_CURRENT_PROJECT, data.data);
            commit(solutionsMutationTypes.SET_SOLUTIONS_LIST, newSolutions);

            return Promise.resolve(data.data);
        } catch (error) {
            return errorHandler(error, solutionsActionTypes.UPDATE_SOLUTION);
        }
    },
    async [solutionsActionTypes.UPDATE_SOLUTION_ACCESS]({ commit, getters }, { centerId, solutionId, isPrivate, members }) {
        try {

            const { data } = await solutionApi.updateSolutionAccess(centerId, solutionId, {
                is_private: isPrivate,
                members,
            });
            const currentSolutionsList = getters[solutionsGetterTypes.GET_SOLUTIONS];
            const newSolutions = currentSolutionsList.map(item => (item.id === solutionId ? data.data : item));

            commit(solutionsMutationTypes.SET_CURRENT_PROJECT, data.data);
            commit(solutionsMutationTypes.SET_SOLUTIONS_LIST, newSolutions);

            return Promise.resolve(data.data);
        } catch (error) {
            return errorHandler(error, solutionsActionTypes.UPDATE_SOLUTION_ACCESS);
        }
    },
    async [solutionsActionTypes.REMOVE_SOLUTION]({ commit, getters }, removeData) {
        try {
            const { centerId, id } = removeData;

            await solutionApi.removeSolution(centerId, id);

            const newSolutions = getters[solutionsGetterTypes.GET_SOLUTIONS].filter((item, index) => {
                if (item.id === id) {
                    commit(solutionsMutationTypes.SET_REMOVED_SOLUTION_INDEX, index);
                }

                return item.id !== id;
            });

            commit(solutionsMutationTypes.SET_SOLUTIONS_LIST, newSolutions);
        } catch (error) {
            return errorHandler(error, solutionsActionTypes.REMOVE_SOLUTION);
        }
    },

    async [solutionsActionTypes.RESTORE_SOLUTION]({ commit, getters }, restoreData) {
        const { centerId, id } = restoreData;

        try {
            const { data } = await solutionApi.restoreSolution(centerId, id);
            const solutions = getters[solutionsGetterTypes.GET_SOLUTIONS];

            solutions.splice(getters[solutionsGetterTypes.GET_SOLUTION_REMOVED_INDEX], 0, data.data);

            commit(solutionsMutationTypes.SET_SOLUTIONS_LIST, solutions);
        } catch (error) {
            return errorHandler(error, solutionsActionTypes.RESTORE_SOLUTION);
        }
    },

    async [solutionsActionTypes.COPY_SOLUTION]({ commit, getters }, { centerId, id, title }) {
        try {
            const { data } = await solutionApi.copySolution(centerId, id, title);
            const newSolutions = [data.data, ...getters[solutionsGetterTypes.GET_SOLUTIONS]];

            commit(solutionsMutationTypes.SET_SOLUTIONS_LIST, newSolutions);

            return Promise.resolve(data.data);
        } catch (error) {
            return errorHandler(error, solutionsActionTypes.COPY_SOLUTION);
        }
    },

    async [solutionsActionTypes.CREATE_VERSION]({ commit, getters }, { solutionId, versionData }) {
        try {
            const { data } = await solutionApi.addSolutionVersion(solutionId, versionData);

            const versions = [data.data, ...getters[solutionsGetterTypes.GET_VERSIONS]];

            commit(solutionsMutationTypes.SET_VERSIONS, versions);
            commit(solutionsMutationTypes.SET_VERSIONS_TOTAL_COUNT, versions.length);

            return Promise.resolve(data.data);
        } catch (error) {
            return errorHandler(error, solutionsActionTypes.CREATE_VERSION);
        }
    },

    [solutionsActionTypes.UPDATE_VERSION_STATUS_COUNTS]({ commit, getters }) {
        const versions = getters[solutionsGetterTypes.GET_VERSIONS];

        const counts = {
            archived: 0,
            draft: 0,
            saved: 0,
            published: 0,
        };

        versions.forEach(({ status }) => {
            counts[status] += 1;
        });

        commit(solutionsMutationTypes.SET_VERSIONS_COUNTS, counts);
    },
    async [solutionsActionTypes.UPDATE_VERSION]({ commit, getters, dispatch }, { solutionId, versionId, versionData }) {
        try {
            const { data } = await solutionApi.updateSolutionVersion(solutionId, versionId, versionData);

            const versions = getters[solutionsGetterTypes.GET_VERSIONS].map(version => (version.id === versionId ? data.data : version));

            commit(solutionsMutationTypes.SET_VERSIONS, versions);
            commit(solutionsMutationTypes.SET_VERSION, data.data);
            dispatch(solutionsActionTypes.UPDATE_VERSION_STATUS_COUNTS);

            return Promise.resolve(data.data);
        } catch (error) {
            return errorHandler(error, solutionsActionTypes.UPDATE_VERSION);
        }
    },

    async [solutionsActionTypes.REMOVE_VERSION]({ commit, getters }, { solutionId, versionId }) {
        try {
            const versions = getters[solutionsGetterTypes.GET_VERSIONS];

            await solutionApi.removeSolutionVersion(solutionId, versionId);

            const updatedVersions = versions.filter((version, index) => {
                const isMatchVersion = version.id === versionId;

                if (isMatchVersion) commit(solutionsMutationTypes.SET_REMOVED_SOLUTION_INDEX, index);

                return !isMatchVersion;
            });

            commit(solutionsMutationTypes.SET_VERSIONS, updatedVersions);
            commit(solutionsMutationTypes.SET_VERSION, null);
            commit(solutionsMutationTypes.SET_VERSIONS_TOTAL_COUNT, updatedVersions.length);

            return Promise.resolve();
        } catch (error) {
            return errorHandler(error, solutionsActionTypes.REMOVE_VERSION);
        }
    },

    async [solutionsActionTypes.RESTORE_VERSION]({ commit, getters }, { solutionId, versionId }) {
        try {
            const versions = getters[solutionsGetterTypes.GET_VERSIONS];

            const { data } = await solutionApi.restoreSolutionVersion(solutionId, versionId);

            versions.splice(getters[solutionsGetterTypes.GET_REMOVED_VERSION_INDEX], 0, data.data);
            commit(solutionsMutationTypes.SET_VERSIONS, versions);

            return Promise.resolve(data.data);
        } catch (error) {
            return errorHandler(error, solutionsActionTypes.RESTORE_VERSION);
        }
    },

    async [solutionsActionTypes.COPY_VERSION]({ commit, getters }, { solutionId, versionId, title }) {
        try {
            const { data } = await solutionApi.copySolutionVersion(solutionId, versionId, title);

            const versions = [data.data, ...getters[solutionsGetterTypes.GET_VERSIONS]];

            commit(solutionsMutationTypes.SET_VERSIONS, versions);

            return Promise.resolve(data.data);
        } catch (error) {
            return errorHandler(error, solutionsActionTypes.COPY_VERSION);
        }
    },
    async [solutionsActionTypes.UPDATE_VERSION_ACCESS]({ commit, getters }, { solutionId, versionId, isPrivate, members }) {
        try {
            const versions = getters[solutionsGetterTypes.GET_VERSIONS];
            const { data } = await solutionApi.updateVersionAccess(solutionId, versionId, {
                is_private: isPrivate,
                members,
            });

            commit(solutionsMutationTypes.SET_VERSIONS, versions.map(item => (item.id === versionId ? data.data : item)));
            commit(solutionsMutationTypes.SET_VERSION, data.data);

            return Promise.resolve(data.data);
        } catch (error) {
            return errorHandler(error, solutionsActionTypes.UPDATE_VERSION_ACCESS);
        }
    },
    async [solutionsActionTypes.RESTORE_VERSION_ACCESS]({ commit, dispatch, getters }) {
        const dataForRestore = getters[solutionsGetterTypes.GET_ACCESS_DATA_FOR_RESTORE];

        if (!dataForRestore) return;

        try {
            const restoredVersion = await dispatch(solutionsActionTypes.UPDATE_VERSION_ACCESS, dataForRestore);

            commit(solutionsMutationTypes.SET_ACCESS_DATA_FOR_RESTORE, null);

            return Promise.resolve(restoredVersion);
        } catch (error) {
            return errorHandler(error, solutionsActionTypes.RESTORE_VERSION_ACCESS);
        }
    },
    [solutionsActionTypes.RESET_STATE]({ commit }) {
        commit(solutionsMutationTypes.RESET_STATE);
    },
    async [solutionsActionTypes.FETCH_CURRENT_PROJECT]({ commit }, { centerId, projectId }) {
        try {
            const { data } = await solutionApi.getProjectById(centerId, projectId);

            commit(solutionsMutationTypes.SET_CURRENT_PROJECT, data.data);
            commit(solutionsMutationTypes.SET_ACCESS_DENIED, false);
        } catch (error) {
            console.error(error);

            if (error.response?.status === httpStatusCodes.FORBIDDEN) commit(solutionsMutationTypes.SET_ACCESS_DENIED, true);
        }
    },
    // eslint-disable-next-line max-statements
    async [solutionsActionTypes.FETCH_VERSIONS]({ commit, getters }, { projectId, filterParams }) {
        commit(solutionsMutationTypes.SET_VERSIONS_LOADING, true);

        const { isReplaceContent, ...params } = filterParams;

        try {
            const { data } = await solutionApi.getVersionsList(projectId, params);
            const versions = isReplaceContent ? data.data : [...getters[solutionsGetterTypes.GET_VERSIONS], ...data.data];

            commit(solutionsMutationTypes.SET_VERSIONS, versions);
            commit(solutionsMutationTypes.SET_VERSIONS_CURRENT_PAGE, data.meta.current_page);
            commit(solutionsMutationTypes.SET_VERSIONS_LAST_PAGE, data.meta.last_page);
            commit(solutionsMutationTypes.SET_VERSIONS_TOTAL_COUNT, data.meta.total);
            commit(solutionsMutationTypes.SET_VERSIONS_COUNTS, data.counts);
            commit(solutionsMutationTypes.SET_VERSIONS_ERROR, false);
            commit(solutionsMutationTypes.SET_ACCESS_DENIED, false);

            return Promise.resolve(versions);
        } catch (error) {
            if (error.response?.status === httpStatusCodes.FORBIDDEN) commit(solutionsMutationTypes.SET_ACCESS_DENIED, true);

            commit(solutionsMutationTypes.SET_VERSIONS_ERROR, true);

            return errorHandler(error, solutionsActionTypes.FETCH_VERSIONS);
        } finally {
            commit(solutionsMutationTypes.SET_VERSIONS_LOADING, false);
        }
    },
    [solutionsActionTypes.RESET_VERSIONS_STATE]({ commit }) {
        commit(solutionsMutationTypes.RESET_VERSIONS_STATE);
    },
    async [solutionsActionTypes.UPDATE_PROJECT_ARCHIVAL_STATUS]({ commit, getters }, { centerId, projectId, status }) {
        try {
            const { data } = await solutionApi.updateProjectStatus(centerId, projectId, { status });
            const currentSolutionsList = getters[solutionsGetterTypes.GET_SOLUTIONS];
            const newSolutions = currentSolutionsList.map(item => (item.id === projectId ? data.data : item));

            commit(solutionsMutationTypes.SET_CURRENT_PROJECT, data.data);
            commit(solutionsMutationTypes.SET_SOLUTIONS_LIST, newSolutions);

            return Promise.resolve(data.data);
        } catch (error) {
            return errorHandler(error, solutionsActionTypes.UPDATE_SOLUTION);
        }
    },
    async [solutionsActionTypes.FETCH_VERSION]({ commit, dispatch }, { solutionId, versionId }) {
        commit(solutionsMutationTypes.SET_VERSION_LOADING, true);

        try {
            const { data } = await solutionApi.getSolutionVersion(solutionId, versionId);

            commit(solutionsMutationTypes.SET_VERSION, data.data);
        } catch (error) {
            dispatch('globals/setGlobalerrorFromResponseData', error, { root: true });

            return errorHandler(error, solutionsActionTypes.FETCH_VERSION);
        } finally {
            commit(solutionsMutationTypes.SET_VERSION_LOADING, false);
        }
    },
    async [solutionsActionTypes.FETCH_HOMEWORK_ACTIVITIES]({ commit }, { versionId }) {
        try {
            const { data } = await homeworkApi.getVersionHomeworkActivities({ versionId });

            commit(solutionsMutationTypes.SET_VERSION_HOMEWORK_ACTIVITIES, data.data);
            commit(solutionsMutationTypes.SET_VERSION_HOMEWORK_ACTIVITIES_TOTAL, data.meta.total);
        } catch (error) {
            return errorHandler(error, solutionsActionTypes.UPDATE_SOLUTION);
        }
    },
};
