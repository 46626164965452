import { Utils } from '@sbs/uikit-constructor';
import { isNeedUpdateVersionCompletionType, solutionResponseStructureHelper } from '@/utils/helpers.js';
import * as solutionStructureApi from '@/api/solutionsListApi.js';
import * as solutionModulesApi from '@/api/solutionsModulesApi.js';
import * as solutionSectionsApi from '@/api/solutionsSectionsApi.js';
import * as pageApi from '@/api/pageApi.js';
import { updateVersionAccess } from '@/api/solutionsListApi.js';
import { PAGE_STATUSES } from '@/constants/index.js';
import { solutionsStructureGetterTypes } from './getters.js';
import { solutionsStructureMutationTypes } from './mutations.js';

const errorHandler = (error, actionName) => {
    const errorText = `error in ${actionName} action`;

    console.error(errorText, error);

    return Promise.reject(error);
};

export const solutionsStructureActionTypes = Object.freeze({
    FETCH_STRUCTURE: '[structure] fetch solutions structure',
    UPDATE_STRUCTURE: '[structure] update solutions structure',
    UPDATE_VERSION: '[structure] update current version',
    UPDATE_VERSION_COMPLETION_TYPE: '[structure] update current version completion_type',

    CREATE_TEMP_MODULE: '[structure] create temporary module',
    CREATE_MODULE: '[structure] create module',
    UPDATE_TEMP_MODULE: '[structure] update temporary module',
    UPDATE_MODULE: '[structure] update module',
    REMOVE_MODULE: '[structure] remove module',
    RESTORE_MODULE: '[structure] restore module',
    COPY_MODULE: '[structure] copy module',

    CREATE_SECTION: '[structure] create section',
    UPDATE_SECTION: '[structure] update section',
    COPY_SECTION: '[structure copy section',
    REMOVE_SECTION: '[structure] remove section',
    RESTORE_SECTION: '[structure] restore section',

    CREATE_PAGE: '[structure] create page',
    UPDATE_PAGE: '[structure] update page',
    UPDATE_STRUCTURE_SINGLE_MODULE_AND_PAGE: '[structure] update structure single module and single page',
    REMOVE_PAGE: '[structure] remove page',
    RESTORE_PAGE: '[structure] restore page',
    COPY_PAGE: '[structure] copy page',

    RESET_STATE: '[structure] reset state',
    UPDATE_ACCESS: '[structure] update access',
    RESTORE_ACCESS: '[structure] restore access',
});

export const solutionsStructureActions = {
    async [solutionsStructureActionTypes.FETCH_STRUCTURE]({ commit, dispatch }, { solutionId, versionId, title }) {
        commit(solutionsStructureMutationTypes.SET_LOADING, true);

        try {
            const params = { title };
            const { data } = await solutionStructureApi.getSolutionsStructure(solutionId, versionId, params);

            commit(solutionsStructureMutationTypes.SET_STRUCTURE, data.data);
            commit(solutionsStructureMutationTypes.SET_ERROR, false);

            return Promise.resolve(data.data);
        } catch (error) {
            dispatch('globals/setGlobalerrorFromResponseData', error, { root: true });

            return errorHandler(error, solutionsStructureActionTypes.FETCH_STRUCTURE);
        } finally {
            commit(solutionsStructureMutationTypes.SET_LOADING, false);
        }
    },
    async [solutionsStructureActionTypes.UPDATE_STRUCTURE]({ commit }, { solutionId, versionId, updatedStructure }) {
        try {
            const { data } = await solutionStructureApi.updateSolutionStructure(solutionId, versionId, updatedStructure);

            const structure = data.data;

            commit(solutionsStructureMutationTypes.SET_STRUCTURE, structure);

            return Promise.resolve(structure);
        } catch (error) {
            return errorHandler(error, solutionsStructureActionTypes.UPDATE_STRUCTURE);
        }
    },
    async [solutionsStructureActionTypes.UPDATE_VERSION]({ commit, getters }, { solutionId, versionId, versionData }) {
        try {
            const { data } = await solutionStructureApi.updateSolutionVersion(solutionId, versionId, versionData);
            const newStructure = {
                ...getters[solutionsStructureGetterTypes.GET_SOLUTIONS_STRUCTURE],
                version: data.data,
            };

            commit(solutionsStructureMutationTypes.SET_STRUCTURE, newStructure);

            return Promise.resolve(data.data);
        } catch (error) {
            return errorHandler(error, solutionsStructureActionTypes.UPDATE_VERSION);
        }
    },
    async [solutionsStructureActionTypes.UPDATE_VERSION_COMPLETION_TYPE]({ dispatch }, { solutionId, versionId, version }) {
        try {
            await dispatch(solutionsStructureActionTypes.UPDATE_VERSION, {
                solutionId,
                versionId,
                versionData: {
                    ...version,
                    responsibles: version.responsibles.map(el => el.id),
                },
            });
        } catch (error) {
            return errorHandler(error, solutionsStructureActionTypes.UPDATE_VERSION_COMPLETION_TYPE);
        }
    },
    async [solutionsStructureActionTypes.CREATE_TEMP_MODULE]({ commit, getters, dispatch }, { index = 0, solutionId, versionId }) {
        const structure = getters[solutionsStructureGetterTypes.GET_SOLUTIONS_STRUCTURE] ?? [];
        const modulesLength = structure.modules?.length ?? 0;
        const newModule = {
            tempId: Utils.Helpers.getRandomId(),
            title: `Модуль ${modulesLength + 1}`,
            order: index + 1,
            pages: [],
            sections: [],
        };

        structure.modules.push(newModule);

        if (isNeedUpdateVersionCompletionType(structure)) {
            structure.version.completion_type = 'marked';

            await dispatch(solutionsStructureActionTypes.UPDATE_VERSION_COMPLETION_TYPE, {
                solutionId,
                versionId,
                version: structure.version,
            });
        }

        commit(solutionsStructureMutationTypes.SET_STRUCTURE, structure);
    },
    async [solutionsStructureActionTypes.CREATE_MODULE]({ commit, dispatch, getters }, { moduleData, solutionId }) {
        try {
            const structure = { ...getters[solutionsStructureGetterTypes.GET_SOLUTIONS_STRUCTURE] };
            const index = structure.modules.findIndex(item => item.tempId === moduleData.tempId);
            const versionId = getters[solutionsStructureGetterTypes.GET_CURRENT_VERSION].id;
            const { data } = await solutionModulesApi.addSolutionsModule({
                ...moduleData,
                version_id: versionId,
            });

            structure.modules[index] = data.data;
            commit(solutionsStructureMutationTypes.SET_STRUCTURE, structure);
            commit(solutionsStructureMutationTypes.SET_LAST_MODULE_CREATED_ID, data.data.id);

            const updatedStructure = solutionResponseStructureHelper(getters[solutionsStructureGetterTypes.GET_SOLUTIONS_STRUCTURE].modules);

            await dispatch(solutionsStructureActionTypes.UPDATE_STRUCTURE, {
                solutionId,
                versionId,
                updatedStructure,
            });


            return Promise.resolve(data.data);
        } catch (error) {
            return errorHandler(error, solutionsStructureActionTypes.CREATE_MODULE);
        }
    },
    [solutionsStructureActionTypes.UPDATE_TEMP_MODULE]({ commit, getters }, moduleData) {
        const { tempId } = moduleData;
        const structure = { ...getters[solutionsStructureGetterTypes.GET_SOLUTIONS_STRUCTURE] };

        structure.modules = structure.modules.map(item => (item.tempId === tempId
            ? {
                ...item,
                ...moduleData,
            }
            : item));
        commit(solutionsStructureMutationTypes.SET_STRUCTURE, structure);
    },
    async [solutionsStructureActionTypes.UPDATE_MODULE]({ commit, getters, dispatch }, moduleData) {
        if (moduleData.tempId) {
            dispatch(solutionsStructureActionTypes.UPDATE_TEMP_MODULE, moduleData);

            return;
        }

        try {
            const { id: moduleId } = moduleData;
            const structure = { ...getters[solutionsStructureGetterTypes.GET_SOLUTIONS_STRUCTURE] };
            const { data } = await solutionModulesApi.updateSolutionsModule(moduleId, {
                ...moduleData,
                version_id: getters[solutionsStructureGetterTypes.GET_CURRENT_VERSION].id,
            });

            structure.modules = structure.modules.map(item => (item.id === moduleId ? data.data : item));
            commit(solutionsStructureMutationTypes.SET_STRUCTURE, structure);
        } catch (error) {
            return errorHandler(error, solutionsStructureActionTypes.UPDATE_MODULE);
        }
    },
    async [solutionsStructureActionTypes.REMOVE_MODULE]({ commit, getters }, removeModuleData) {
        try {
            const structure = { ...getters[solutionsStructureGetterTypes.GET_SOLUTIONS_STRUCTURE] };
            const { versionId, moduleId } = removeModuleData;

            await solutionModulesApi.removeSolutionsModule(versionId, moduleId);
            structure.modules = structure.modules.filter((item, index) => {
                if (item.id === moduleId) commit(solutionsStructureMutationTypes.SET_REMOVED_MODULE_INDEX, index);

                return item.id !== moduleId;
            });
            commit(solutionsStructureMutationTypes.SET_STRUCTURE, structure);

            return Promise.resolve(moduleId);
        } catch (error) {
            return errorHandler(error, solutionsStructureActionTypes.REMOVE_MODULE);
        }
    },
    async [solutionsStructureActionTypes.RESTORE_MODULE]({ commit, getters }, restoreModuleData) {
        try {
            const { versionId, moduleId } = restoreModuleData;
            const { data } = await solutionModulesApi.restoreSolutionsModule(versionId, moduleId);
            const newStructure = { ...getters[solutionsStructureGetterTypes.GET_SOLUTIONS_STRUCTURE] };

            newStructure.modules.splice(getters[solutionsStructureGetterTypes.GET_REMOVED_MODULE_INDEX], 0, data.data);
            commit(solutionsStructureMutationTypes.SET_STRUCTURE, newStructure);

            return Promise.resolve(data.data);
        } catch (error) {
            return errorHandler(error, solutionsStructureActionTypes.RESTORE_MODULE);
        }
    },
    async [solutionsStructureActionTypes.COPY_MODULE]({ getters, dispatch }, { solutionId, versionId, moduleId, title }) {
        try {
            const { data } = await solutionModulesApi.copySolutionsModule(versionId, moduleId, title);

            const structure = {
                ...getters[solutionsStructureGetterTypes.GET_SOLUTIONS_STRUCTURE],
                modules: [
                    ...getters[solutionsStructureGetterTypes.GET_SOLUTIONS_STRUCTURE].modules,
                    data.data,
                ],
            };

            const updatedStructure = solutionResponseStructureHelper(structure.modules);

            dispatch(solutionsStructureActionTypes.UPDATE_STRUCTURE, {
                solutionId,
                versionId,
                updatedStructure,
            });

            return Promise.resolve(data.data);
        } catch (error) {
            return errorHandler(error, solutionsStructureActionTypes.COPY_MODULE);
        }
    },
    async [solutionsStructureActionTypes.CREATE_SECTION]({ dispatch, getters }, { solutionId, versionId, moduleId }) {
        try {
            const currentModule = { ...getters[solutionsStructureGetterTypes.GET_MODULE](moduleId) };
            const structure = { ...getters[solutionsStructureGetterTypes.GET_SOLUTIONS_STRUCTURE] };
            const newSection = {
                title: `Раздел ${currentModule.sections.length + 1}`,
                module_id: moduleId,
            };
            const { data } = await solutionSectionsApi.addSolutionsSection(newSection);

            currentModule.sections.push(data.data);
            structure.modules = structure.modules.map(module => (module.id === currentModule.id ? currentModule : module));

            const updatedStructure = solutionResponseStructureHelper(getters[solutionsStructureGetterTypes.GET_SOLUTIONS_STRUCTURE].modules);

            await dispatch(solutionsStructureActionTypes.UPDATE_STRUCTURE, {
                solutionId,
                versionId,
                updatedStructure,
            });

            return Promise.resolve(data.data);
        } catch (error) {
            return errorHandler(error, solutionsStructureActionTypes.CREATE_SECTION);
        }
    },
    async [solutionsStructureActionTypes.UPDATE_SECTION]({ commit, getters }, { section: { section }, moduleId }) {
        const currentModule = { ...getters[solutionsStructureGetterTypes.GET_MODULE](moduleId) };


        const { id: sectionId } = section;
        const structure = { ...getters[solutionsStructureGetterTypes.GET_SOLUTIONS_STRUCTURE] };
        const { data } = await solutionSectionsApi.updateSolutionsSection(moduleId, sectionId, {
            ...section,
            moduleId,
        });

        currentModule.sections = currentModule.sections.map(item => (item.id === sectionId ? data.data : item));
        structure.modules = structure.modules.map(item => (item.id === moduleId ? currentModule : item));
        commit(solutionsStructureMutationTypes.SET_STRUCTURE, structure);
    },
    async [solutionsStructureActionTypes.COPY_SECTION]({ dispatch, getters }, { solutionId, versionId, section, title, moduleId }) {
        try {
            const currentModule = { ...getters[solutionsStructureGetterTypes.GET_MODULE](moduleId) };
            const structure = { ...getters[solutionsStructureGetterTypes.GET_SOLUTIONS_STRUCTURE] };

            const { data } = await solutionSectionsApi.copySolutionsSection(moduleId, section.id, title);

            currentModule.sections.push(data.data);
            structure.modules = structure.modules.map(module => (module.id === currentModule.id ? currentModule : module));

            const updatedStructure = solutionResponseStructureHelper(structure.modules);

            dispatch(solutionsStructureActionTypes.UPDATE_STRUCTURE, {
                solutionId,
                versionId,
                updatedStructure,
            });

            return Promise.resolve(data.data);
        } catch (error) {
            return errorHandler(error, solutionsStructureActionTypes.COPY_SECTION);
        }
    },
    async [solutionsStructureActionTypes.REMOVE_SECTION]({ commit, getters }, { section, moduleId }) {
        try {
            const currentModule = { ...getters[solutionsStructureGetterTypes.GET_MODULE](moduleId) };
            const structure = { ...getters[solutionsStructureGetterTypes.GET_SOLUTIONS_STRUCTURE] };

            await solutionSectionsApi.removeSolutionsSection(moduleId, section.section.id);
            currentModule.sections = currentModule.sections.filter((item, index) => {
                if (item.id === section.section.id) commit(solutionsStructureMutationTypes.SET_REMOVED_SECTION_INDEX, index);

                return item.id !== section.section.id;
            });

            structure.modules = structure.modules.map(module => (module.id === moduleId ? currentModule : module));

            commit(solutionsStructureMutationTypes.SET_STRUCTURE, structure);

            return Promise.resolve(section.section.id);
        } catch (error) {
            return errorHandler(error, solutionsStructureActionTypes.REMOVE_SECTION);
        }
    },
    async [solutionsStructureActionTypes.RESTORE_SECTION]({ commit, getters }, { section, moduleId }) {
        try {
            const { data } = await solutionSectionsApi.restoreSolutionsSection(moduleId, section.section.id);
            const currentModule = { ...getters[solutionsStructureGetterTypes.GET_MODULE](moduleId) };
            const structure = { ...getters[solutionsStructureGetterTypes.GET_SOLUTIONS_STRUCTURE] };

            currentModule.sections.splice(getters[solutionsStructureGetterTypes.GET_REMOVED_SECTION_INDEX], 0, data.data);
            structure.modules.map(module => (module.id === moduleId ? currentModule : module));

            commit(solutionsStructureMutationTypes.SET_STRUCTURE, structure);

            return Promise.resolve(data.data);
        } catch (error) {
            return errorHandler(error, solutionsStructureActionTypes.RESTORE_SECTION);
        }
    },
    async [solutionsStructureActionTypes.CREATE_PAGE]({ dispatch, getters }, { page, moduleId, solutionId, versionId }) {
        const pageData = page.page ? page.page : page;
        const parentNode = { ...pageData.parentNode };
        const currentModule = { ...getters[solutionsStructureGetterTypes.GET_MODULE](moduleId) };
        const structure = { ...getters[solutionsStructureGetterTypes.GET_SOLUTIONS_STRUCTURE] };

        const newPage = {
            is_educational: pageData.type === 'educational',
            name: `Страница ${parentNode.pages.length + 1}`,
            parent_type: parentNode.type,
            parent_id: parentNode.id,
        };

        const { data } = await pageApi.createPage(newPage);

        parentNode.type === 'module'
            ? currentModule.pages.push(data.data)
            : currentModule.sections.map(item => (item.id === parentNode.id ? item.pages.push(data.data) : item));

        structure.modules = structure.modules.map(module => (module.id === parentNode.id ? currentModule : module));

        const updatedStructure = {
            ...structure,
            ...solutionResponseStructureHelper(structure.modules),
        };

        if (isNeedUpdateVersionCompletionType(updatedStructure)) {
            updatedStructure.version.completion_type = 'marked';

            await dispatch(solutionsStructureActionTypes.UPDATE_VERSION_COMPLETION_TYPE, {
                solutionId,
                versionId,
                version: updatedStructure.version,
            });
        }

        await dispatch(solutionsStructureActionTypes.UPDATE_STRUCTURE, {
            solutionId,
            versionId,
            updatedStructure,
        });

        return Promise.resolve(data.data);
    },
    // eslint-disable-next-line max-statements
    async [solutionsStructureActionTypes.UPDATE_PAGE]({ commit, getters, dispatch }, page) {
        const { page: curPage, solutionId } = page.page;

        const moduleId = page.parentId;
        const sectionId = page.page.parentId;
        const pageData = curPage.page.page ?? curPage.page;
        const structure = { ...getters[solutionsStructureGetterTypes.GET_SOLUTIONS_STRUCTURE] };
        const currentModule = { ...getters[solutionsStructureGetterTypes.GET_MODULE](moduleId) };

        const { data } = await pageApi.updatePage(pageData.id, {
            ...pageData,
            name: pageData.name,
        });

        if (sectionId) {
            const currentSection = currentModule.sections.find(item => item.id === sectionId);

            currentSection.pages = currentSection.pages.map(item => (item.id === pageData.id ? data.data : item));

            currentModule.sections = currentModule.sections.map(item => (item.id === currentSection.id ? currentSection : item));
        } else {
            currentModule.pages = currentModule.pages.map(item => (item.id === pageData.id ? data.data : item));
        }

        structure.modules = structure.modules.map(item => (item.id === moduleId ? currentModule : item));

        const isPageSaved = pageData.status === PAGE_STATUSES.SAVED;
        const isSingleModuleAndPage = structure.modules?.length === 1 && structure.modules[0]?.pages?.length === 1;

        if (isPageSaved && isSingleModuleAndPage) {
            await dispatch(solutionsStructureActionTypes.UPDATE_STRUCTURE_SINGLE_MODULE_AND_PAGE, {
                structure,
                module: currentModule,
                solutionId,
            });
        } else {
            commit(solutionsStructureMutationTypes.SET_STRUCTURE, structure);
        }

        return Promise.resolve(data.data);
    },
    async [solutionsStructureActionTypes.UPDATE_STRUCTURE_SINGLE_MODULE_AND_PAGE]({ dispatch, getters }, { structure, module, solutionId }) {
        const currentStructure = { ...structure };
        const currentModule = { ...module };
        const versionId = getters[solutionsStructureGetterTypes.GET_CURRENT_VERSION].id;

        currentModule.status = PAGE_STATUSES.SAVED;
        currentStructure.version.status = PAGE_STATUSES.SAVED;

        await solutionStructureApi.updateSolutionVersion(solutionId, versionId, currentStructure.version);
        await dispatch(solutionsStructureActionTypes.UPDATE_MODULE, currentModule);
    },
    async [solutionsStructureActionTypes.REMOVE_PAGE]({ commit, getters }, page) {
        const moduleId = page.parentId;
        const sectionId = page.page.parentId;
        const pageData = page.page.page ? page.page.page : page.page;
        const structure = { ...getters[solutionsStructureGetterTypes.GET_SOLUTIONS_STRUCTURE] };
        const currentModule = { ...getters[solutionsStructureGetterTypes.GET_MODULE](moduleId) };

        await pageApi.deletePage(pageData.id);

        if (sectionId) {
            const currentSection = currentModule.sections.find(item => item.id === sectionId);

            currentSection.pages = currentSection.pages.filter((item, index) => {
                if (item.id === pageData.id) commit(solutionsStructureMutationTypes.SET_REMOVED_PAGE_INDEX, index);

                return item.id !== pageData.id;
            });

            currentModule.sections = currentModule.sections.map(item => (item.id === currentSection.id ? currentSection : item));
        } else {
            currentModule.pages = currentModule.pages.filter((item, index) => {
                if (item.id === pageData.id) commit(solutionsStructureMutationTypes.SET_REMOVED_PAGE_INDEX, index);

                return item.id !== pageData.id;
            });
        }

        structure.modules = structure.modules.map(item => (item.id === moduleId ? currentModule : item));
        commit(solutionsStructureMutationTypes.SET_STRUCTURE, structure);

        return Promise.resolve(pageData.id);
    },
    async [solutionsStructureActionTypes.RESTORE_PAGE]({ commit, getters }, page) {
        const moduleId = page.parentId;
        const sectionId = page.page.parentId;
        const pageData = page.page.page ? page.page.page : page.page;
        const structure = { ...getters[solutionsStructureGetterTypes.GET_SOLUTIONS_STRUCTURE] };
        const currentModule = { ...getters[solutionsStructureGetterTypes.GET_MODULE](moduleId) };

        const { data } = await pageApi.restorePage(pageData.id);

        if (sectionId) {
            const currentSection = currentModule.sections.find(item => item.id === sectionId);

            currentSection.pages.splice(getters[solutionsStructureGetterTypes.GET_REMOVED_PAGE_INDEX], 0, data.data);
            currentModule.sections = currentModule.sections.map(item => (item.id === currentSection.id ? currentSection : item));
        } else {
            currentModule.pages.splice(getters[solutionsStructureGetterTypes.GET_REMOVED_PAGE_INDEX], 0, data.data);
        }

        structure.modules = structure.modules.map(item => (item.id === moduleId ? currentModule : item));
        commit(solutionsStructureMutationTypes.SET_STRUCTURE, structure);

        return Promise.resolve(data.data);
    },
    async [solutionsStructureActionTypes.COPY_PAGE]({ getters, dispatch }, { page, name, moduleId, sectionId, versionId, solutionId }) {
        const structure = { ...getters[solutionsStructureGetterTypes.GET_SOLUTIONS_STRUCTURE] };
        const currentModule = { ...getters[solutionsStructureGetterTypes.GET_MODULE](moduleId) };

        const { data } = await pageApi.copyPage(page.id, name);

        if (sectionId) {
            const currentSection = currentModule.sections.find(item => item.id === sectionId);

            currentSection.pages.push(data.data);
            currentModule.sections = currentModule.sections.map(item => (item.id === currentSection.id ? currentSection : item));
        } else {
            currentModule.pages.push(data.data);
        }

        structure.modules = structure.modules.map(item => (item.id === moduleId ? currentModule : item));

        const updatedStructure = solutionResponseStructureHelper(structure.modules);

        dispatch(solutionsStructureActionTypes.UPDATE_STRUCTURE, {
            solutionId,
            versionId,
            updatedStructure,
        });

        return Promise.resolve(data.data);
    },
    [solutionsStructureActionTypes.RESET_STATE]({ commit }) {
        commit(solutionsStructureMutationTypes.RESET_STATE);
    },
    async [solutionsStructureActionTypes.UPDATE_ACCESS]({ commit, getters }, { solutionId, versionId, accessData }) {
        try {
            const currentVersion = getters[solutionsStructureGetterTypes.GET_CURRENT_VERSION];
            const dataForRestore = {
                solutionId,
                versionId,
                accessData: {
                    is_private: currentVersion.is_private,
                    added_users: currentVersion.added_users.map(user => user.id),
                },
            };
            const { data } = await updateVersionAccess(solutionId, versionId, accessData);
            const newStructure = {
                ...getters[solutionsStructureGetterTypes.GET_SOLUTIONS_STRUCTURE],
                version: data.data,
            };

            commit(solutionsStructureMutationTypes.SET_STRUCTURE, newStructure);
            commit(solutionsStructureMutationTypes.SET_ACCESS_DATA_FOR_RESTORE, dataForRestore);

            return Promise.resolve(data.data);
        } catch (error) {
            return errorHandler(error, solutionsStructureActionTypes.UPDATE_ACCESS);
        }
    },
    async [solutionsStructureActionTypes.RESTORE_ACCESS]({ dispatch, commit, getters }) {
        const dataForRestore = getters[solutionsStructureGetterTypes.GET_ACCESS_DATA_FOR_RESTORE];

        if (!dataForRestore) return;

        try {
            const restoredData = await dispatch(solutionsStructureActionTypes.UPDATE_ACCESS, dataForRestore);

            commit(solutionsStructureMutationTypes.SET_ACCESS_DATA_FOR_RESTORE, null);

            return Promise.resolve(restoredData);
        } catch (error) {
            return errorHandler(error, solutionsStructureActionTypes.RESTORE_ACCESS);
        }
    },
};
