import { homeworkState as state } from '@/admin/store/modules/tasks/state.js';
import { homeworkGetters as getters } from '@/admin/store/modules/tasks/getters.js';
import { homeworkActions as actions } from '@/admin/store/modules/tasks/actions.js';
import { homeworkMutations as mutations } from '@/admin/store/modules/tasks/mutations.js';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
