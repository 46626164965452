import isNil from 'lodash/isNil.js';

/** @type {import('vuex').Module<any, any>} */
const module = {
    namespaced: true,
    state: {
        title: '',
        audioFile: null,
        coverFile: null,
        playing: false,
        currentTime: 0,
        startTime: 0,
        episodes: [],
    },
    mutations: {
        /**
         * @param {*} state
         */
        clearAudio: state => {
            state.playing = false;
            state.audioFile = null;
            state.cover = null;
            state.episodes = [];
        },

        /**
         * @param {*} state
         * @param {object} payload
         * @param {string} payload.title
         * @param {object} payload.audio
         * @param {object} [payload.cover]
         * @param {Array<*>} [payload.episodes]
         * @param {number} [payload.startTime]
         */
        setAudio: (state, payload) => {
            state.playing = false;
            state.title = payload.title;
            state.audioFile = payload.audio;
            state.coverFile = payload.cover;
            state.coverFile = payload.cover;
            state.episodes = payload.episodes ?? [];
            state.currentTime = payload.startTime ?? 0;
            state.startTime = payload.startTime ?? 0;
        },

        /**
         * @param {*} state
         * @param {number} [payload]
         */
        play: (state, payload) => {
            if (isNil(payload)) {
                state.playing = true;
            } else {
                state.startTime = state.currentTime;
                setTimeout(() => {
                    state.currentTime = payload;
                    state.startTime = payload;

                    setTimeout(() => {
                        state.playing = true;
                    });
                });
            }
        },

        /**
         * @param {*} state
         */
        pause: state => {
            state.playing = false;
        },

        /**
         * @param {*} state
         * @param {boolean} payload
         */
        changePlayState: (state, payload) => {
            state.playing = payload;
        },

        /**
         * @param {*} state
         * @param {number} payload
         */
        changeProgress: (state, payload) => {
            state.currentTime = payload;
        },

        /**
         * @param {*} state
         * @param {object|null} payload
         */
        changeCover: (state, payload) => {
            state.coverFile = payload || null;
        },

        /**
         * @param {*} state
         * @param {Array|null} payload
         */
        changeEpisodes: (state, payload) => {
            state.episodes = payload || [];
        },
    },
    getters: {
        isAudioPlayerActive: state => Boolean(state.audioFile),
    },
};

export default module;
