import { getEvent, participationStudents, updateParticipationStudents } from '@/api/eventsApi.js';
import { getDateListFromEvent } from '@/utils/helpers.js';

export default {
    namespaced: true,
    state: {
        loading: false,
        selectedStudents: [],
        event: {},
        studentsList: [],
        studentsCurrentPage: 1,
        studentsLastPage: 1,
        studentsTotalCount: 0,
    },
    getters: {
        loadingEventData: state => state.loading,
        event: state => state.event ?? {},
        eventType: (_state, getters) => (getters.event?.settings ? getters.event?.settings?.event_type : ''),
        eventSchedule: (_state, getters) => {
            if (getters.event.content && getters.event.content.schedule) {
                return getDateListFromEvent(getters.event);
            }

            return [];
        },
        eventLocation: (_state, getters) => getters.event.location ?? [],
        eventId: (_state, getters) => getters.event.id ?? '',
        studentCurrentPage: state => state.studentsCurrentPage,
        studentLastPage: state => state.studentsLastPage,
        studentsTotalCount: state => state.studentsTotalCount,
        studentsList: state => state.studentsList ?? [],
        selectedStudentsCards: state => state.selectedStudents ?? [],
        isMassActionsBarVisible: state => Boolean(state.selectedStudents.length),
        isStudentSelected: state => student => state.selectedStudents.some(item => item.id === student.id),
    },
    mutations: {
        SET_LOADING(state, data) {
            state.loading = data;
        },
        SET_STUDENTS_LIST(state, data) {
            state.studentsList = data;
        },
        SET_SELECTED_STUDENTS(state, students = []) {
            state.selectedStudents = students;
        },
        SET_EVENT(state, event) {
            state.event = event;
        },
        SET_STUDENTS_CURRENT_PAGE(state, data) {
            state.studentsCurrentPage = data;
        },
        SET_STUDENTS_LAST_PAGE(state, data) {
            state.studentsLastPage = data;
        },
        SET_STUDENTS_TOTAL_COUNT(state, data) {
            state.studentsTotalCount = data;
        },
    },
    actions: {
        async fetchStudentList({ dispatch }, { eventId, params }) {
            try {
                dispatch('setLoading', true);

                const { data: studentsList } = await participationStudents(eventId, params);

                dispatch('setStudentsCurrentPage', studentsList.meta.current_page);
                dispatch('setStudentsLastPage', studentsList.meta.last_page);
                dispatch('setStudentsTotalCount', studentsList.meta.total);
                dispatch('setStudentsList', studentsList.data);

                return Promise.resolve(studentsList.data);
            } catch (e) {
                console.error(e);
            } finally {
                dispatch('setLoading', false);
            }
        },
        async fetchStudentMoreList({ dispatch, getters }, { eventId }) {
            try {
                dispatch('setLoading', true);

                const { data: studentsList } = await participationStudents(eventId, {
                    page: getters.studentCurrentPage + 1,
                });

                dispatch('setStudentsCurrentPage', studentsList.meta.current_page);
                dispatch('setStudentsLastPage', studentsList.meta.last_page);
                dispatch('setStudentsTotalCount', studentsList.meta.total);
                dispatch('setStudentsList', [
                    ...getters.studentsList,
                    ...studentsList.data,
                ]);

                return Promise.resolve(studentsList.data);
            } catch (e) {
                console.error(e);
            } finally {
                dispatch('setLoading', false);
            }
        },
        async initStudentsList({ dispatch }, id) {
            await dispatch('fetchStudentList', { eventId: id });
        },
        async initEvent({ dispatch }, activityId) {
            dispatch('setLoading', true);

            try {
                const { data: event } = await getEvent(activityId);

                const { data, ...rest } = event.data;

                const eventData = {
                    ...rest,
                    ...JSON.parse(data),
                };


                dispatch('setEvent', eventData);

                return Promise.resolve(event.data);
            } catch (e) {
                console.error(e);
            } finally {
                dispatch('setLoading', false);
            }
        },
        setLoading({ commit }, isLoading) {
            commit('SET_LOADING', isLoading);
        },
        setEvent({ commit }, event) {
            commit('SET_EVENT', event);
        },
        setStudentsList({ commit }, data) {
            commit('SET_STUDENTS_LIST', data);
        },
        setStudentsMeta({ commit }, data) {
            commit('SET_STUDENTS_META', data);
        },
        setStudentsCurrentPage({ commit }, data) {
            commit('SET_STUDENTS_CURRENT_PAGE', data);
        },
        resetStudentsCurrentPage({ commit }) {
            commit('SET_STUDENTS_CURRENT_PAGE', 1);
        },
        setStudentsLastPage({ commit }, data) {
            commit('SET_STUDENTS_LAST_PAGE', data);
        },
        setStudentsTotalCount({ commit }, data) {
            commit('SET_STUDENTS_TOTAL_COUNT', data);
        },
        async changeSingleStudentStatus({ getters, dispatch }, student) {
            try {
                await updateParticipationStudents(getters.eventId, { participation: [student] });

                const { studentsList } = getters;
                const newStudentsList = studentsList.map(item => (
                    item.id === student.user_id
                        ? {
                            ...item,
                            status: student.status,
                        }
                        : item));

                dispatch('setStudentsList', newStudentsList);
            } catch (error) {
                console.error(error);
            }
        },
        async changeGroupStudentStatus({ getters, dispatch }, selectedStatus) {
            try {
                const selectedStudentsList = [...getters.selectedStudentsCards];
                const participation = selectedStudentsList.map(item => ({
                    user_id: item.id,
                    status: selectedStatus,
                }));

                await updateParticipationStudents(getters.eventId, { participation });

                const newStudentsList = getters.studentsList.map(item => {
                    if (selectedStudentsList.some(student => student.id === item.id)) {
                        return {
                            ...item,
                            status: selectedStatus,
                        };
                    }

                    return item;
                });

                dispatch('setStudentsList', newStudentsList);
                dispatch('resetSelectedStudents');
            } catch (error) {
                console.error(error);
            }
        },
        toggleSelectedStudents({ commit, getters }, student) {
            let selectedStudents = [...getters.selectedStudentsCards];

            if (getters.isStudentSelected(student)) {
                selectedStudents = selectedStudents.filter(item => item.id !== student.id);
            } else {
                selectedStudents.push(student);
            }

            commit('SET_SELECTED_STUDENTS', selectedStudents);
        },
        toggleAllStudentsSelection({ commit, getters }) {
            const students = getters.studentsList.length === getters.selectedStudentsCards.length
                ? []
                : getters.studentsList;

            commit('SET_SELECTED_STUDENTS', students);
        },
        resetSelectedStudents({ commit }) {
            commit('SET_SELECTED_STUDENTS');
        },
    },
};
