import { solutionsGetters as getters } from '@/admin/store/modules/solutions/getters.js';
import { solutionsMutations as mutations } from '@/admin/store/modules/solutions/mutations.js';
import { solutionsActions as actions } from '@/admin/store/modules/solutions/actions.js';
import { solutionsState as state } from '@/admin/store/modules/solutions/state.js';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
