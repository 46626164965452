// TODO: Удалить паттерн шины событий с проекта. Переделать на хуки либо provide/inject.

import emitter from 'tiny-emitter/instance.js';

const EventBus = {
    $on: (...args) => emitter.on(...args),
    $once: (...args) => emitter.once(...args),
    $off: (...args) => emitter.off(...args),
    $emit: (...args) => emitter.emit(...args),
};

export default EventBus;
