import axios from 'axios';
import { BASE_URI_DEVELOPMENT_CENTER, BASE_URI_FILES } from '@/api/CONST.js';

/**
 * get homework
 * @param {number|string} activityId activity id
 * @returns Promise
 */
export const getHomeworkBlock = activityId => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}`);

/**
 *
 * @param {object} options
 * @param {*} options.homeworkId
 * @param {*} options.page
 * @param {*} options.search
 * @param {*} options.not_authorized
 * @param {*} options.new_message
 * @param {*} options.status
 * @param {Array<*>} options.mentors
 * @param {import('axios').AxiosRequestConfig} [config]
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getHomeworkBlockList = (options, config = {}) => {
    /* eslint-disable camelcase */
    const { homeworkId, page, search, not_authorized, new_message, status, mentors } = options;

    return axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${homeworkId}/students`, {
        ...config,
        params: {
            page,
            search,
            not_authorized: not_authorized ? 1 : 0,
            new_message: new_message ? 1 : 0,
            status,
            mentors,
        },
    });
    /* eslint-enable camelcase */
};

/**
 * @param {number|string} homeworkId
 * @param {number|string} homeworkId
 * @param {object} [options]
 * @param {number|string} [options.page]
 * @param {string} [options.search]
 * @param {object} [config]
 * @returns {Promise<import('axios').AxiosResponse<any>>}
 */
export const getHomeworkBlockMentors = (homeworkId, options = {}, config = {}) => {
    const { page, search } = options;

    return axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${homeworkId}/mentors`, {
        ...config,
        params: {
            page,
            search,
        },
    });
};

/**
 * @param {number|string} versionId
 * @param {number|string} page
 * @param {'HomeworkFiles' | 'EssayHomework'} type
 * @param {boolean} typeAcceptance
 * @param {'newest' | 'oldest'} sort
 * @returns {Promise<AxiosResponse>} Promise
 */
export const getVersionHomeworkActivities = ({ versionId, page, type, typeAcceptance, sort }) => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/versions/${versionId}/homework-activities`, {
    params: {
        page,
        type,
        type_acceptance: typeAcceptance,
        sort,
    },
});

/**
 * Получить домашнее задание
 * @param homeworkId
 * @param studentId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchHomework = ({ activityId, studentId }) => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/students/${studentId}`);

/**
 * Получить историю домашнего задания
 * @param id
 * @param cursor Хэш курсорной пагинации
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchHomeworkHistory = (id, cursor, order) => axios
    .get(`${BASE_URI_DEVELOPMENT_CENTER}/homework/${id}/history`, {
        params: {
            cursor,
            order,
        },
    });

/**
 * Начать сеccию проверки домашнего задания
 * @param homeworkId
 * @param studentId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const startHomeworkCheckingSession = ({ activityId, studentId }) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/students/${studentId}/start-checking`);

/**
 * Закончить сеccию проверки домашнего заданияы
 * @param homeworkId
 * @param studentId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const finishHomeworkCheckingSession = ({ activityId, studentId }) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/students/${studentId}/finish-checking`);

/**
 * Продлить сеccию проверки домашнего задания
 * @param homeworkId
 * @param studentId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const prolongHomeworkCheckingSession = ({ activityId, studentId }) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/students/${studentId}/prolong-checking`);

/**
 * Получить сеccию проверки домашнего задания
 * @param homeworkId
 * @param studentId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getHomeworkCheckingSession = ({ activityId, studentId }) => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/students/${studentId}/checking-session`);

/**
 * Получить элемент истории домашнего задания
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchHomeworkHistoryItem = ({ homeworkId, historyId }) => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/homework/${homeworkId}/history/${historyId}`);

/**
 * Создать ответ для домашнего задания
 * @param homeworkId
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createHomeworkResponse = ({ homeworkId, data }) => axios.post(
    `${BASE_URI_DEVELOPMENT_CENTER}/homework/${homeworkId}/responses`,
    data,
);

/**
 * Обновить ответ домашнего задания
 * @param {object} options
 * @param {string|number} options.homeworkId
 * @param {string|number} options.responseId
 * @param {object} options.data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateHomeworkResponse = ({ homeworkId, responseId, data }) => axios.put(
    `${BASE_URI_DEVELOPMENT_CENTER}/homework/${homeworkId}/responses/${responseId}`,
    data,
);

/**
 * Отменить ответ домашнего задания и создать новый
 * @param homeworkId
 * @param responseId
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const cancelAndCreatHomeworkResponse = ({ homeworkId, responseId, data }) => axios.post(
    `${BASE_URI_DEVELOPMENT_CENTER}/homework/${homeworkId}/responses/${responseId}/cancel`,
    data,
);

export const homeworkHistoryViewed = historyId => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/homework/history/${historyId}/view`);

/**
 * Получить файл шаблона для импорта учеников в систему.
 * @returns {string}
 */
export const getImportingStudentsTemplate = () => `${BASE_URI_DEVELOPMENT_CENTER}/homework/import/template`;

/**
 * Импорт файла решений ДЗ.
 * @param {number|string} activityId activity id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const importFileHomeworkSolutions = (activityId, file) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/homework/import`, file);

/**
 * Импорт файла решений ДЗ.
 * @param {number|string} activityId activity id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getStudentsReport = activityId => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/homework-activities/${activityId}/homework/users-report`);
/**
 * Создать ответ домашнего задания от преподавателя
 * @param homeworkId
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createHomeworkAnswerByTeacher = ({ homeworkId, data }) => axios.post(
    `${BASE_URI_DEVELOPMENT_CENTER}/homework/${homeworkId}/versions`,
    data,
);

/**
 * Обновить ответ домашнего задания от преподавателя
 * @param {object} options
 * @param {string|number} options.homeworkId
 * @param {string|number} options.versionId
 * @param {object} options.data
 * @param {string} [options.data.essay]
 * @param {array} [options.data.files]
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateHomeworkAnswerByTeacher = ({ homeworkId, versionId, data }) => axios.put(
    `${BASE_URI_DEVELOPMENT_CENTER}/homework/${homeworkId}/versions/${versionId}`,
    data,
);

/**
 * Загрузить файлы домашнего задания от преподавателя
 * @param {object} options
 * @param {number} options.modelId
 * @param {string} options.modelType
 * @param {File} options.file
 * @param {import('axios').AxiosRequestConfig} [params]
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createFile = ({ modelId, modelType, file }, params) => {
    const formData = new FormData();

    formData.append('model_id', modelId);
    formData.append('model_type', modelType);
    formData.append('file', file, file.name);

    return axios.post(BASE_URI_FILES, formData, params);
};

/**
 * Отправить сообщение
 * @param homeworkId
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const sendMessage = (homeworkId, data) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/homework/${homeworkId}/messages`, data);

/**
 * Редактировать сообщение
 * @param homeworkId
 * @param messageId
 * @param message
 * @returns {Promise<AxiosResponse<any>>}
 */
export const editMessage = ({ homeworkId, messageId, message }) => axios.put(`${BASE_URI_DEVELOPMENT_CENTER}/homework/${homeworkId}/messages/${messageId}`, { message });

/**
 * Удалить сообщение
 * @param homeworkId
 * @param messageId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteMessage = (homeworkId, messageId) => axios.delete(`${BASE_URI_DEVELOPMENT_CENTER}/homework/${homeworkId}/messages/${messageId}`);
