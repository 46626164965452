/* eslint-disable max-lines-per-function */
import isNil from 'lodash/isNil.js';
import {
    downloadSolutionsStudentReports,
    downloadSolutionsSummaryReports,
    downloadSolutionsStudentsProgressReports,
    getSolutionsReports,
} from '@/api/solutionsReportsApi.js';
import router from '@/router/index.js';
import { downloadUrl } from '@/api/attachmentsApi.js';
import { getStudentsReport, importFileHomeworkSolutions } from '@/api/homeworkApi.js';
import store from '@/store/index.js';
import useSnackbar from '@/hooks/snackbars.js';
import { getEchoInstance } from '@/services/echo.js';
import httpStatusCodes from '@/utils/httpStatusCodes.js';
import { DEFAULT_HOMEWORK_FILTER_VALUE } from '@/admin/components/Homework/HomeworkInfoPanel.vue';
import { homeworkActionsTypes } from './tasks/actions.js';

const { createSnackbar } = useSnackbar();

/**
 * @param {AxiosResponse} response
 * @param {string} [errorMsg]
 */
const throwIfNotSuccess = (response, errorMsg = 'Ошибка') => {
    if (response?.data?.success === false) {
        throw new Error(errorMsg);
    }
};

export const startSolutionsSummaryReport = async versionId => {
    try {
        const resp = await downloadSolutionsSummaryReports(versionId);
        const { developmentCenterId } = router.currentRoute.value.params;

        throwIfNotSuccess(resp, 'Ошибка старта формирования отчета');
        createSnackbar({
            type: 'info',
            size: 'large',
            title: 'Формирование отчета',
            message: 'Сводный отчет поставлен в очередь на создание. Сформированный отчет можно будет получить на странице отчетов.',
            link: {
                text: 'Перейти к отчетам',
                route: {
                    name: 'AdminReports',
                    params: {
                        developmentCenterId,
                    },
                },
            },
        });
    } catch (e) {
        console.error(e);
        createSnackbar({
            type: 'error',
            message: 'Ошибка формирование отчета',
        });
    }
};


export const startSolutionsStudentReport = async versionId => {
    try {
        const resp = await downloadSolutionsStudentReports(versionId);
        const { developmentCenterId } = router.currentRoute.value.params;

        throwIfNotSuccess(resp, 'Ошибка старта формирования отчета');
        createSnackbar({
            type: 'info',
            size: 'large',
            title: 'Формирование отчета',
            message: 'Отчет по ученикам поставлен в очередь на создание. Сформированный отчет можно будет получить на странице отчетов.',
            link: {
                text: 'Перейти к отчетам',
                route: {
                    name: 'AdminReports',
                    params: {
                        developmentCenterId,
                    },
                },
            },
        });
    } catch (e) {
        console.error(e);
        createSnackbar({
            type: 'error',
            message: 'Ошибка формирование отчета',
        });
    }
};

export const startSolutionsStudentsProgressReport = async versionId => {
    try {
        const resp = await downloadSolutionsStudentsProgressReports(versionId);
        const { developmentCenterId } = router.currentRoute.value.params;

        throwIfNotSuccess(resp, 'Ошибка старта формирования отчета');
        createSnackbar({
            type: 'info',
            size: 'large',
            title: 'Формирование отчета',
            message: 'Отчет об успеваемости поставлен в очередь на создание. Сформированный отчет можно будет получить на странице отчетов.',
            link: {
                text: 'Перейти к отчетам',
                route: {
                    name: 'AdminReports',
                    params: {
                        developmentCenterId,
                    },
                },
            },
        });
    } catch (e) {
        console.error(e);
        createSnackbar({
            type: 'error',
            message: 'Ошибка формирование отчета',
        });
    }
};

export const startImportingAllSolutions = async (activityId, fileData) => {
    const { developmentCenterId, solutionId, versionId, homeworkId } = router.currentRoute.value.params;

    try {
        store.dispatch('globals/setTemporaryRoute', {
            name: 'TaskMainView',
            params: {
                developmentCenterId,
                solutionId,
                versionId,
                homeworkId,
            },
        });

        const resp = await importFileHomeworkSolutions(activityId, fileData);

        throwIfNotSuccess(resp, 'Ошибка старта формирования отчета');
        createSnackbar({
            type: 'success',
            size: 'medium',
            message: 'Импорт добавлен в очередь',
        });

    } catch (e) {
        console.error(e);
        createSnackbar({
            type: 'error',
            message: 'Ошибка импорта. Попробуйте еще раз',
            size: 'large',
            link: {
                text: 'Перейти в модуль проверки',
                route: {
                    name: 'TaskMainView',
                    params: {
                        developmentCenterId,
                        solutionId,
                        versionId,
                        homeworkId,
                    },
                },
            },
        });
    }
};

export const startExportingReports = async activityId => {
    const { developmentCenterId, solutionId, versionId, homeworkId } = router.currentRoute.value.params;

    try {
        const resp = await getStudentsReport(activityId);

        throwIfNotSuccess(resp, 'Ошибка старта формирования отчета');
        createSnackbar({
            type: 'info',
            size: 'large',
            title: 'Отчет добавлен в очередь на обработку',
            message: 'По завершению обработки, результаты по практике можно скачать на странице отчетов.',
            link: {
                text: 'Перейти к отчетам',
                route: {
                    name: 'AdminReports',
                    params: {
                        developmentCenterId,
                    },
                },
            },
        });

    } catch (e) {
        console.error(e);
        createSnackbar({
            type: 'error',
            message: 'Ошибка генерации отчета. Попробуйте еще раз',
            size: 'large',
            link: {
                text: 'Перейти в модуль проверки',
                route: {
                    name: 'TaskMainView',
                    params: {
                        developmentCenterId,
                        solutionId,
                        versionId,
                        homeworkId,
                    },
                },
            },
        });
    }
};

/** @type {import('vuex').Module<any, any>} */
const module = {
    namespaced: true,
    state: {
        fetched: false,
        loading: false,
        reports: [],
        meta: {},
        accessDenied: false,
        listeners: [],
    },
    mutations: {
        startLoading(state) {
            state.loading = true;
        },
        stopLoading(state) {
            state.loading = false;
            state.fetched = true;
        },
        saveReports(state, { reports, meta = {} }) {
            state.reports = reports;
            state.meta = meta;
        },
        setAccessDenied(state, payload) {
            state.accessDenied = payload;
        },
        addListenReportReady(state, callback) {
            state.listeners = [
                ...state.listeners,
                callback,
            ];
        },
        removeListenReportReady(state, callback) {
            state.listeners = state.listeners.filter(cb => cb !== callback);
        },
    },
    actions: {
        callListenersReportReady: ({ state }, payload) => {
            state.listeners.forEach(callback => {
                callback?.(payload);
            });
        },
        initReportChanel({ dispatch }, { userId }) {
            getEchoInstance()
                .private(`users.${userId}.report`)
                .stopListening('.report.ready')
                .listen('.report.ready', reportData => {
                    const routeDevCenterId = router.currentRoute.value.params?.developmentCenterId;
                    const reportDevelopmentCenterId = reportData?.location?.development_center_id;
                    const isReportRoutes = router.currentRoute.value.name === 'AdminReports' &&
                        Number(reportDevelopmentCenterId) === Number(routeDevCenterId);
                    const shouldGoToReports = !isNil(reportDevelopmentCenterId) && !isReportRoutes;

                    createSnackbar({
                        type: 'success',
                        size: 'large',
                        title: 'Отчет сформирован',
                        message: 'Чтобы скачать отчет перейдите на страницу списка отчетов.',
                        ...(shouldGoToReports
                            ? {
                                link: {
                                    text: 'Перейти к отчетам',
                                    route: {
                                        name: 'AdminReports',
                                        params: {
                                            developmentCenterId: reportDevelopmentCenterId,
                                        },
                                    },
                                },
                            }
                            : {}),
                        actionButton: {
                            text: 'Скачать отчет',
                            iconName: 'download',
                            on: {
                                click: () => {
                                    if (reportData.url) {
                                        downloadUrl(reportData.url);
                                    }
                                },
                            },
                        },
                    });

                    dispatch('callListenersReportReady', reportData);
                })
                .stopListening('.report.failed')
                .listen('.report.failed', reportData => {
                    createSnackbar({
                        type: 'error',
                        size: 'large',
                        title: 'Ошибка формирования отчет',
                    });

                    dispatch('callListenersReportReady', reportData);
                });
        },
        initSolutionsImportChanel(_, { userId }) {
            getEchoInstance()
                .private(`users.${userId}.homework`)
                .stopListening('.homework.imported')
                .listen('.homework.imported', reportData => {
                    const totalRowsCount = reportData.total_rows_count;
                    const importedRowsCount = reportData.imported_rows_count;
                    const notImported = totalRowsCount - importedRowsCount;
                    const message = notImported > 0 ? `По ${notImported} ученикам не удалось получить данные или практика завершена. Проверьте правильность заполнения или дождитесь пока система получит данные об учениках после их перехода в новый интерфейс курсов` : '';

                    const tempRoute = store.getters['globals/getTemporaryRoute'];

                    if (totalRowsCount && tempRoute?.name === 'TaskMainView') {
                        store.dispatch(`tasks/${homeworkActionsTypes.FETCH_HOMEWORK_BLOCK_DATA}`, {
                            ...DEFAULT_HOMEWORK_FILTER_VALUE,
                            homeworkId: reportData.location.activity_id,
                            page: 1,
                            search: '',
                            isReplaceContent: true,
                        });
                    }

                    createSnackbar({
                        type: 'info',
                        size: 'large',
                        title: `Успешно импортировано ${importedRowsCount} из ${totalRowsCount}`,
                        message,
                        link: {
                            text: 'Перейти в модуль проверки',
                            route: {
                                name: tempRoute?.name,
                                params: tempRoute?.params,
                            },
                        },
                    });
                })
                .stopListening('.homework.import_failed')
                .listen('.homework.import_failed', () => {
                    const tempRoute = store.getters['globals/getTemporaryRoute'];

                    createSnackbar({
                        type: 'error',
                        size: 'large',
                        title: 'Ошибка импорта. Попробуйте еще раз',
                        link: {
                            text: 'Перейти в модуль проверки',
                            route: {
                                name: tempRoute?.name,
                                params: tempRoute?.params,
                            },
                        },
                    });
                });
        },
        getReports: async ({ commit, state }, payload = {}) => {
            try {
                commit('startLoading');

                const { devCenterId, append = false, ...params } = payload;

                const resp = await getSolutionsReports(devCenterId, params);
                const { data: newReports, meta } = resp.data;
                const reports = append ? [...state.reports, ...newReports] : newReports;

                commit('saveReports', {
                    reports,
                    meta,
                });

                commit('setAccessDenied', false);
            } catch (e) {
                console.error(e);

                if (e.response.status === httpStatusCodes.FORBIDDEN) {
                    commit('setAccessDenied', true);

                    return;
                }

                createSnackbar({
                    type: 'error',
                    message: 'Ошибка получаения списка отчетов',
                });
            } finally {
                commit('stopLoading');
            }
        },
    },
};

export default module;
