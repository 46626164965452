export const searchMutationTypes = Object.freeze({
    SET_SEARCH_DATA: '[search] set search data',
    SET_SEARCH_HISTORY: '[search] set search history',
    SET_LOADING: '[search] set search loading',
    SET_NOT_FOUND_DATA: '[search] set not found data',
});

export const searchMutations = {
    [searchMutationTypes.SET_SEARCH_DATA](state, payload) {
        state.searchData = payload;
    },
    [searchMutationTypes.SET_SEARCH_HISTORY](state, payload) {
        state.searchHistory = payload;
    },
    [searchMutationTypes.SET_LOADING](state, payload) {
        state.loading = payload;
    },
    [searchMutationTypes.SET_NOT_FOUND_DATA](state, payload) {
        state.isNotFoundData = payload;
    },
};

