import axios from 'axios';
import { redirectToAuth } from '@/utils/utils.js';
import StatusCodes from '@/utils/httpStatusCodes.js';

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.xsrfCookieName = 'SBS-CONSTRUCTOR-2-XSRF-TOKEN';

axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response) {
            const { status } = error.response;
            const isUnauthorazed = (status === StatusCodes.UNAUTHORIZED);

            if (isUnauthorazed) {
                redirectToAuth();

                return new Promise(() => {
                    // eslint-disable-next-line
                    console.log('redirect auth');
                });
            }
        }

        return Promise.reject(error);
    },
);
