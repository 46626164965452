export const developmentCentersState = {
    developmentCentersList: [],
    selectedDevelopmentCenter: null,
    listError: '',
    listLoading: false,
    currentPage: 0,
    lastPage: 1,
    counts: null,
    developmentCenterMembers: [],
    developmentCenterMembersError: '',
    developmentCenterMembersLoading: true,
    currentMembersPage: 0,
    lastMembersPage: 1,
    membersCounts: null,
    developmentCenterAccessList: [],
    developmentCenterPeremissionsList: [],
    developmentCenterRolesList: [],
    currentDevelopmentCenter: null,
    accessDenied: false,
};
