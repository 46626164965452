<template>
    <div class="DefaultLayout">
        <router-view class="DefaultLayout__View" />
        <LongreadUpdatedViewPlayer />
    </div>
</template>

<script>
import LongreadUpdatedViewPlayer from '@/admin/components/Longread/LongreadUpdatedViewPlayer.vue';

export default {
    name: 'DefaultLayout',
    components: { LongreadUpdatedViewPlayer },
};
</script>

<style lang="scss">
.DefaultLayout {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100vh;
    // min-height: calc(100vh * var(--zoom));

    &__View {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}
</style>
