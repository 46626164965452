export const EVENTS_TYPE = {
    ONLINE: 'online',
    OFFLINE: 'offline',
};

export const EVENTS_FORMATS = {
    [EVENTS_TYPE.ONLINE]: 'Онлайн',
    [EVENTS_TYPE.OFFLINE]: 'Очно',
};

export const EVENTS_PERIOD = {
    SINGLE: 'single',
    MULTIPLE: 'multiple',
};
