import * as developmentCentersApi from '@/api/developmentCentersApi.js';
import { developmentCenterMutationTypes } from '@/admin/store/modules/developmentCenters/mutations.js';
import { developmentCentersGetterTypes } from '@/admin/store/modules/developmentCenters/getters.js';
import { setCurrentDevelopmentCenterId } from '@/api/developmentCentersApi.js';
import useSnackbar from '@/hooks/snackbars.js';
import { ClientErrorCodes } from '@/utils/httpStatusCodes.js';

const { createSnackbar } = useSnackbar();


const errorHandler = (error, actionName) => {
    const errorText = `error in ${actionName} action`;

    console.error(errorText, error);

    return Promise.reject(error);
};

const formatMember = (member, userId) => ({
    ...(userId && { user_id: userId }),
    roles: member.roles.map(role => role.id),
    access: member.access,
    permissions: member.permissions
        .map(el => {
            const { slug, value } = el;

            return {
                slug,
                value,
            };
        }),
});

export const developmentCentersActionTypes = Object.freeze({
    FETCH_DEVELOPMENT_CENTERS: '[developmentCenters] fetch development centers list',
    FETCH_SELECTED_DEVELOPMENT_CENTER: '[developmentCenter] fetch selected development center',
    CHANGE_SELECTED_DEVELOPMENT_CENTER: '[developmentCenter] change selected development center',
    SET_DEFAULT_SELECTED_DEVELOPMENT_CENTER: '[developmentCenter] set default selected development center',
    FETCH_DEVELOPMENT_CENTER_MEMBERS: '[developmentCenters] fetch development center members',
    RESET_CURRENT_PAGE: '[developmentCenters] reset development center list current page',
    RESET_CURRENT_MEMBERS_PAGE: '[developmentCentersMembers] reset development center members list current page',
    GET_COUNTS: '[developmentCentersMembers] get development center members list page count',
    ADD_DEVELOPMENT_CENTER_MEMBER: '[developmentCentersMembers] add development center member',
    UPDATE_DEVELOPMENT_CENTER_MEMBER: '[developmentCentersMembers] update development center member',
    REMOVE_DEVELOPMENT_CENTER_MEMBER: '[developmentCentersMembers] remove development center member',
    FETCH_DEVELOPMENT_CENTER_DICTIONARIES: '[developmentCenters] fetch development center dictionaries',
    FETCH_DEVELOPMENT_CENTER_BY_ID: '[developmentCenters] fetch development center by id',
    RESET_MEMBERS_STATE: '[developmentCentersMembers] reset development center members state',
});

export const developmentCentersActions = {
    async [developmentCentersActionTypes.FETCH_DEVELOPMENT_CENTERS]({ getters, commit }) {
        commit(developmentCenterMutationTypes.SET_LIST_LOADING, true);

        try {
            const page = getters[developmentCentersGetterTypes.GET_CURRENT_PAGE] + 1;

            const { data } = await developmentCentersApi.getDevelopmentCentersList({ page });
            const developmentCenters = page === 1
                ? data.data
                : [
                    ...getters[developmentCentersGetterTypes.GET_DEVELOPMENT_CENTERS],
                    ...data.data,
                ];

            commit(developmentCenterMutationTypes.SET_LAST_PAGE, data.meta.last_page);
            commit(developmentCenterMutationTypes.SET_CURRENT_PAGE, page);
            commit(developmentCenterMutationTypes.SET_COUNTS, data.meta.total);
            commit(developmentCenterMutationTypes.SET_DEVELOPMENT_CENTERS, developmentCenters);
            commit(developmentCenterMutationTypes.SET_LIST_ERROR, false);

            return Promise.resolve(data.data);
        } catch (error) {
            commit(developmentCenterMutationTypes.SET_LIST_ERROR, true);

            return errorHandler(error, developmentCentersActionTypes.FETCH_DEVELOPMENT_CENTERS);
        } finally {
            commit(developmentCenterMutationTypes.SET_LIST_LOADING, false);
        }
    },
    async [developmentCentersActionTypes.FETCH_SELECTED_DEVELOPMENT_CENTER]({ commit, dispatch }) {
        commit(developmentCenterMutationTypes.SET_LIST_LOADING, true);

        try {
            const { data } = await developmentCentersApi.getSelectedDevelopmentCenter();

            commit(developmentCenterMutationTypes.SET_SELECTED_DEVELOPMENT_CENTER, data.data);
            commit(developmentCenterMutationTypes.SET_LIST_ERROR, false);

            return Promise.resolve(data.data);
        } catch (_error) {
            commit(developmentCenterMutationTypes.SET_LIST_ERROR, true);
            await dispatch(developmentCentersActionTypes.SET_DEFAULT_SELECTED_DEVELOPMENT_CENTER);
        } finally {
            commit(developmentCenterMutationTypes.SET_LIST_LOADING, false);
        }
    },
    async [developmentCentersActionTypes.CHANGE_SELECTED_DEVELOPMENT_CENTER]({ commit }, developmentCenterId) {
        try {
            await setCurrentDevelopmentCenterId(developmentCenterId);

            const { data } = await developmentCentersApi.getSelectedDevelopmentCenter();

            commit(developmentCenterMutationTypes.SET_CHANGE_SELECTED_DEVELOPMENT_CENTER, data.data);
            commit(developmentCenterMutationTypes.SET_CURRENT_DEVELOPMENT_CENTER, data.data);
        } catch (error) {
            commit(developmentCenterMutationTypes.SET_LIST_ERROR, true);

            return errorHandler(error, developmentCentersActionTypes.SET_CHANGE_SELECTED_DEVELOPMENT_CENTER);
        } finally {
            commit(developmentCenterMutationTypes.SET_LIST_LOADING, false);
        }

    },
    async [developmentCentersActionTypes.SET_DEFAULT_SELECTED_DEVELOPMENT_CENTER]({ getters, commit, dispatch }) {
        try {
            const developmentCenters = getters[developmentCentersGetterTypes.GET_DEVELOPMENT_CENTERS];

            if (!developmentCenters?.length) throw new Error();

            const developmentCenter = developmentCenters[0];
            const { id } = developmentCenter;

            await developmentCentersApi.setCurrentDevelopmentCenterId(id);
            commit(developmentCenterMutationTypes.SET_SELECTED_DEVELOPMENT_CENTER, developmentCenter);
        } catch (error) {
            createSnackbar({
                type: 'error',
                message: 'Отсутствуют доступные центры разработки. Обратитесь к администратору',
            });

            dispatch('globals/setGlobalerrorFromResponseData', error, { root: true });

            return errorHandler(error, developmentCentersActionTypes.SET_DEFAULT_SELECTED_DEVELOPMENT_CENTER);
        }
    },
    [developmentCentersActionTypes.RESET_CURRENT_PAGE]({ commit }) {
        commit(developmentCenterMutationTypes.SET_CURRENT_PAGE, 0);
    },
    async [developmentCentersActionTypes.GET_COUNTS]({ commit }, { developmentCenterId, params }) {
        try {
            const { data } = await developmentCentersApi.getDevelopmentCenterMembers(
                developmentCenterId,
                {
                    ...params,
                    page: 1,
                },
            );

            commit(developmentCenterMutationTypes.SET_COUNTS, data.meta.total);
        } catch (error) {
            return errorHandler(error, developmentCentersActionTypes.GET_COUNTS);
        }
    },
    // eslint-disable-next-line max-statements
    async [developmentCentersActionTypes.FETCH_DEVELOPMENT_CENTER_MEMBERS]({ commit, getters, dispatch }, { developmentCenterId, filterParams = {} }) {
        commit(developmentCenterMutationTypes.SET_DEVELOPMENT_CENTER_MEMBERS_LOADING, true);

        try {
            const page = getters[developmentCentersGetterTypes.GET_CURRENT_MEMBERS_PAGE] + 1;
            const params = {
                ...filterParams,
                page,
            };

            const { data } = await developmentCentersApi.getDevelopmentCenterMembers(developmentCenterId, params);
            const members = page === 1
                ? data.data
                : [
                    ...getters[developmentCentersGetterTypes.GET_DEVELOPMENT_CENTER_MEMBERS],
                    ...data.data,
                ];

            commit(developmentCenterMutationTypes.SET_LAST_MEMBERS_PAGE, data.meta.last_page);
            commit(developmentCenterMutationTypes.SET_CURRENT_MEMBERS_PAGE, page);
            commit(developmentCenterMutationTypes.SET_MEMBERS_COUNTS, data.meta.total);
            commit(developmentCenterMutationTypes.SET_DEVELOPMENT_CENTER_MEMBERS, members);
            commit(developmentCenterMutationTypes.SET_DEVELOPMENT_CENTER_MEMBERS_ERROR, false);
            commit(developmentCenterMutationTypes.SET_ACCESS_DENIED, false);

            return Promise.resolve(data.data);
        } catch (error) {
            if (error.response?.status === ClientErrorCodes.FORBIDDEN) commit(developmentCenterMutationTypes.SET_ACCESS_DENIED, true);

            dispatch('globals/setGlobalerrorFromResponseData', error, { root: true });

            return errorHandler(error, developmentCentersActionTypes.FETCH_DEVELOPMENT_CENTER_MEMBERS);
        } finally {
            commit(developmentCenterMutationTypes.SET_DEVELOPMENT_CENTER_MEMBERS_LOADING, false);
        }
    },
    [developmentCentersActionTypes.RESET_CURRENT_MEMBERS_PAGE]({ commit }) {
        commit(developmentCenterMutationTypes.SET_CURRENT_MEMBERS_PAGE, 0);
    },
    async [developmentCentersActionTypes.ADD_DEVELOPMENT_CENTER_MEMBER]({ commit, getters }, { developmentCenterId, data: developmentCenterData }) {
        try {
            const members = developmentCenterData.map(member => formatMember(member, member.user.id));

            const { data } = await developmentCentersApi.addDevelopmentCenterMember(developmentCenterId, { members });

            const developmentCenterMembers = [
                ...getters[developmentCentersGetterTypes.GET_DEVELOPMENT_CENTER_MEMBERS],
                ...data.data,
            ];

            commit(developmentCenterMutationTypes.SET_DEVELOPMENT_CENTER_MEMBERS, developmentCenterMembers);
        } catch (error) {
            return errorHandler(error, developmentCentersActionTypes.ADD_DEVELOPMENT_CENTER_MEMBER);
        }
    },
    async [developmentCentersActionTypes.UPDATE_DEVELOPMENT_CENTER_MEMBER]({ commit, getters }, developmentCenterData) {
        try {
            const { id, member } = developmentCenterData;

            const updatedMember = formatMember(member);

            const { data } = await developmentCentersApi.updateDevelopmentCenterMember(id, member.id, updatedMember);

            const developmentCenterMembers = getters[developmentCentersGetterTypes.GET_DEVELOPMENT_CENTER_MEMBERS];
            const idx = developmentCenterMembers.findIndex(el => el.id === member.id);

            developmentCenterMembers.splice(idx, 1, data.data);

            commit(developmentCenterMutationTypes.SET_DEVELOPMENT_CENTER_MEMBERS, developmentCenterMembers);
        } catch (error) {
            return errorHandler(error, developmentCentersActionTypes.UPDATE_DEVELOPMENT_CENTERS);
        }
    },
    async [developmentCentersActionTypes.REMOVE_DEVELOPMENT_CENTER_MEMBER]({ commit, getters }, { developmentCenterId, memberId }) {
        try {
            await developmentCentersApi.removeDevelopmentCenterMember(developmentCenterId, memberId);

            const developmentCenterMembers = getters[developmentCentersGetterTypes.GET_DEVELOPMENT_CENTER_MEMBERS]
                .filter(item => item.id !== memberId);

            commit(developmentCenterMutationTypes.SET_DEVELOPMENT_CENTER_MEMBERS, developmentCenterMembers);
        } catch (error) {
            return errorHandler(error, developmentCentersActionTypes.REMOVE_DEVELOPMENT_CENTER_MEMBER);
        }
    },
    async [developmentCentersActionTypes.FETCH_DEVELOPMENT_CENTER_DICTIONARIES]({ commit }) {
        try {
            const { data: rolesList } = await developmentCentersApi.getDevelopmentCenterDictionariesRoles();
            const roleId = rolesList.data.find(role => role.is_default)?.id;
            const { data: access } = await developmentCentersApi.getDevelopmentCenterDictionariesAccess();
            const { data: permissions } = await developmentCentersApi.getDevelopmentCenterDictionariesPermissions(roleId);

            commit(developmentCenterMutationTypes.SET_DEVELOPMENT_CENTER_DICTIONARIES, {
                developmentCenterAccessList: access.data,
                developmentCenterPeremissionsList: permissions.data,
                developmentCenterRolesList: rolesList.data,
            });
        } catch (error) {
            return errorHandler(error, developmentCentersActionTypes.FETCH_DEVELOPMENT_CENTER_DICTIONARIES);
        }
    },
    async [developmentCentersActionTypes.FETCH_DEVELOPMENT_CENTER_BY_ID]({ commit }, developmentCenterId) {
        try {
            const { data } = await developmentCentersApi.getDevelopmentCenterById(developmentCenterId);

            commit(developmentCenterMutationTypes.SET_CURRENT_DEVELOPMENT_CENTER, data.data);
        } catch (error) {
            return errorHandler(error, developmentCentersActionTypes.FETCH_DEVELOPMENT_CENTER_BY_ID);
        }
    },
    [developmentCentersActionTypes.RESET_MEMBERS_STATE]({ commit }) {
        commit(developmentCenterMutationTypes.RESET_MEMBERS_STATE);
    },
};
