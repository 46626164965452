import axios from 'axios';
import { BASE_URI_DEVELOPMENT_CENTER } from '@/api/CONST.js';


/**
 * get participation students
 * @param {number|string} activityId activity id
 * @param {object} params
 * @returns Promise
 */


export const participationStudents = (activityId, params) => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/activities/${activityId}/participation`, { params });


/**
 * update participation students
 * @param {number|string} activityId activity id
 * @returns Promise
 */


export const updateParticipationStudents = (activityId, data) => axios.post(`${BASE_URI_DEVELOPMENT_CENTER}/activities/${activityId}/participation`, data);


/**
 * get all events
 * @param {number|string} versionId version id
 * @returns Promise
 */


export const getAllEvents = versionId => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/versions/${versionId}/events`);

/**
 * get event
 * @param {number|string} activityId activity id
 * @returns Promise
 */


export const getEvent = activityId => axios.get(`${BASE_URI_DEVELOPMENT_CENTER}/activities/${activityId}`);
