<template>
    <div
        v-intersect="intersectOptions"
        class="scroll-preloader d-flex justify-content-center w-100">
        <SbsSpinner
            v-if="loading"
            size="large"
            colorTheme="primary" />
    </div>
</template>

<script>
export default {
    name: 'ScrollPreloader',
    emits: ['load'],
    computed: {
        intersectOptions() {
            return {
                callback: (_entries, _observer, isIntersecting) => {
                    if (!isIntersecting) return;

                    this.$emit('load');
                },
                options: {
                    root: null,
                    rootMargin: '0px',
                    threshold: 0.5,
                },
            };
        },
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
.scroll-preloader {
    height: 60px;
}
</style>
