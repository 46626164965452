import axios from 'axios';
import { BASE_URI } from '@/api/CONST.js';
import { AiSessionTypes } from '@/constants/AI.js';

const prefix = `${BASE_URI}/ai`;

/**
 * create AI session
 * @param {object} params data
 * @param {string} params.type type
 * @param {number|string} params.activityId activity id
 * @returns Promise
 */
export const createAiSession = (activityId, type = AiSessionTypes.TEXT_BLOCK) => axios.post(`${prefix}/sessions`, {
    type,
    params: {
        activity_id: activityId,
    },
});

/**
 * create AI session
 * @param {string|number} sessiondId session id
 * @param {object} data search query data
 * @param {string} data.text search query text
 * @param {string} data.prompt_type query prompt type
 * @returns Promise
 */
export const sendAiSearchQuery = (sessiondId, data) => axios.post(`${prefix}/sessions/${sessiondId}/messages`, data);

/**
 * get AI support problems list
 * @returns Promise
 */
export const getAiSupportProblems = () => axios.get(`${prefix}/dictionaries/problems`);

/**
 * get AI support dialogs themes list
 * @returns Promise
 */
export const getAiSupportDialogThemes = () => axios.get(`${prefix}/dictionaries/dialog-themes`);

/**
 * set like on AI message
 * @param {object} data session and message data
 * @param {string|number} data.messageId session id
 * @param {string|number} data.sessionId message id
 * @returns Promise
 */
export const setLikeAiMessage = ({ sessionId, messageId }) => axios.post(`${prefix}/sessions/${sessionId}/messages/${messageId}/like`);

/**
 * set dislike on AI message
 * @param {object} data session and message data
 * @param {string|number} data.messageId session id
 * @param {string|number} data.sessionId message id
 * @param {object} data.data dislike request data
 * @returns Promise
 */
export const setDislikeAiMessage = ({ sessionId, messageId, data }) => axios.post(`${prefix}/sessions/${sessionId}/messages/${messageId}/dislike`, data);

/**
 * cancel ai message sending
 * @param {object} data session and message data
 * @param {string|number} data.messageId session id
 * @param {string|number} data.sessionId message id
 * @returns Promise
 */
export const canselMeassage = ({ sessionId, messageId }) => axios.delete(`${prefix}/sessions/${sessionId}/messages/${messageId}`);
