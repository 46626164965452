/* eslint-disable camelcase */
import {
    MAX_HOURS,
    LIKERT_SCALE_TYPES,
    QUESTION_TYPES,
    REACTION_TYPES,
    VISIBILITY_MIN_ANSWERS,
    MAX_MINIMUM_ANSWERS_LEVEL,
} from '@/components/Blocks/BlockTestOrQuiz/constants.js';

/**
 * @param {number} maxValue
 * @param {number} stepValue
 * @return {number[]}
 */
export const getRangeArray = (maxValue, stepValue) => {
    const stepsCount = Math.round(maxValue / stepValue);
    let currentStepVal = stepValue;
    const resultArray = [currentStepVal];

    while (resultArray.length < stepsCount) {
        currentStepVal = resultArray.length === stepsCount - 1
            ? maxValue
            : Number((currentStepVal + stepValue).toFixed(2));
        resultArray.push(currentStepVal);
    }

    return resultArray;
};

/**
 * @param {number} minAnswersLevel
 * @param {number} stepRangeMinimumLevelAnswers
 * @return {number}
 */
export const getNormalizeMinAnswerLevel = (minAnswersLevel, stepRangeMinimumLevelAnswers) => {
    const valueNumber = Number(minAnswersLevel);
    const stepNumber = Number(stepRangeMinimumLevelAnswers);
    const rangeValues = getRangeArray(MAX_MINIMUM_ANSWERS_LEVEL, stepRangeMinimumLevelAnswers);

    if (valueNumber < stepNumber) return rangeValues[0];

    const validValueIndex = Math.round(valueNumber / stepNumber) - 1;

    return rangeValues[validValueIndex];
};

export const getStepRangeMinimumLevelAnswers = countAnswersShouldAnswered => {
    if (!countAnswersShouldAnswered) return MAX_MINIMUM_ANSWERS_LEVEL;

    return Number((MAX_MINIMUM_ANSWERS_LEVEL / countAnswersShouldAnswered).toFixed(2));
};

/**
 * @param {object} block
 * @param {string} questioType
 * @returns {{
 *      likertType: (string|null),
 *      questionContent: (null|string),
 *      reactionType: string,
 *      minAnswersLevel: (null|number),
 *      isShaked: boolean,
 *      generalAnswerReaction: (null|object),
 *      withOther: (null|boolean)
 * }}
 */
export const getDefaultQuestionOptions = (block, questioType) => ({
    isShaked: questioType === QUESTION_TYPES.SORTING,
    minAnswersLevel: VISIBILITY_MIN_ANSWERS.includes(questioType) ? 100 : null, // null or percents
    likertType: questioType === QUESTION_TYPES.LIKERT_SCALE ? LIKERT_SCALE_TYPES.NUMBER : null,
    withOther: null, // boolean|null
    generalAnswerReaction: null, // null | { correct: string, error: string }
    reactionType: REACTION_TYPES.NONE,
    questionContent: null,
});

export const checkDuration = val => {
    if (val) {
        const result = /(\d+?):(\d+?)/.exec(val);

        if (result) {
            const [, hours, minutes] = result;

            if (Number(hours) > MAX_HOURS || (Number(hours) >= MAX_HOURS && Number(minutes) > 0)) {
                return false;
            }
        }
    }

    return true;
};
