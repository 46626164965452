<template>
    <SbsButton
        class="ClimbUpButton"
        v-bind="buttonData">
        <template #prepend>
            <InlineSvg
                class="ClimbUpButton__Icon"
                :src="require('@/assets/svg/icons8-upload.svg')" />
        </template>
    </SbsButton>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
    name: 'ClimbUpButton',
    components: { InlineSvg },
    computed: {
        buttonData() {
            return {
                text: this.text,
                backgroundTheme: 'white',
                bordered: false,
                static: true,
                fullWidth: false,
            };
        },
    },
    props: {
        text: {
            type: String,
            default: 'Подняться вверх',
        },
    },
};
</script>

<style lang="scss">
.ClimbUpButton {
    z-index: 10;
    max-width: 173px;
    border-radius: 10px;
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.12);
    padding: 7px 15px;
    @include typo-secondary-demi;
}

.ClimbUpButton__Icon {
    margin-right: 6px;
}
</style>
