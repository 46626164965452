export default {
    namespaced: true,
    state: {
        modals: {},
    },
    getters: {
        isShowModal: state => name => Boolean(state.modals[name]),
    },
    mutations: {
        MODAL_SHOW(state, { name }) {
            state.modals = {
                ...state.modals,
                [name]: true,
            };
        },
        MODAL_HIDE(state, { name }) {
            state.modals = {
                ...state.modals,
                [name]: false,
            };
        },
    },
    actions: {
        modalShow({ commit }, name) {
            commit('MODAL_SHOW', { name });
        },
        modalHide({ commit }, name) {
            commit('MODAL_HIDE', { name });
        },
    },
};
