export const ClientErrorCodes = {
    BAD_REQUEST: 400, // некорректный запрос
    UNAUTHORIZED: 401, // не авторизован
    FORBIDDEN: 403, // нет прав
    NOT_FOUND: 404, // не найдено
    PAYLOAD_TOO_LARGE: 413, // слишком большой размер тела запроса
    AUTHENTICATION_TIMEOUT: 419, // обычно ошибка проверки CSRF
    UNPROCESSABLE_ENTITY: 422, // необрабатываемый экземпляр
    TOO_MANY_REQUESTS: 429, // слишком много запросов
};

export const ServerErrorCodes = {
    INTERNAL_SERVER_ERROR: 500, // внутренняя ошибка сервера
    NOT_IMPLEMENTED: 501, // не реализовано
    BAD_GATEWAY: 502, // ошибочный шлюз
    SERVICE_UNAVAILABLE: 503, // сервис недоступен
};

export default {
    ...ClientErrorCodes,
    ...ServerErrorCodes,
};
