<template>
    <SbsModal
        v-model="modalModel"
        class="asdfadsf"
        maxWidth="627">
        <div class="ConfirmModal">
            <slot name="prepend"></slot>
            <h4
                v-html="label"
                class="ConfirmModal__Title"></h4>
            <p
                v-if="subLabel"
                class="ConfirmModal__Subtitle">
                {{ subLabel }}
            </p>
            <div class="ConfirmModal__Actions">
                <SbsButton
                    class="ConfirmModal__ActionsButton"
                    :bordered="false"
                    backgroundTheme="primary"
                    colorTheme="white"
                    size="large"
                    :text="buttonRemoveTitle"
                    :loading="loading"
                    static
                    fullWidth
                    @click="$emit('confirm')" />
                <SbsButton
                    class="ConfirmModal__ActionsButton"
                    backgroundTheme="gray-ultra-light"
                    borderTheme="gray"
                    colorTheme="black"
                    size="large"
                    :text="buttonCancelTitle"
                    :disabled="loading"
                    bordered
                    static
                    fullWidth
                    @click="$emit('cancel')" />
            </div>
        </div>
    </SbsModal>
</template>

<script>
export default {
    name: 'ConfirmModal',
    emits: ['update:modelValue', 'confirm', 'cancel'],
    computed: {
        modalModel: {
            get() {
                return this.modelValue;
            },
            set(value) {
                if (this.loading) return;

                this.$emit('update:modelValue', value);
            },
        },
    },
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
            required: true,
        },
        subLabel: {
            type: String,
            default: '',
        },
        buttonRemoveTitle: {
            type: String,
            default: 'Да, выйти',
        },
        buttonCancelTitle: {
            type: String,
            default: 'Нет, я передумал-(а)',
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.ConfirmModal {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ConfirmModal__Title {
    text-align: center;

    @include typo-h3-bold;
}

.ConfirmModal__Subtitle {
    margin-top: 10px;
    text-align: center;

    @include typo-additional-book;
}

.ConfirmModal__Actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    width: 100%;
    margin-top: 20px;
}
</style>
