import axios from 'axios';
import { BASE_URI_DEVELOPMENT_CENTER } from '@/api/CONST.js';

const prefix = `${BASE_URI_DEVELOPMENT_CENTER}/pages`;

/**
 * get activities of page
 * @param {number} pageId page id
 * @param {object} params
 * @param {string} params.status activities status
 * @returns Promise
 */
export const getPageActivities = (pageId, params = {}) => axios.get(`${prefix}/${pageId}/activities`, { params });

/**
 * add activity to page
 * @param {number|string} pageId page id
 * @param {object} activityData activity data
 * @param {string} activityData.type activity type
 * @param {string} activityData.name activity name
 * @param {boolean} activityData.enabled activity visibility
 * @param {boolean} activityData.is_required activity required status
 * @param {string} activityData.duration activity duration
 * @param {object} activityData.data activity content
 * @returns Promise
 */
export const addPageActivity = (pageId, activityData) => axios.post(`${prefix}/${pageId}/activities`, activityData);

/**
 * copy activity
 * @param {number|string} pageId page id
 * @param {number|string} activityId activity id
 * @returns Promise
 */
export const copyPageActivity = (pageId, activityId) => axios.post(`${prefix}/${pageId}/activities/${activityId}/copy`);

/**
 * sort activities in page
 * @param {number|string} pageId page id
 * @param {object} sortedData
 * @param {string[]|number[]} sortedData.order array of sorted activities ids
 * @param {number} [sortedData.start] start inedex of sorted ids, optional
 * @returns Promise
 */
export const sortPageActivities = (pageId, sortedData) => axios.post(`${prefix}/${pageId}/activities/sort`, sortedData);

/**
 * get activity of page by id
 * @param {number|string} pageId page id
 * @param {number|string} activityId activity id
 * @returns {Promise<import('axios').AxiosResponse<{ data: any, success: boolean }>>}
 */
export const getPageActivity = (pageId, activityId) => axios.get(`${prefix}/${pageId}/activities/${activityId}`);

/**
 * edit page activity
 * @param {number|string} pageId page id
 * @param {number|string} activityId activity id
 * @param {object} activityData activity content
 * @param {string} activityData.type activity type
 * @param {string} activityData.name activity name
 * @param {boolean} activityData.enabled activity visibility
 * @param {boolean} activityData.is_required activity required status
 * @param {string} activityData.duration activity duration
 * @param {string} activityData.data activity content
 * @param {import('axios').CancelToken} [cancelToken]
 * @returns Promise<AxiosResponse<any>>
 */
export const editPageActivity = (pageId, activityId, activityData, cancelToken) => axios.put(`${prefix}/${pageId}/activities/${activityId}`, activityData, {
    cancelToken,
});

/**
 * remove activity of page by id
 * @param {number|string} pageId page id
 * @param {number|string} activityId activity id
 * @returns Promise
 */
export const removePageActivity = (pageId, activityId) => axios.delete(`${prefix}/${pageId}/activities/${activityId}`);

/**
 * restore removed activity
 * @param {number|string} pageId pageid
 * @param {number|string} activityId activity id
 * @returns Promise
 */
export const restorePageActivity = (pageId, activityId) => axios.post(`${prefix}/${pageId}/activities/${activityId}/restore`);
