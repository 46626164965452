export const homeworkState = {
    homeworkBlocks: [],
    homeworkBlocksLoading: false,
    homeworkBlocksError: '',
    homeworkBlocksCurrentPage: 1,
    homeworkBlocksLastPage: 1,

    homeworkBlock: null,
    homeworkBlockLoading: false,
    homeworkBlockError: '',
    homeworkBlockData: [],
    homeworkBlockDataCurrentPage: 0,
    homeworkBlockDataLastPage: 1,
    homeworkBlockDataTotalCount: 0,
    homeworkBlockDataLoading: false,
    homeworkBlockDataError: '',
};
