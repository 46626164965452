import axios from 'axios';
import { BASE_URI_DEVELOPMENT_CENTER } from '@/api/CONST.js';

// const PREFIX = `${BASE_URI}/sections`;
const PREFIX = `${BASE_URI_DEVELOPMENT_CENTER}/modules/`;

/**
 * add solutins section
 * @param {Object} sectionData data for added section
 * @param {string} sectionData.title section title
 * @param {string} sectionData.status section status
 * @param {number} sectionData.module_id section module id
 * @param {number} sectionData.order section order
 * @returns Promise
 */
export const addSolutionsSection = sectionData => axios.post(`${PREFIX}${sectionData.module_id}/sections`, { ...sectionData });


/**
 * update solutins section
 * @param {number|string} moduleId
 * @param {number|string} centerId section id
 * @param {Object} sectionData data for added solution
 * @param {string} sectionData.title section title
 * @param {string} sectionData.status section status
 * @param {number} sectionData.module_id section module id
 * @param {number} sectionData.order section order
 * @param {string} sectionData.access_type section access condition type 'always' | 'after'
 * @param {string} sectionData.access_data section access data
 * @returns Promise
 */
export const updateSolutionsSection = (moduleId, centerId, sectionData) => {
    const url = `${PREFIX}${moduleId}/sections/${centerId}`;

    return axios.put(url, { ...sectionData });
};


/**
 * remove section by id
 * @param {number|string} centerId section id
 * @param {number|string} moduleId
 * @returns Promise
 */
export const removeSolutionsSection = (moduleId, centerId) => {
    const url = `${PREFIX}${moduleId}/sections/${centerId}`;

    return axios.delete(url);
};


/**
 * restore section by id
 * @param {number|string} moduleId
 * @param {number|string} centerId section id
 * @returns Promise
 */
export const restoreSolutionsSection = (moduleId, centerId) => {
    const url = `${PREFIX}${moduleId}/sections/${centerId}/restore`;

    return axios.post(url);
};


/**
 * copy section by id
 * @param {number|string} moduleId
 * @param {number|string} centerId section id
 * @param {string} title section title
 * @returns Promise
 */
export const copySolutionsSection = (moduleId, centerId, title) => {
    const url = `${PREFIX}${moduleId}/sections/${centerId}/copy`;

    return axios.post(url, { title });
};
