import { eventsGetters as getters } from '@/admin/store/modules/events/getters.js';
import { eventsMutations as mutations } from '@/admin/store/modules/events/mutations.js';
import { eventsActions as actions } from '@/admin/store/modules/events/actions.js';
import { eventsState as state } from '@/admin/store/modules/events/state.js';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
