import { getTags } from '@/api/tagsApi.js';

export const tagsActionTypes = {
    FETCH_TAGS: '[tags] fetch tags',
    RESET_STATE: '[tags] reset state',
};

export const tagsMutationTypes = {
    SET_TAGS: '[tags] set tags',
    SET_META: '[tags] set META',
};

export const tagsGetterTypes = {
    GET_TAGS: '[tags] get tags',
    GET_TAG_BY_ID: '[tags] get tag by id',
    GET_CURRENT_PAGE: '[tags] get current page',
    GET_IS_LAST_PAGE: '[tags] get is last page',
};

export default {
    namespaced: true,
    state: {
        tags: [],
        meta: {},
    },
    getters: {
        [tagsGetterTypes.GET_TAGS]: state => state.tags,
        [tagsGetterTypes.GET_TAG_BY_ID]: state => id => state.tags.find(tag => tag.id === id),
        [tagsGetterTypes.GET_CURRENT_PAGE]: state => state.meta.current_page ?? 0,
        [tagsGetterTypes.GET_IS_LAST_PAGE]: (state, getters) => getters[tagsGetterTypes.GET_CURRENT_PAGE] === state.meta.last_page,
    },
    mutations: {
        [tagsMutationTypes.SET_TAGS](state, tags) {
            state.tags = tags;
        },
        [tagsMutationTypes.SET_META](state, meta) {
            state.meta = meta;
        },
    },
    actions: {
        async [tagsActionTypes.FETCH_TAGS]({ getters, commit }, params) {
            if (getters[tagsGetterTypes.GET_IS_LAST_PAGE]) return;

            try {
                const { data } = await getTags(params);
                const tags = [...getters[tagsGetterTypes.GET_TAGS], ...data.data];

                commit(tagsMutationTypes.SET_TAGS, tags);
                commit(tagsMutationTypes.SET_META, data.meta);

                return Promise.resolve(tags);
            } catch (error) {
                console.error(`error in ${tagsActionTypes.FETCH_TAGS}`, error);

                return Promise.reject(error);
            }
        },
        [tagsActionTypes.RESET_STATE]({ commit }) {
            commit(tagsMutationTypes.SET_TAGS, []);
            commit(tagsMutationTypes.SET_META, {});
        },
    },
};
