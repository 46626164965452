<template>
    <transition>
        <SbsAudioPlayer
            v-if="audio"
            class="LongreadUpdatedViewPlayer"
            v-bind="audio"
            @close="handleClosePlayer"
            @play="handlePlay"
            @pause="handlePause"
            @stop="handleStop"
            @progress="handleProgress" />
    </transition>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
    name: 'LongreadUpdatedViewPlayer',
    computed: {
        ...mapState({
            audio: state => {
                if (state.audio.audioFile) {
                    return {
                        id: state.audio.audioFile.id,
                        title: state.audio.title,
                        sources: [state.audio.audioFile.file],
                        cover: state.audio.coverFile?.file,
                        playing: state.audio.playing,
                        startTime: state.audio.startTime,
                        episodes: state.audio.episodes,
                    };
                }

                return null;
            },
        }),
    },
    methods: {
        ...mapMutations('audio', ['clearAudio', 'changePlayState', 'changeProgress']),
        handleClosePlayer() {
            this.clearAudio();
        },
        handlePlay() {
            this.changePlayState(true);
        },
        handlePause() {
            this.changePlayState(false);
        },
        handleStop() {
            this.changePlayState(false);
        },
        handleProgress(currentTime) {
            this.changeProgress(currentTime);
        },
    },
};
</script>

<style lang="scss">
.LongreadUpdatedViewPlayer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 102;

    &.v-enter-active,
    &.v-leave-active {
        transition: transform 0.5s ease;
    }

    &.v-enter-from,
    &.v-leave-to {
        transform: translateY(100%) translateZ(0);
    }
}
</style>
