<template>
    <SbsPopup
        v-if="isShowLocationBtn"
        v-model="isShowLocationPopup"
        class="LocationBtn"
        popupClass="LocationBtn__Popup"
        @click.stop>
        <SbsLink
            v-bind="linkData"
            @click="toggleLocationPopup">
            <template #prepend>
                <InlineSvg
                    class="LocationBtn__LinkIcon"
                    :class="linkIconClasses"
                    :src="require('@/assets/svg/icons8-folder-tree.svg')" />
            </template>
        </SbsLink>
        <template #content>
            <div class="LocationBtn__Title">
                Расположение
            </div>
            <div class="LocationBtn__Items">
                <template v-for="item in locationItems">
                    <div
                        v-if="item.id && item.title"
                        :key="item.id"
                        class="LocationBtn__Item">
                        <div class="LocationBtn__ItemType">
                            <InlineSvg
                                class="LocationBtn__ItemIcon"
                                :src="item.icon" />
                            <div class="LocationBtn__ItemName">
                                {{ item.type }}
                            </div>
                        </div>
                        <SbsLink
                            hoverUnderline="primary"
                            colorTheme="black"
                            :to="item.to"
                            @click="item.click">
                            <div class="LocationBtn__ItemTitle">
                                {{ item.title }}
                            </div>
                        </SbsLink>
                    </div>
                </template>
            </div>
        </template>
    </SbsPopup>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import { LOCATION_ITEMS, LOCATION_ITEMS_ICONS } from '@/constants/index.js';

const STRUCTURE_ACCORDIONS_STATE_KEY = 'STRUCTURE_ACCORDIONS_STATE';

export default {
    name: 'LocationBtn',
    components: { InlineSvg },
    emits: ['goToBlock'],
    data: () => ({
        isShowLocationPopup: false,
    }),
    computed: {
        isShowLocationBtn() {
            return this.locationItems.filter(item => item.id && item.title).length > 0;
        },
        linkData() {
            return {
                text: 'Расположение',
                colorTheme: 'black',
                hoverUnderline: 'primary',
                underlineTheme: this.isShowLocationPopup ? 'primary' : 'gray',
                borderTheme: 'transparent',
            };
        },
        locationItems() {
            // const isShowDevCenter = this.location.development_center?.id &&
            //     String(this.$route.params.developmentCenterId) !== String(this.location.development_center?.id);

            const isShowSolution = this.location.solution?.id &&
                String(this.$route.params.solutionId) !== String(this.location.solution?.id);

            const isShowVersion = this.location.version?.id &&
                String(this.$route.params.versionId) !== String(this.location.version?.id);

            return [
                // ...(isShowDevCenter
                //     ? [
                //         {
                //             id: this.location.development_center?.id,
                //             type: LOCATION_ITEMS.DEV_CENTER,
                //             title: this.location.development_center?.name, // не приходит название
                //             icon: LOCATION_ITEMS_ICONS[LOCATION_ITEMS.DEV_CENTER],
                //             to: this.devCenterRoute(),
                //         },
                //     ]
                //     : []),
                ...(isShowSolution
                    ? [
                        {
                            id: this.location.solution?.id,
                            type: LOCATION_ITEMS.SOLUTION,
                            title: this.location.solution?.name,
                            icon: LOCATION_ITEMS_ICONS[LOCATION_ITEMS.SOLUTION],
                            to: this.solutionRoute(),
                        },
                    ]
                    : []),
                ...(isShowVersion
                    ? [
                        {
                            id: this.location.version?.id,
                            type: LOCATION_ITEMS.VERSION,
                            title: this.location.version?.name,
                            icon: LOCATION_ITEMS_ICONS[LOCATION_ITEMS.VERSION],
                            to: this.versionRoute(),
                        },
                    ]
                    : []),
                {
                    id: this.location.module?.id,
                    type: LOCATION_ITEMS.MODULE,
                    title: this.location.module?.name,
                    icon: LOCATION_ITEMS_ICONS[LOCATION_ITEMS.MODULE],
                    to: this.moduleRoute(),
                },
                {
                    id: this.location.section?.id,
                    type: LOCATION_ITEMS.SECTION,
                    title: this.location.section?.name,
                    icon: LOCATION_ITEMS_ICONS[LOCATION_ITEMS.SECTION],
                    to: this.sectionRoute(),
                    click: () => this.addItemToAccordionState(this.location.module?.id),
                },
                {
                    id: this.location.page?.id,
                    type: LOCATION_ITEMS.PAGE,
                    title: this.location.page?.name,
                    icon: LOCATION_ITEMS_ICONS[LOCATION_ITEMS.PAGE],
                    to: this.pageRoute(),
                },
                {
                    id: this.location.activity?.id,
                    type: LOCATION_ITEMS.ACTIVITY,
                    title: 'К материалу',
                    icon: LOCATION_ITEMS_ICONS[LOCATION_ITEMS.ACTIVITY],
                    to: this.blockRoute(),
                },
            ];
        },
        linkIconClasses() {
            return [{ 'TaskInfoPanelLocation__LinkIcon--Active': this.isShowLocationPopup }];
        },
    },
    methods: {
        toggleLocationPopup() {
            this.isShowLocationPopup = !this.isShowLocationPopup;
        },
        addItemToAccordionState(moduleId) {
            const structureAccordionsState = JSON.parse(sessionStorage.getItem(STRUCTURE_ACCORDIONS_STATE_KEY)) || [];

            const isMatchIdx = structureAccordionsState.findIndex(item => Number(item.moduleId) === Number(moduleId));

            if (isMatchIdx === -1) {
                structureAccordionsState.push({
                    moduleId: String(moduleId),
                    isOpen: true,
                });
            } else {
                structureAccordionsState[isMatchIdx].isOpen = true;
            }

            sessionStorage.setItem(STRUCTURE_ACCORDIONS_STATE_KEY, JSON.stringify(structureAccordionsState));
        },
        blockRoute() {
            return {
                name: 'AdminLongreadUpdateView',
                hash: `#activity-${this.location.activity?.id}`,
                params: {
                    developmentCenterId: this.location.development_center?.id,
                    solutionId: this.location.solution?.id,
                    versionId: this.location.version?.id,
                    longreadId: this.location.page?.id,
                },
            };
        },
        pageRoute() {
            return {
                name: 'AdminLongreadUpdateView',
                params: {
                    developmentCenterId: this.location.development_center?.id,
                    solutionId: this.location.solution?.id,
                    versionId: this.location.version?.id,
                    longreadId: this.location.page?.id,
                },
            };
        },
        // eslint-disable-next-line vue/no-unused-properties
        devCenterRoute() {
            return {
                name: 'AdminProjectsList',
                params: {
                    developmentCenterId: this.location.development_center?.id,
                },
            };
        },
        solutionRoute() {
            return {
                name: 'AdminProjectVersionsMainView',
                params: {
                    developmentCenterId: this.location.development_center?.id,
                    solutionId: this.location.solution?.id,
                },
            };
        },
        versionRoute() {
            return {
                name: 'VersionReviewMainView',
                params: {
                    developmentCenterId: this.location.development_center?.id,
                    solutionId: this.location.solution?.id,
                    versionId: this.location.version?.id,
                },
            };
        },
        moduleRoute() {
            return this.pushToStructure(`#module-${this.location.module?.id}`);
        },
        sectionRoute() {
            return this.pushToStructure(`#section-${this.location.section?.id}`);
        },
        pushToStructure(hash) {
            return {
                name: 'LearningSolutionStructure',
                params: {
                    developmentCenterId: this.location.development_center?.id,
                    solutionId: this.location.solution?.id,
                    versionId: this.location.version?.id,
                },
                hash,
            };
        },
    },
    props: {
        location: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>

<style lang="scss">
.LocationBtn__Popup {
    padding: 20px 25px;
}

.LocationBtn__LinkIcon {
    margin-right: 5px;
}

.TaskInfoPanelLocation__LinkIcon--Active {
    color: #0066ff;
}

.LocationBtn__Title {
    margin-bottom: 15px;

    @include typo-primary-medium;
}

.LocationBtn__Items {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.LocationBtn__Item {
    display: grid;
    grid-template-columns: 95px 231px;
    gap: 24px;

    @include typo-secondary-book;
}

.LocationBtn__ItemType {
    display: flex;
    gap: 5px;
    align-items: center;
    color: $gray-deep;

    path {
        fill: $gray-deep;
    }
}

.LocationBtn__ItemIcon {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
}

.LocationBtn__ItemTitle {
    display: -webkit-box; // stylelint-disable-line property-no-vendor-prefix
    overflow: hidden;
    -webkit-box-orient: vertical; // stylelint-disable-line property-no-vendor-prefix
    -webkit-line-clamp: 1; // stylelint-disable-line property-no-vendor-prefix
    cursor: pointer;
}
</style>


